import React, { useState, useEffect, useRef, useContext } from 'react';
import './PvpCrash.css';
import Navbar from './Navbar';
import { CreditsContext } from '../context/CreditsContext';
import axios from 'axios';

const PvpCrash = ({ username, handleLogout }) => {
  // Herní stavy
  const [betAmount, setBetAmount] = useState(50);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [isWaitingForOpponent, setIsWaitingForOpponent] = useState(false);
  const [gameId, setGameId] = useState(null);
  const [multiplier, setMultiplier] = useState(1.00);
  const [crashPoint, setCrashPoint] = useState(null);
  const [player1Data, setPlayer1Data] = useState({ username: username, hasCashed: false, cashoutMultiplier: null });
  const [player2Data, setPlayer2Data] = useState({ username: 'Opponent', hasCashed: false, cashoutMultiplier: null });
  const [winner, setWinner] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);
  const [gameResult, setGameResult] = useState(null);
  const [showCrashStatus, setShowCrashStatus] = useState(false);
  const [inputAmount, setInputAmount] = useState('');
  const [notifications, setNotifications] = useState([]);
  
  // Reference pro herní elementy
  const gameInterval = useRef(null);
  const graphRef = useRef(null);
  const shipRef = useRef(null);
  const pollInterval = useRef(null);

  // Použití kontextu pro kredity
  const { credits, updateCredits } = useContext(CreditsContext);

  // Generování crash pointu
  const generateCrashPoint = () => {
    const random = Math.random();
    const value = 1 + (Math.pow(random, -0.9) - 1) * 0.7;
    return Math.min(10.00, value);
  };

  // Aktualizace vizuálních prvků hry
  const updateGameVisuals = (multiplierValue) => {
    if (!graphRef.current || !shipRef.current) return;
    
    // Logaritmická progrese pro více přirozenou křivku grafu
    const logMultiplier = Math.log10(multiplierValue * 5) / Math.log10(50);
    const progress = Math.min(logMultiplier * 100, 100);
    
    // Nelineární mapování pro pohyb
    const easedProgress = Math.pow(progress / 100, 0.8) * 100;
    
    // Aktualizace pozice lodi
    const shipX = easedProgress * 0.7;
    const shipY = easedProgress * 0.9;
    
    // Rotace lodi
    const rotationAngle = 45 - progress * 0.3;
    
    // Aplikace transformací na loď
    shipRef.current.style.transform = `translate(${shipX}%, -${shipY}%) rotate(${rotationAngle}deg)`;
    
    // Vykreslení křivky grafu
    if (graphRef.current) {
      const canvas = graphRef.current;
      const ctx = canvas.getContext('2d');
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.strokeStyle = '#4dff91';
      ctx.lineWidth = 3;
      ctx.shadowBlur = 10;
      ctx.shadowColor = '#4dff91';
      
      ctx.beginPath();
      ctx.moveTo(0, canvas.height);
      
      const points = [];
      for (let i = 0; i <= easedProgress; i++) {
        const x = i / 100 * canvas.width;
        const y = canvas.height - (Math.pow(i / 100, 1.2) * canvas.height);
        points.push({x, y});
      }
      
      for (let i = 0; i < points.length - 1; i++) {
        const xc = (points[i].x + points[i + 1].x) / 2;
        const yc = (points[i].y + points[i + 1].y) / 2;
        ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
      }
      
      ctx.stroke();
      
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, 'rgba(77, 255, 145, 0.3)');
      gradient.addColorStop(1, 'rgba(77, 255, 145, 0)');
      
      if (points.length > 0) {
        ctx.lineTo(points[points.length - 1].x, canvas.height);
        ctx.lineTo(0, canvas.height);
        ctx.fillStyle = gradient;
        ctx.fill();
      }
    }
  };

  // Funkce pro přidání notifikace
  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    
    // Automaticky odstranit notifikaci po 5 sekundách
    setTimeout(() => {
      setNotifications(prev => prev.filter(notif => notif.id !== id));
    }, 5000);
  };

  // Začátek hry
  const startGame = async () => {
    if (betAmount > credits) {
      alert("Nemáte dostatek kreditů!");
      return;
    }

    try {
      // Odečtení sázky
      updateCredits(-betAmount);
      
      // Resetování grafických elementů
      if (graphRef.current) {
        const ctx = graphRef.current.getContext('2d');
        ctx.clearRect(0, 0, graphRef.current.width, graphRef.current.height);
      }
      
      if (shipRef.current) {
        shipRef.current.style.transform = 'translate(0%, 0%) rotate(45deg)';
      }
      
      // Vytvoření hry na serveru
      setIsWaitingForOpponent(true);
      addNotification("Hledání soupeře...", "info");
      
      const token = localStorage.getItem('authToken');
      const response = await axios.post('/pvp/crash/create', 
        { betAmount },
        { headers: { Authorization: `Bearer ${token}` }}
      );
      
      const { gameId, opponent } = response.data;
      setGameId(gameId);
      
      if (opponent) {
        // Hra začíná okamžitě, máme soupeře
        setPlayer2Data({ ...player2Data, username: opponent });
        addNotification(`Soupeř ${opponent} nalezen! Hra začíná...`, "success");
        startGameSession(response.data.crashPoint);
      } else {
        // Čekáme na soupeře
        addNotification("Čekáme na připojení soupeře...", "info");
        pollForOpponent(gameId);
      }
    } catch (error) {
      console.error("Chyba při vytváření PVP hry:", error);
      updateCredits(betAmount); // Vrácení sázky
      setIsWaitingForOpponent(false);
      addNotification("Nepodařilo se vytvořit hru. Zkuste to znovu.", "error");
    }
  };

  // Dotazování na připojení soupeře
  const pollForOpponent = (gameId) => {
    let waitTime = 0;
    pollInterval.current = setInterval(async () => {
      try {
        waitTime += 1;
        // Každých 10 sekund aktualizovat zprávu
        if (waitTime % 10 === 0) {
          addNotification(`Stále hledáme soupeře... (${waitTime}s)`, "info");
        }
        
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`/pvp/crash/status/${gameId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (response.data.opponent) {
          clearInterval(pollInterval.current);
          setIsWaitingForOpponent(false);
          setPlayer2Data({ ...player2Data, username: response.data.opponent });
          addNotification(`Soupeř ${response.data.opponent} nalezen! Hra začíná...`, "success");
          startGameSession(response.data.crashPoint);
        }
      } catch (error) {
        console.error("Chyba při dotazování na stav hry:", error);
        addNotification("Problém s připojením k serveru.", "error");
      }
    }, 1000);
  };

  // Spuštění herní relace
  const startGameSession = (serverCrashPoint) => {
    const crashValue = serverCrashPoint || generateCrashPoint();
    setCrashPoint(crashValue);
    console.log(`Crash point: ${crashValue.toFixed(2)}x`);
    
    setIsGameRunning(true);
    setMultiplier(1.00);
    
    // Reset stavů hráčů
    setPlayer1Data(prev => ({ ...prev, hasCashed: false, cashoutMultiplier: null }));
    setPlayer2Data(prev => ({ ...prev, hasCashed: false, cashoutMultiplier: null }));
    setShowCrashStatus(false);
    setGameResult(null);
    setWinner(null);
    
    // Spuštění herní smyčky
    let currentMultiplier = 1.00;
    gameInterval.current = setInterval(() => {
      currentMultiplier += 0.0075 * (currentMultiplier * 0.18);
      const formattedMultiplier = parseFloat(currentMultiplier.toFixed(2));
      setMultiplier(formattedMultiplier);
      
      updateGameVisuals(formattedMultiplier);
      
      // Kontrola, zda oba hráči cashovali
      if (player1Data.hasCashed && player2Data.hasCashed) {
        determineWinner();
        handleCrash();
      }
      
      // Kontrola, zda nedošlo ke crashnutí
      if (formattedMultiplier >= crashValue) {
        handleCrash();
      }
    }, 40);
  };

  // Funkce pro cashout
  const handleCashout = async () => {
    if (!isGameRunning || player1Data.hasCashed) return;
    
    // Nastavení cashout stavu pro hráče 1 (uživatele)
    setPlayer1Data(prev => ({ 
      ...prev, 
      hasCashed: true, 
      cashoutMultiplier: multiplier 
    }));
    
    // Oznámení na server
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`/pvp/crash/cashout/${gameId}`, {
        multiplier
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      console.error("Chyba při cashout oznámení:", error);
    }
    
    // Simulace cashout soupeře (pokud by byl reálný, byl by řízen serverem)
    simulateOpponentActions();
  };

  // Simulace akcí soupeře (v reálné aplikaci by toto přišlo ze serveru)
  const simulateOpponentActions = () => {
    // Pro ukázku: soupeř cashuje náhodně mezi současným multiplierem a crashPointem
    if (!player2Data.hasCashed && Math.random() > 0.3) {
      const maxMultiplier = crashPoint * 0.9;
      const opponentCashoutMultiplier = Math.min(
        multiplier + Math.random() * (maxMultiplier - multiplier),
        crashPoint - 0.1
      ).toFixed(2);
      
      setTimeout(() => {
        setPlayer2Data(prev => ({
          ...prev,
          hasCashed: true,
          cashoutMultiplier: parseFloat(opponentCashoutMultiplier)
        }));
      }, Math.random() * 2000 + 500);
    }
  };

  // Zpracování crashe
  const handleCrash = () => {
    clearInterval(gameInterval.current);
    gameInterval.current = null;
    
    setIsGameRunning(false);
    setShowCrashStatus(true);
    
    // Určení výsledku, pokud ještě nebyl určen
    if (!gameResult) {
      determineWinner();
    }
    
    // Přidání do historie
    const gameData = {
      crashPoint,
      player1: { ...player1Data },
      player2: { ...player2Data },
      winner: winner
    };
    
    setGameHistory(prev => [gameData, ...prev.slice(0, 9)]);
    
    setTimeout(() => {
      setShowCrashStatus(false);
    }, 2000);
  };

  // Určení vítěze
  const determineWinner = () => {
    let result, winningPlayer;
    
    // Pokud oba crashli
    if (!player1Data.hasCashed && !player2Data.hasCashed) {
      result = "draw";
      winningPlayer = null;
    }
    // Pokud hráč 1 cashoval a hráč 2 crashnul
    else if (player1Data.hasCashed && !player2Data.hasCashed) {
      result = "win";
      winningPlayer = player1Data.username;
      // Přičtení výhry uživateli (2x sázka)
      updateCredits(betAmount * 2);
    }
    // Pokud hráč 2 cashoval a hráč 1 crashnul
    else if (!player1Data.hasCashed && player2Data.hasCashed) {
      result = "lose";
      winningPlayer = player2Data.username;
    }
    // Pokud oba cashovali, vyhrává ten s vyšším multiplierem
    else {
      if (player1Data.cashoutMultiplier > player2Data.cashoutMultiplier) {
        result = "win";
        winningPlayer = player1Data.username;
        // Přičtení výhry uživateli (2x sázka)
        updateCredits(betAmount * 2);
      } else if (player1Data.cashoutMultiplier < player2Data.cashoutMultiplier) {
        result = "lose";
        winningPlayer = player2Data.username;
      } else {
        result = "draw";
        winningPlayer = null;
        // Při remíze vrácení sázky
        updateCredits(betAmount);
      }
    }
    
    setGameResult(result);
    setWinner(winningPlayer);
  };

  // Vyčištění intervalu při odpojení komponenty
  useEffect(() => {
    return () => {
      if (gameInterval.current) clearInterval(gameInterval.current);
      if (pollInterval.current) clearInterval(pollInterval.current);
    };
  }, []);

  // Aktualizace rozměrů canvasu při změně rozměrů okna
  useEffect(() => {
    const handleResize = () => {
      if (graphRef.current) {
        const container = graphRef.current.parentElement;
        graphRef.current.width = container.clientWidth;
        graphRef.current.height = container.clientHeight;
        
        if (isGameRunning) {
          updateGameVisuals(multiplier);
        }
      }
    };
    
    setTimeout(handleResize, 100);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isGameRunning, multiplier]);

  // Zpracování vstupu pro sázku
  const handleBetAmountChange = (e) => {
    const value = e.target.value;
    setInputAmount(value);
    
    if (/^\d+$/.test(value)) {
      setBetAmount(parseInt(value));
    }
  };

  // Nastavení předvolených sázek
  const setQuickBet = (amount) => {
    setBetAmount(amount);
    setInputAmount(amount.toString());
  };

  // Funkce pro zobrazení výsledkové zprávy
  const getResultMessage = () => {
    switch (gameResult) {
      case "win": return `Vyhráli jste ${betAmount * 2} kreditů!`;
      case "lose": return "Prohráli jste!";
      case "draw": return "Remíza! Vaše sázka byla vrácena.";
      default: return "";
    }
  };

  return (
    <div className="crash-game-container">
      <Navbar username={username} initialCredits={credits} handleLogout={handleLogout} />
      
      <div className="game-container pvp-mode">
        <h1>PVP Crash Battle</h1>
        
        {/* Notifikace */}
        <div className="notifications-container">
          {notifications.map(notification => (
            <div key={notification.id} className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          ))}
        </div>
        
        <div className="pvp-status-board">
          <div className={`player-box ${winner === player1Data.username ? 'winner' : ''}`}>
            <div className="player-name">{player1Data.username} (vy)</div>
            <div className="player-status">
              {player1Data.hasCashed ? `Cashout @ ${player1Data.cashoutMultiplier.toFixed(2)}x` : isGameRunning ? "Hraje..." : "Připraven"}
            </div>
          </div>
          
          <div className="vs-indicator">VS</div>
          
          <div className={`player-box ${winner === player2Data.username ? 'winner' : ''}`}>
            <div className="player-name">{player2Data.username}</div>
            <div className="player-status">
              {player2Data.hasCashed ? `Cashout @ ${player2Data.cashoutMultiplier.toFixed(2)}x` : isGameRunning ? "Hraje..." : "Připraven"}
            </div>
          </div>
        </div>
        
        <div className="crash-display">
          <div className={`crash-status ${showCrashStatus ? 'active' : ''}`}>
            CRASH!
          </div>
          
          <div className="graph-multiplier-value">
            {multiplier.toFixed(2)}x
          </div>
          
          <div className="graph-container">
            <canvas ref={graphRef} className="graph-canvas"></canvas>
            <div 
              ref={shipRef} 
              className="ship-icon"
              style={{ background: 'orange', width: '20px', height: '20px', borderRadius: '50%' }}
            ></div>
          </div>
          
          {!isGameRunning && gameResult && (
            <div className={`game-result ${gameResult}`}>
              <h3>{getResultMessage()}</h3>
              {winner && <p>Vítěz: {winner}</p>}
            </div>
          )}
        </div>
        
        {isWaitingForOpponent ? (
          <div className="waiting-message">
            <p>Čekání na soupeře...</p>
            <div className="loader"></div>
            <button className="cancel-button" onClick={() => {
              if (pollInterval.current) clearInterval(pollInterval.current);
              setIsWaitingForOpponent(false);
              updateCredits(betAmount); // Vrácení sázky
            }}>Zrušit hledání</button>
          </div>
        ) : (
          <div className="bet-controls">
            {!isGameRunning ? (
              <>
                <div className="bet-amount">
                  <label htmlFor="bet-amount">Sázka:</label>
                  <input 
                    type="text" 
                    id="bet-amount" 
                    value={inputAmount || betAmount}
                    onChange={handleBetAmountChange}
                    placeholder="Zadejte částku"
                  />
                </div>
                
                <div className="quick-bet-amounts">
                  <button onClick={() => setQuickBet(10)}>10</button>
                  <button onClick={() => setQuickBet(50)}>50</button>
                  <button onClick={() => setQuickBet(100)}>100</button>
                  <button onClick={() => setQuickBet(500)}>500</button>
                </div>
                
                <div className="credit-info">
                  Dostupné kredity: {credits.toLocaleString()}
                </div>
                
                <button 
                  className="start-game-button" 
                  onClick={startGame}
                  disabled={betAmount <= 0 || betAmount > credits}
                >
                  Najít soupeře a vsadit {betAmount} kreditů
                </button>
              </>
            ) : (
              <button 
                className="cashout-button" 
                onClick={handleCashout}
                disabled={player1Data.hasCashed}
              >
                CASHOUT @ {multiplier.toFixed(2)}x
              </button>
            )}
          </div>
        )}
        
        <div className="pvp-game-rules">
          <h3>Pravidla PVP Crash hry:</h3>
          <ul>
            <li>Oba hráči začínají se stejnou sázkou</li>
            <li>Vyhrajte tím, že se dostanete výš než váš soupeř</li>
            <li>Musíte včas stisknout CASHOUT, než hra crashne</li>
            <li>Pokud oba crashnete, nikdo nevyhrává</li>
            <li>Pokud jen jeden hráč stihne cashout, vyhrává</li>
            <li>Pokud oba stihnou cashout, vyhrává ten s vyšším multiplierem</li>
            <li>Vítěz získává dvojnásobek své sázky</li>
            <li>Při remíze se sázky vrací oběma hráčům</li>
          </ul>
        </div>
        
        <div className="game-history">
          <h3>Historie her</h3>
          <div className="history-items pvp-history">
            {gameHistory.map((game, index) => (
              <div key={index} className="pvp-history-item">
                <div className="history-crash-point">Crash @ {game.crashPoint.toFixed(2)}x</div>
                <div className="history-players">
                  <div className={`history-player ${game.winner === game.player1.username ? 'winner' : ''}`}>
                    {game.player1.username}: {game.player1.hasCashed ? `${game.player1.cashoutMultiplier.toFixed(2)}x` : 'CRASH'}
                  </div>
                  <div className={`history-player ${game.winner === game.player2.username ? 'winner' : ''}`}>
                    {game.player2.username}: {game.player2.hasCashed ? `${game.player2.cashoutMultiplier.toFixed(2)}x` : 'CRASH'}
                  </div>
                </div>
                <div className="history-result">
                  {game.winner ? `Vítěz: ${game.winner}` : 'Remíza'}
                </div>
              </div>
            ))}
            {gameHistory.length === 0 && (
              <div className="empty-history-message">
                Zatím neproběhla žádná hra
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PvpCrash;
