import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';
import Navbar from '../components/Navbar';
import bigInt from 'big-integer';

function Profile() {
  const [tradeLink, setTradeLink] = useState('');
  const [inventory, setInventory] = useState([]);
  const [isTradeLinkSaved, setIsTradeLinkSaved] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  // Změna z jednoho vybraného skinu na pole vybraných skinů
  const [selectedSkins, setSelectedSkins] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const navigate = useNavigate();
  const [steamID, setSteamID] = useState('');
  
  // Přidání stavů pro výpočet hodnoty vybraných skinů
  const [totalSelectedValue, setTotalSelectedValue] = useState(0);
  const [expectedCredits, setExpectedCredits] = useState(0);
  
  // Přidání stavů pro filtrování a řazení
  const [sortOrder, setSortOrder] = useState('default');
  const [filterMinPrice, setFilterMinPrice] = useState('');
  const [filterMaxPrice, setFilterMaxPrice] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    console.log('[DEBUG] Token načten z localStorage:', token);
    return token;
  };

  // Funkce pro přepočet dolarů na kredity (1000 kreditů za $1)
  const calculateCredits = (dollarValue) => {
    return Math.round(dollarValue * 1000);
  };
  
  const fetchInventoryPage = async (pageNumber) => {
    const authToken = getAuthToken();
    if (!authToken) {
      setError('Uživatel není přihlášen.');
      return;
    }

    try {
      const limit = 20; 
      const offset = pageNumber * limit;
      console.log(`[DEBUG] Načítám inventář pro stránku ${pageNumber} s offsetem ${offset}`);

      const response = await axios.get(`/profile/inventory?limit=${limit}&offset=${offset}`, {
        headers: { Authorization: `Bearer ${authToken}` },
        withCredentials: true,
      });
      const { inventory: newItems, total } = response.data;
      const updatedItems = newItems.map((item) => ({
        ...item,
        market_hash_name: item.name,
      }));

      console.log('[DEBUG] Upravené skiny s market_hash_name:', updatedItems);

      const inventoryWithPrices = await fetchSkinPrices(updatedItems); 
      setInventory((prevItems) => [...prevItems, ...inventoryWithPrices]);
      setHasMore(offset + newItems.length < total); 
    } catch (err) {
      setError('Nepodařilo se načíst další stránku inventáře.');
      console.error('[DEBUG] Chyba při načítání inventáře:', err);
    }
  };

  // Upravená funkce pro obchodování s více skiny
  const handleTrade = async () => {
    console.log('[DEBUG] handleTrade called');
    console.log('[DEBUG] selectedSkins:', selectedSkins);
    console.log('[DEBUG] steamID:', steamID);
    
    if (selectedSkins.length === 0 || !steamID) {
      console.log('[DEBUG] selectedSkins nebo steamID chybí', { selectedSkins, steamID });
      return;
    }
  
    try {
      // Vytvoření pole assetIds a výpočet celkové hodnoty kreditů
      const assetIds = selectedSkins.map(skin => skin.assetid);
      
      // Přenos skinů na Steam bota a vytvoření obchodní nabídky
      console.log('[DEBUG] Přenáším skiny na Steam bota a vytvářím obchodní nabídku:', selectedSkins);
      const transferResponse = await axios.post('/skinTrades/transfer-skin', {
        steamID: steamID,
        skinIDs: assetIds, // Změna z skinID na pole skinIDs
        credits: expectedCredits, // Použití vypočtené hodnoty kreditů
      }, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        withCredentials: true,
      });
  
      if (transferResponse.status !== 200) {
        console.error('[DEBUG] Neúspěšný přenos skinů:', transferResponse);
        alert('Přenos skinů na Steam bota selhal.');
        return;
      }
  
      alert(`Nabídka byla odeslána! Po potvrzení obchodu získáte ${expectedCredits.toLocaleString()} kreditů.`);
      
      // Reset výběru
      setSelectedSkins([]);
      setTotalSelectedValue(0);
      setExpectedCredits(0);
      
      // Aktualizace inventáře
      setInventory([]);
      setPage(0);
      fetchInventoryPage(0);
    } catch (error) {
      console.error('Chyba při odesílání nabídky:', error);
      alert('Nastala chyba při odesílání nabídky.');
    }
  };

  const fetchSkinPrices = async (skins) => {
    const token = getAuthToken();
    if (!token) {
      console.error('[DEBUG] Token chybí. Nelze načíst ceny skinů.');
      throw new Error('Chybějící token');
    }
    try {
      console.log('[DEBUG] Seznam skinů odesílaný na server:', skins);
      setIsLoadingPrices(true);
      const response = await axios.post(
        '/api/skin-prices',
        { skins },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('[DEBUG] Odpověď ze serveru na skin-prices:', response.data);
      setIsLoadingPrices(false);

      return skins.map((skin) => {
        console.log(`[DEBUG] Mapuji skin: ${skin.market_hash_name}`);
        const priceData = response.data.find((item) => item.marketHashName === skin.market_hash_name);
        if (!priceData) {
          console.warn(`[DEBUG] Nenalezena cena pro skin: ${skin.market_hash_name}`);
        }
        return { ...skin, price: priceData?.price || 'Cena nedostupná' };
      });
    } catch (error) {
      console.error('[DEBUG] Chyba při načítání cen:', error.message);
      setIsLoadingPrices(false);
      return skins.map((skin) => ({ ...skin, price: 'Chyba při načítání' }));
    }
  };

  const fetchProfileData = async () => {
    const authToken = getAuthToken();
    if (!authToken) {
      console.error('[DEBUG] Uživatel není přihlášen. Žádný token není uložen.');
      setError('Uživatel není přihlášen.');
      return;
    }

    try {
      console.log('[DEBUG] Načítám trade link...');
      const tradeLinkResponse = await axios.get('/profile/trade-link', {
        headers: { Authorization: `Bearer ${authToken}` },
        withCredentials: true,
      });
      console.log('[DEBUG] Odpověď ze serveru na trade-link:', tradeLinkResponse.data);

      const savedTradeLink = tradeLinkResponse.data.tradeLink;
      let userSteamID;
      if (savedTradeLink) {
        console.log('[DEBUG] Trade link existuje:', savedTradeLink);
        setTradeLink(savedTradeLink);

        const urlParams = new URLSearchParams(new URL(savedTradeLink).search);
        const steamID32 = urlParams.get('partner');
        console.log('[DEBUG] Extrahované SteamID32:', steamID32);

        userSteamID = bigInt(steamID32).add(bigInt('76561197960265728'));
        console.log('[DEBUG] Převedené SteamID64:', userSteamID);

        setSteamID(userSteamID.toString());
        console.log('[DEBUG] SteamID nastaveno:', userSteamID.toString());
        setIsTradeLinkSaved(true);

        // Načtení inventáře
        fetchInventoryPage(0);
      } else {
        console.log('[DEBUG] Žádný trade link není uložen.');
        setIsTradeLinkSaved(false);
      }
    } catch (err) {
      setError('Nepodařilo se načíst profil.');
      console.error('[DEBUG] Chyba při načítání profilu:', err);
    }
  };

  const handleSaveTradeLink = async () => {
    const authToken = getAuthToken();
    if (!authToken) {
      console.error('[DEBUG] Uživatel není přihlášen. Žádný token není uložen.');
      setError('Uživatel není přihlášen.');
      return;
    }

    try {
      console.log('[DEBUG] Ukládám trade link:', tradeLink);
      const response = await axios.post(
        '/profile/trade-link',
        { tradeLink },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log('[DEBUG] Trade link byl úspěšně uložen:', response.data);
      setSuccessMessage('Trade link byl úspěšně uložen.');
      setIsTradeLinkSaved(true);

      // Znova načteme profil (aby se aktualizovalo SteamID, inventář atd.)
      setInventory([]);
      fetchProfileData();
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Nepodařilo se uložit trade link.';
      console.error('[DEBUG] Chyba při ukládání trade linku:', errorMessage, err);
      setError(errorMessage);
    }
  };

  // Funkce pro vícenásobný výběr skinů
  const handleItemClick = (assetid) => {
    const clickedItem = inventory.find((item) => item.assetid === assetid);
    
    // Kontrola, zda je skin již vybraný
    const isSelected = selectedSkins.some(skin => skin.assetid === assetid);
    
    let updatedSelectedSkins;
    if (isSelected) {
      // Odebrat skin z výběru
      updatedSelectedSkins = selectedSkins.filter(skin => skin.assetid !== assetid);
    } else {
      // Přidat skin do výběru
      updatedSelectedSkins = [...selectedSkins, clickedItem];
    }
    
    setSelectedSkins(updatedSelectedSkins);
    
    // Výpočet celkové hodnoty a očekávaných kreditů
    const newTotalValue = updatedSelectedSkins.reduce((sum, skin) => {
      const priceString = skin.price?.replace('€', '').replace('$', '').replace(',', '.').trim();
      const skinPrice = parseFloat(priceString) || 0;
      return sum + skinPrice;
    }, 0);
    
    setTotalSelectedValue(newTotalValue);
    setExpectedCredits(calculateCredits(newTotalValue));
    
    console.log(`[DEBUG] Aktualizace výběru skinů:`, updatedSelectedSkins);
    console.log(`[DEBUG] Celková hodnota: $${newTotalValue.toFixed(2)}, Kredity: ${calculateCredits(newTotalValue)}`);
  };

  // Funkce pro zrušení všech výběrů
  const clearSelection = () => {
    setSelectedSkins([]);
    setTotalSelectedValue(0);
    setExpectedCredits(0);
  };

  // Funkce pro filtrování a řazení inventáře
  const getFilteredAndSortedInventory = () => {
    if (!inventory || inventory.length === 0) return [];
    
    // Filtrování podle ceny a vyhledávání
    let filteredItems = inventory.filter(item => {
      const priceString = item.price?.replace('€', '').replace('$', '').replace(',', '.').trim();
      const itemPrice = parseFloat(priceString) || 0;
      const minPrice = filterMinPrice ? parseFloat(filterMinPrice) : 0;
      const maxPrice = filterMaxPrice ? parseFloat(filterMaxPrice) : Infinity;
      
      // Filtr ceny
      const priceInRange = itemPrice >= minPrice && itemPrice <= maxPrice;
      
      // Filtr vyhledávání (podle názvu)
      const matchesSearch = !searchQuery || 
        item.name?.toLowerCase().includes(searchQuery.toLowerCase());
      
      return priceInRange && matchesSearch;
    });
    
    // Řazení
    switch (sortOrder) {
      case 'priceAsc':
        return filteredItems.sort((a, b) => {
          const priceA = parseFloat(a.price?.replace('€', '').replace('$', '').replace(',', '.').trim()) || 0;
          const priceB = parseFloat(b.price?.replace('€', '').replace('$', '').replace(',', '.').trim()) || 0;
          return priceA - priceB;
        });
      case 'priceDesc':
        return filteredItems.sort((a, b) => {
          const priceA = parseFloat(a.price?.replace('€', '').replace('$', '').replace(',', '.').trim()) || 0;
          const priceB = parseFloat(b.price?.replace('€', '').replace('$', '').replace(',', '.').trim()) || 0;
          return priceB - priceA;
        });
      default:
        return filteredItems;
    }
  };

  // Výpočet celkové hodnoty inventáře
  const calculateInventoryValue = () => {
    if (!inventory || inventory.length === 0) return 0;
    
    return inventory.reduce((sum, item) => {
      const priceString = item.price?.replace('€', '').replace('$', '').replace(',', '.').trim();
      const itemPrice = parseFloat(priceString) || 0;
      return sum + itemPrice;
    }, 0);
  };

  const loadMoreItems = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    loadMoreItems();
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (page > 0) {
      fetchInventoryPage(page);
    }
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  // Pokud máme error, zobrazíme chybové hlášení i formulář pro zadání trade linku
  if (error) {
    console.error('[DEBUG] Chyba při načítání stránky:', error);
    return (
      <div>
        <p className="profile-error">{error}</p>
        <div className="trade-link-section">
          <h2>Zadejte / upravte váš Steam trade link</h2>
          <label htmlFor="steamTradeLink">Steam Trade Link:</label>
          <input
            type="text"
            id="steamTradeLink"
            name="steamTradeLink"
            value={tradeLink}
            onChange={(e) => setTradeLink(e.target.value)}
            placeholder="Vložte váš Steam trade link"
            autoComplete="on"
          />
          <button onClick={handleSaveTradeLink}>Uložit</button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar username="Hráč" handleLogout={handleLogout} />
      <div className="profile-container">
        <h1 className="profile-header">Vítejte!</h1>

        {/* Sekce úpravy trade linku – vždy dostupná */}
        <div className="trade-link-section">
          <h2>Zadejte / upravte váš Steam trade link</h2>
          <label htmlFor="steamTradeLink">Steam Trade Link:</label>
          <input
            type="text"
            id="steamTradeLink"
            name="steamTradeLink"
            value={tradeLink}
            onChange={(e) => setTradeLink(e.target.value)}
            placeholder="Vložte váš Steam trade link"
            autoComplete="on"
          />
          <button onClick={handleSaveTradeLink}>Uložit</button>
          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>

        {/* Sekce inventáře – zobrazí se jen pokud je trade link uložen */}
        {isTradeLinkSaved && (
          <>
            <div className="inventory-section">
              <h2>Inventář</h2>
              
              {/* Panel pro filtrování a řazení */}
              <div className="inventory-tools">
                <div className="search-and-filter">
                  <div className="search-box">
                    <input
                      type="text"
                      placeholder="Vyhledat skin..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  
                  <div className="price-filter">
                    <input
                      type="number"
                      placeholder="Min $"
                      value={filterMinPrice}
                      onChange={(e) => setFilterMinPrice(e.target.value)}
                    />
                    <span>-</span>
                    <input
                      type="number"
                      placeholder="Max $"
                      value={filterMaxPrice}
                      onChange={(e) => setFilterMaxPrice(e.target.value)}
                    />
                  </div>
                  
                  <div className="sort-options">
                    <select 
                      value={sortOrder} 
                      onChange={(e) => setSortOrder(e.target.value)}
                    >
                      <option value="default">Výchozí řazení</option>
                      <option value="priceAsc">Cena (vzestupně)</option>
                      <option value="priceDesc">Cena (sestupně)</option>
                    </select>
                  </div>
                </div>
                
                {/* Statistiky inventáře */}
                <div className="inventory-stats">
                  <div className="stat-item">
                    <span>Počet skinů:</span>
                    <span>{inventory ? inventory.length : 0}</span>
                  </div>
                  <div className="stat-item">
                    <span>Celková hodnota:</span>
                    <span>${calculateInventoryValue().toFixed(2)}</span>
                  </div>
                  <div className="stat-item">
                    <span>Hodnota v kreditech:</span>
                    <span>{calculateCredits(calculateInventoryValue()).toLocaleString()} kreditů</span>
                  </div>
                </div>
              </div>
              
              {/* Panel s informacemi o vybraných skinech */}
              {selectedSkins.length > 0 && (
                <div className="selected-skins-panel">
                  <div className="selected-info">
                    <div className="selected-count">
                      <span>Vybráno skinů:</span>
                      <span>{selectedSkins.length}</span>
                    </div>
                    <div className="selected-value">
                      <span>Celková hodnota:</span>
                      <span>${totalSelectedValue.toFixed(2)}</span>
                    </div>
                    <div className="expected-credits">
                      <span>Očekávané kredity:</span>
                      <span>{expectedCredits.toLocaleString()}</span>
                    </div>
                  </div>
                  
                  <div className="selected-actions">
                    <button 
                      className="trade-button-inline" 
                      onClick={handleTrade}
                      disabled={selectedSkins.length === 0}
                    >
                      Obchodovat vybrané skiny
                    </button>
                    <button 
                      className="clear-selection" 
                      onClick={clearSelection}
                    >
                      Zrušit výběr
                    </button>
                  </div>
                </div>
              )}
              
              {isLoadingPrices && <p className="loading-message">Načítám ceny skinů...</p>}
              
              <div className="inventory-grid">
                {getFilteredAndSortedInventory().map((item) => (
                  <div
                    className={`inventory-item ${selectedSkins.some(skin => skin.assetid === item.assetid) ? 'selected' : ''}`}
                    key={item.assetid}
                    onClick={() => handleItemClick(item.assetid)}
                  >
                    {item.icon_url ? (
                      <img src={item.icon_url} alt={item.name} className="inventory-item-image" />
                    ) : (
                      <div className="inventory-placeholder">Obrázek není dostupný</div>
                    )}
                    <p><strong>{item.name}</strong></p>
                    <p>{item.price}</p>
                    
                    {/* Zobrazení kreditů */}
                    <p className="item-credits">
                      {item.price && item.price !== 'Cena nedostupná' && item.price !== 'Chyba při načítání'
                        ? `${calculateCredits(parseFloat(item.price.replace('€', '').replace('$', '').replace(',', '.').trim()) || 0).toLocaleString()} kreditů`
                        : ''}
                    </p>
                    
                    {/* Zobrazení značky výběru */}
                    {selectedSkins.some(skin => skin.assetid === item.assetid) && (
                      <div className="selected-overlay">✓</div>
                    )}
                  </div>
                ))}
              </div>
              
              {hasMore && (
                <button className="load-more" onClick={() => setPage((prevPage) => prevPage + 1)}>
                  Načíst více
                </button>
              )}
            </div>

            {/* Odstraníme plovoucí tlačítko, protože teď máme inline tlačítka v panelu vybraných */}
          </>
        )}
      </div>
    </div>
  );
}

export default Profile;