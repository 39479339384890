import React, { useState, useEffect, useContext } from 'react';
import Navbar from './Navbar';
import { CreditsContext } from '../context/CreditsContext';
import './CardBattle.css';
import soundManager from '../utils/SoundManager';

function CardBattle({ username, handleLogout }) {
  const { credits } = useContext(CreditsContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [betAmount, setBetAmount] = useState(10);
  const [playerCards, setPlayerCards] = useState([]);
  const [opponentCards, setOpponentCards] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [playerCardSelected, setPlayerCardSelected] = useState(null);
  const [opponentCardSelected, setOpponentCardSelected] = useState(null);
  const [roundResult, setRoundResult] = useState('');
  const [gameResult, setGameResult] = useState('');
  const [waitingForOpponent, setWaitingForOpponent] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);

  // Inicializace hry
  const startGame = () => {
    if (isPlaying) return;

    setIsPlaying(true);
    setCurrentRound(1);
    setPlayerScore(0);
    setOpponentScore(0);
    setPlayerCardSelected(null);
    setOpponentCardSelected(null);
    setRoundResult('');
    setGameResult('');

    // Generování náhodných karet pro hráče a protivníka (hodnoty 1-10)
    const newPlayerCards = Array.from({length: 3}, () => Math.floor(Math.random() * 10) + 1);
    setPlayerCards(newPlayerCards);

    // Simulujeme karty pro počítačového protivníka
    const newOpponentCards = Array.from({length: 3}, () => Math.floor(Math.random() * 10) + 1);
    setOpponentCards(newOpponentCards);

    // Přehrajeme zvuk startu hry, pokud existuje
    if (soundManager.sounds['buttonClick']) {
      soundManager.play('buttonClick');
    }
  };

  // Funkce pro výběr karty hráčem
  const selectCard = (cardIndex) => {
    if (!isPlaying || currentRound > 3 || playerCardSelected !== null) return;

    setPlayerCardSelected(cardIndex);
    setWaitingForOpponent(true);

    // Simulujeme, že protivník vybírá kartu (za 1 sekundu)
    setTimeout(() => {
      // Jednoduchá AI - vybere náhodnou kartu z dostupných
      const availableOpponentCards = opponentCards.filter(card => card !== null);
      const randomIndex = Math.floor(Math.random() * availableOpponentCards.length);
      const opponentCardIndex = opponentCards.findIndex(card => card === availableOpponentCards[randomIndex]);

      setOpponentCardSelected(opponentCardIndex);
      setWaitingForOpponent(false);

      // Vyhodnocení kola
      evaluateRound(cardIndex, opponentCardIndex);
    }, 1000);
  };

  // Vyhodnocení kola
  const evaluateRound = (playerCardIndex, opponentCardIndex) => {
    const playerCardValue = playerCards[playerCardIndex];
    const opponentCardValue = opponentCards[opponentCardIndex];

    let newPlayerScore = playerScore;
    let newOpponentScore = opponentScore;
    let result = '';

    if (playerCardValue > opponentCardValue) {
      newPlayerScore += 1;
      result = 'Vyhráli jste toto kolo!';
      if (soundManager.sounds['win']) {
        soundManager.play('win');
      }
    } else if (playerCardValue < opponentCardValue) {
      newOpponentScore += 1;
      result = 'Protivník vyhrál toto kolo.';
      if (soundManager.sounds['lose']) {
        soundManager.play('lose');
      }
    } else {
      result = 'Remíza v tomto kole!';
      if (soundManager.sounds['buttonClick']) {
        soundManager.play('buttonClick');
      }
    }

    setPlayerScore(newPlayerScore);
    setOpponentScore(newOpponentScore);
    setRoundResult(result);

    // Odstraníme použité karty
    const newPlayerCards = [...playerCards];
    newPlayerCards[playerCardIndex] = null;
    setPlayerCards(newPlayerCards);

    const newOpponentCards = [...opponentCards];
    newOpponentCards[opponentCardIndex] = null;
    setOpponentCards(newOpponentCards);

    // Příprava na další kolo
    setTimeout(() => {
      if (currentRound < 3) {
        setCurrentRound(currentRound + 1);
        setPlayerCardSelected(null);
        setOpponentCardSelected(null);
        setRoundResult('');
      } else {
        // Konec hry
        endGame(newPlayerScore, newOpponentScore);
      }
    }, 2000);
  };

  // Konec hry
  const endGame = (finalPlayerScore, finalOpponentScore) => {
    let finalResult = '';
    let winAmount = 0;

    if (finalPlayerScore > finalOpponentScore) {
      finalResult = 'Gratuluji! Vyhráli jste hru!';
      winAmount = betAmount * 2;
      if (soundManager.sounds['win']) {
        soundManager.play('win');
      }
    } else if (finalPlayerScore < finalOpponentScore) {
      finalResult = 'Bohužel, prohráli jste hru.';
      if (soundManager.sounds['lose']) {
        soundManager.play('lose');
      }
    } else {
      finalResult = 'Hra skončila remízou!';
      winAmount = betAmount; // Vrátíme sázku při remíze
    }

    setGameResult(finalResult);

    // Aktualizace historie her
    const newGame = {
      date: new Date().toLocaleString(),
      playerScore: finalPlayerScore,
      opponentScore: finalOpponentScore,
      result: finalPlayerScore > finalOpponentScore ? 'win' : finalPlayerScore < finalOpponentScore ? 'loss' : 'draw',
      betAmount,
      winAmount
    };

    setGameHistory(prev => [newGame, ...prev.slice(0, 9)]);

    // Resetování hry po 3 sekundách
    setTimeout(() => {
      setIsPlaying(false);
    }, 3000);
  };

  // Funkce pro změnu sázky
  const handleBetChange = (amount) => {
    if (!isPlaying) {
      setBetAmount(amount);
    }
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="card-battle-container">
        <h1>Card Battle</h1>
        
        <div className="game-info">
          <p>Každý hráč dostane 3 karty s různými hodnotami. V každém kole oba vyloží jednu kartu, vyšší hodnota vyhrává bod. Po 3 kolech vyhrává hráč s více body.</p>
        </div>
        
        {!isPlaying ? (
          <div className="startup-controls">
            <div className="bet-controls">
              <h3>Vyberte sázku:</h3>
              <div className="bet-buttons">
                <button onClick={() => handleBetChange(10)} className={betAmount === 10 ? 'active' : ''}>10</button>
                <button onClick={() => handleBetChange(20)} className={betAmount === 20 ? 'active' : ''}>20</button>
                <button onClick={() => handleBetChange(50)} className={betAmount === 50 ? 'active' : ''}>50</button>
                <button onClick={() => handleBetChange(100)} className={betAmount === 100 ? 'active' : ''}>100</button>
              </div>
            </div>
            <button 
              className="start-game-button" 
              onClick={startGame}
              disabled={credits < betAmount}
            >
              Start Game
            </button>
          </div>
        ) : (
          <div className="game-board">
            <div className="game-status">
              <div className="round-info">Kolo {currentRound}/3</div>
              <div className="score-board">
                <div className="player-score">Hráč: {playerScore}</div>
                <div className="vs">vs</div>
                <div className="opponent-score">Soupeř: {opponentScore}</div>
              </div>
              {roundResult && <div className="round-result">{roundResult}</div>}
              {gameResult && <div className="game-result">{gameResult}</div>}
              {waitingForOpponent && <div className="waiting-message">Čekání na protihráče...</div>}
            </div>
            
            <div className="battle-area">
              <div className="player-cards">
                <h3>Vaše karty</h3>
                <div className="cards-container">
                  {playerCards.map((card, index) => (
                    card !== null && (
                      <button 
                        key={index}
                        className={`card ${playerCardSelected === index ? 'selected' : ''}`}
                        onClick={() => selectCard(index)}
                        disabled={playerCardSelected !== null}
                      >
                        {card}
                      </button>
                    )
                  ))}
                </div>
              </div>
              
              <div className="opponent-cards">
                <h3>Karty soupeře</h3>
                <div className="cards-container">
                  {opponentCards.map((card, index) => (
                    card !== null && (
                      <div 
                        key={index}
                        className={`card opponent ${opponentCardSelected === index ? 'selected' : ''}`}
                      >
                        {opponentCardSelected === index ? card : '?'}
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        
        <div className="game-history">
          <h3>Historie posledních her</h3>
          <table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Skóre</th>
                <th>Výsledek</th>
                <th>Sázka</th>
                <th>Výhra</th>
              </tr>
            </thead>
            <tbody>
              {gameHistory.map((game, index) => (
                <tr key={index} className={`history-row ${game.result}`}>
                  <td>{game.date}</td>
                  <td>{game.playerScore} : {game.opponentScore}</td>
                  <td>
                    {game.result === 'win' && 'Výhra'}
                    {game.result === 'loss' && 'Prohra'}
                    {game.result === 'draw' && 'Remíza'}
                  </td>
                  <td>{game.betAmount}</td>
                  <td>{game.winAmount > 0 ? `+${game.winAmount}` : '0'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CardBattle;
