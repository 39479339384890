import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import backgroundImage from './components/images/backround1.png';

function LoginPage({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showFullForm, setShowFullForm] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [requirePassword, setRequirePassword] = useState(false);
  const [storedUserData, setStoredUserData] = useState(null);
  
  // Odstraňujeme komplikovanou logiku pro animaci písmenek
  const [letterStates, setLetterStates] = useState([
    { letter: 'P', isRotating: true },
    { letter: 'V', isRotating: true },
    { letter: 'P', isRotating: true },
    { letter: 'R', isRotating: true },
    { letter: 'N', isRotating: true },
    { letter: 'G', isRotating: true }
  ]);
  
  // Stav pro sledování typu současné animace
  const [animationType, setAnimationType] = useState('initial'); // 'initial' nebo 'interactive'
  const [initialAnimationDone, setInitialAnimationDone] = useState(false);
  const [interactiveAnimationStep, setInteractiveAnimationStep] = useState(0); // Krok pro postupné zastavování
  
  // Oddělené stavy pro fokusování polí
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  
  // Ref pro sledování polí formuláře
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    // Načtení posledního e-mailu při prvním načtení
    const lastEmail = localStorage.getItem('lastEmail');
    if (lastEmail) {
      setEmail(lastEmail);
      setShowFullForm(false); // Zobrazí jen pole pro heslo
    }

    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      const parsedData = JSON.parse(savedUserData);
      const lastLoginDate = new Date(parsedData.lastLogin);
      const now = new Date();
      const diffMinutes = Math.floor((now - lastLoginDate) / 1000 / 60);

      if (diffMinutes > 30 || !parsedData.email) {
        // Pokud session vypršela nebo není email
        setRequirePassword(true);
        localStorage.removeItem('userData'); // Odstranit neplatná data
      } else {
        setStoredUserData(parsedData);
      }
    }
  }, []);

  // Přidáváme nový stav pro sledování, zda má svítit
  const [shouldPulse, setShouldPulse] = useState(false);
  
  useEffect(() => {
    // Implementace animace loga na přihlašovací stránce
    const letters = document.querySelectorAll('.animated-title .letter');
    const startDelay = 1000; // 1 sekunda před zahájením zastavování
    const stopInterval = 200; // 200ms mezi zastavením jednotlivých písmen
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    
    // Nastavení animace pro všechna písmena
    letters.forEach(letter => {
      letter.style.animation = 'verticalSlide 0.1s linear infinite';
      
      // Spouštíme interval pro změnu písmen
      const interval = setInterval(() => {
        letter.textContent = randomChars[Math.floor(Math.random() * randomChars.length)];
      }, 100);
      
      // Uložíme interval do atributu, abychom ho mohli později zastavit
      letter.dataset.interval = interval;
    });
    
    // Postupné zastavení písmen zleva doprava
    setTimeout(() => {
      letters.forEach((letter, index) => {
        setTimeout(() => {
          // Zastavíme měnění písmen
          clearInterval(parseInt(letter.dataset.interval));
          // Zastavíme animaci
          letter.style.animation = 'none';
          // Nastavíme konečné písmeno
          letter.textContent = letter.getAttribute('data-final');
          
          // Aktualizujeme stav písmenek (pouze v LoginPage)
          setLetterStates(prev => prev.map((item, i) => 
            i === index ? { ...item, isRotating: false } : item
          ));
          
          // Nastavíme pulzování po dokončení všech písmen
          if (index === letters.length - 1) {
            setTimeout(() => {
              setShouldPulse(true);
            }, 1000);
          }
        }, index * stopInterval); // Každé další písmeno se zastaví o stopInterval později
      });
    }, startDelay); // Začínáme zastavovat po startDelay
  }, []);

  const handleEmailFocus = () => {
    setIsEmailFocused(true);
    setIsPasswordFocused(false);
  };
  
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
    setIsEmailFocused(false);
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const loginEmail = requirePassword && storedUserData ? storedUserData.email : email;
      const response = await axios.post('https://pvprng.com/auth/login', {
        email: loginEmail,
        password,
      });

      if (response.status === 200) {
        const userData = {
          email: loginEmail,
          profileImage: response.data.profileImage || null,
          username: response.data.username || 'Neznámý uživatel',
          lastLogin: new Date().toISOString(),
        };

        // Uložení pouze nezbytných dat (bez hesla)
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('authToken', response.data.token);
        
        // Pokud je zaškrtnuto "Zapamatovat si mě", uložíme e-mail
        if (rememberMe) {
          localStorage.setItem('lastEmail', loginEmail);
        }

        setIsAuthenticated(true);
        navigate('/game');
      }
    } catch (error) {
      console.error('Chyba při přihlášení:', error.response?.data || error.message);
      setErrorMessage(error.response?.data?.errors?.[0]?.msg || 'Přihlášení se nezdařilo.');
    }
  };

  const handleQuickLogin = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setErrorMessage('Session vypršela. Přihlaste se prosím znovu.');
        return;
      }

      const response = await axios.get('https://pvprng.com/verify-session', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        navigate('/game');
      }
    } catch (error) {
      console.error('Chyba při ověřování session:', error.response?.data || error.message);
      setErrorMessage('Session vypršela. Přihlaste se prosím znovu.');
      handleLogout(); // Odstranění neplatné session
    }
  };

  const handleLogout = () => {
    // Pokud je zaškrtnuto "Zapamatovat si mě", zachováme e-mail v localStorage
    const emailToRemember = rememberMe ? email : null;
    
    localStorage.removeItem('userData');
    localStorage.removeItem('authToken');
    
    if (emailToRemember) {
      localStorage.setItem('lastEmail', emailToRemember);
      setEmail(emailToRemember);
      setShowFullForm(false); // Zobrazí jen pole pro heslo
    } else {
      localStorage.removeItem('lastEmail');
      setEmail('');
      setShowFullForm(true); // Zobrazí celý formulář
    }
    
    setStoredUserData(null);
    setRequirePassword(false);
    setPassword('');
  };

  const goToRegister = () => {
    navigate('/register');
  };

  const switchToFullForm = () => {
    setShowFullForm(true);
  };

  useEffect(() => {
    const verifySession = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('Neplatný token.');
        }
  
        const response = await axios.get('https://pvprng.com/verify-session', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setIsAuthenticated(true);
          navigate('/game');
        }
      } catch (error) {
        console.error('Chyba při ověřování session:', error.response?.data || error.message);
        setStoredUserData(null);
        localStorage.removeItem('authToken');
        setRequirePassword(true);
      }
    };
  
    verifySession();
  }, [navigate, setIsAuthenticated]);  

  // Přidání stylu pozadí s použitím přímo importovaného obrázku
  const formBoxStyle = {
    '--background-image': `url(${backgroundImage})`
  };

  // Přidáme podmínku pro určení, zda mají všechna písmena zastavit rotaci
  const allLettersStopped = letterStates.every(letter => !letter.isRotating);

  return (
    <div className="login-container">
      <div className="form-box" style={formBoxStyle}>
        <h2 className="animated-title">
          {letterStates.map((item, index) => (
            <React.Fragment key={index}>
              {index === 3 && <span className="colon">:</span>}
              <span 
                className={`letter ${!item.isRotating ? 'stopped' : ''} 
                          ${allLettersStopped && shouldPulse ? 'pulsing' : ''}`} 
                data-final={item.letter}
              >
                {/* Náhodné písmeno se bude měnit v useEffect */}
                {String.fromCharCode(65 + Math.floor(Math.random() * 26))}
              </span>
            </React.Fragment>
          ))}
        </h2>
        {storedUserData ? (
          <div className="profile-container">
            <img
              src={storedUserData.profileImage || 'https://via.placeholder.com/100'}
              alt="Profil"
              className="profile-image"
              onClick={handleQuickLogin}
            />
            <p>{storedUserData.username}</p>
            {requirePassword ? (
              <>
                <input
                  type="password"
                  placeholder="Zadejte heslo"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Přihlásit se</button>
              </>
            ) : (
              <button className="link-button" onClick={handleQuickLogin}>
                Přihlásit se
              </button>
            )}
            <button className="link-button" onClick={handleLogout}>
              Změnit uživatele
            </button>
          </div>
        ) : (
          <>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            
            {!showFullForm ? (
              // Zjednodušený formulář s předvyplněným e-mailem
              <div className="quick-login-form">
                <div className="remembered-email">
                  <img 
                    src="https://via.placeholder.com/40" 
                    alt="Uživatel" 
                    className="user-icon"
                  />
                  <span>{email}</span>
                </div>
                <input
                  type="password"
                  placeholder="Heslo"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Přihlásit se</button>
                <button className="link-button" onClick={switchToFullForm}>
                  Přihlásit se jako jiný uživatel
                </button>
              </div>
            ) : (
              // Plný přihlašovací formulář s upravenými handlery
              <>
                <input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => setIsEmailFocused(false)}
                  onFocus={handleEmailFocus}
                  ref={emailInputRef}
                />
                <input
                  type="password"
                  placeholder="Heslo"
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={() => setIsPasswordFocused(false)}
                  onFocus={handlePasswordFocus}
                  ref={passwordInputRef}
                />
                <div className="remember-me">
                  <input
                    type="checkbox"
                    id="remember-me"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="remember-me">Zapamatovat si mě</label>
                </div>
                <button onClick={handleLogin}>Přihlásit se</button>
                <div className="register-prompt">
                  <p>Nemáte účet?</p>
                  <button className="link-button" onClick={goToRegister}>
                    Registrovat se
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
