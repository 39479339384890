import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import soundManager from '../utils/SoundManager';
import creditsManager from '../utils/creditsManager';

// Změníme Navbar na forwardRef pro přístup z rodičovských komponent
const Navbar = forwardRef(({ handleLogout, initialCredits }, ref) => {
  // Použijeme lokální stav pro kredity místo kontextu
  const [localCredits, setLocalCredits] = useState(initialCredits || 0);
  const [username, setUsername] = useState('');
  const [showDepositPopup, setShowDepositPopup] = useState(false);
  const navigate = useNavigate();

  // Funkce pro aktualizaci kreditů bez překreslení celé stránky
  const updateLocalCredits = (newCredits) => {
    setLocalCredits(newCredits);
    
    // Animace změny kreditů
    const creditsElement = document.querySelector('.credits-value');
    if (creditsElement) {
      creditsElement.classList.add('credits-updated');
      setTimeout(() => {
        creditsElement.classList.remove('credits-updated');
      }, 1000);
    }
  };

  // Jednorázové načtení profilu při inicializaci
  useEffect(() => {
    fetchProfileData();
  }, []);
  
  // Funkce pro načtení dat uživatele včetně kreditů z databáze
  const fetchProfileData = async () => {
    try {
      const credits = await creditsManager.fetchCredits();
      if (credits !== null) {
        updateLocalCredits(credits);
      }

      const token = localStorage.getItem('authToken');
      const response = await axios.get('/profile', {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setUsername(response.data.username);
      }
    } catch (error) {
      console.error('Chyba při načítání profilu:', error.response?.data || error.message);
    }
  };

  // Veřejná funkce pro manuální aktualizaci kreditů - používá creditsManager
  const refreshCredits = async () => {
    const credits = await creditsManager.fetchCredits();
    if (credits !== null) {
      updateLocalCredits(credits);
    }
    return credits;
  };

  // Zpřístupnění metody refreshCredits pro rodiče pomocí ref
  useImperativeHandle(ref, () => ({
    refreshCredits
  }));
  
  // Inicializace zvuků
  useEffect(() => {
    // Zvuky jsou již načteny v SoundSetup
  }, []);
  
  const handleButtonHover = () => {
    if (soundManager.sounds['buttonHover']) {
      soundManager.play('buttonHover');
    }
  };
  
  const handleButtonClick = () => {
    if (soundManager.sounds['buttonClick']) {
      soundManager.play('buttonClick');
    }
  };

  const handleDeposit = async (amount) => {
    try {
      const token = localStorage.getItem('authToken');
      
      const response = await axios.post(
        '/profile/deposit',
        { amount },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        const { updatedCredits } = response.data;
        updateLocalCredits(updatedCredits);
        console.log(`Vklad úspěšný! Nový stav: ${updatedCredits}`);
        setShowDepositPopup(false);

        // Přehrát zvuk úspěšné transakce
        if (soundManager.sounds['win']) {
          soundManager.play('win');
        }
      }
    } catch (error) {
      console.error('Chyba při vkladu:', error.response?.data || error.message);
    }
  };

  const handleClosePopup = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      setShowDepositPopup(false);
    }
  };

  useEffect(() => {
    // Jasná implementace animace loga
    const letters = document.querySelectorAll('.animated-logo .letter');
    const startDelay = 1000; // 1 sekunda před zahájením zastavování
    const stopInterval = 200; // 200ms mezi zastavením jednotlivých písmen
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    
    // Nastavení animace pro všechna písmena
    letters.forEach(letter => {
      letter.style.animation = 'verticalSlide 0.1s linear infinite';
      
      // Spouštíme interval pro změnu písmen
      const interval = setInterval(() => {
        letter.textContent = randomChars[Math.floor(Math.random() * randomChars.length)];
      }, 100);
      
      // Uložíme interval do atributu, abychom ho mohli později zastavit
      letter.dataset.interval = interval;
    });
    
    // Postupné zastavení písmen zleva doprava
    setTimeout(() => {
      letters.forEach((letter, index) => {
        setTimeout(() => {
          // Zastavíme měnění písmen
          clearInterval(parseInt(letter.dataset.interval));
          // Zastavíme animaci
          letter.style.animation = 'none';
          // Nastavíme konečné písmeno
          letter.textContent = letter.getAttribute('data-final');
        }, index * stopInterval); // Každé další písmeno se zastaví o stopInterval později
      });
    }, startDelay); // Začínáme zastavovat po startDelay
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="animated-logo">
          <span className="letter" data-final="P">X</span>
          <span className="letter" data-final="V">Q</span>
          <span className="letter" data-final="P">L</span>
          <span className="colon">:</span>
          <span className="letter" data-final="R">A</span>
          <span className="letter" data-final="N">T</span>
          <span className="letter" data-final="G">K</span>
        </Link>
      </div>
      <div className="navbar-center">
        <div className="navbar-item username-container">
          <span className="navbar-label">Hráč:</span>
          <span className="username-value">{username}</span>
        </div>
        <div className="navbar-item credits-container">
          <span className="navbar-label">Kredity:</span>
          <span className="credits-value">{localCredits.toLocaleString()}</span>
          <button 
            className="refresh-credits-button" 
            onClick={refreshCredits}
            title="Aktualizovat kredity"
          >
            <i className="fa fa-refresh"></i>
          </button>
        </div>
      </div>
      <div className="navbar-right">
        <button
          className="navbar-button deposit-button"
          onClick={() => {
            handleButtonClick();
            setShowDepositPopup(true);
          }}
          onMouseEnter={handleButtonHover}
        >
          <i className="fa fa-plus-circle"></i> Vložit
        </button>
        <Link
          to="/profile"
          className="navbar-button profile-button"
          onClick={handleButtonClick}
          onMouseEnter={handleButtonHover}
        >
          <i className="fa fa-user"></i> Profil
        </Link>
        <button
          className="navbar-button logout-button"
          onClick={(e) => {
            handleButtonClick();
            handleLogout(e);
          }}
          onMouseEnter={handleButtonHover}
        >
          <i className="fa fa-sign-out"></i> Odhlásit se
        </button>
      </div>

      {showDepositPopup && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-content">
            <h2>Vložit kredity</h2>
            <PaymentForm handleDeposit={handleDeposit} />
            <button
              className="close-button"
              onClick={() => setShowDepositPopup(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      )}
    </nav>
  );
});

// Exportujeme komponentu s forwardRef
export default Navbar;