const soundManager = {
  sounds: {},
  
  // Inicializace zvuku
  init(soundMap) {
    Object.keys(soundMap).forEach(name => {
      if (soundMap[name]) {
        this.loadSound(name, soundMap[name]);
      }
    });
  },
  
  // Načtení zvuku do paměti
  loadSound(name, url) {
    try {
      const audio = new Audio(url);
      audio.preload = 'auto';
      this.sounds[name] = audio;
      audio.load();
    } catch (error) {
      console.error(`Nepodařilo se načíst zvuk ${name}:`, error);
    }
  },
  
  // Přehrání zvuku
  play(name) {
    try {
      if (this.sounds[name]) {
        // Vytvoříme novou instanci zvuku pro případné překrývání
        const soundClone = this.sounds[name].cloneNode();
        soundClone.volume = 0.5; // 50% hlasitost
        soundClone.play().catch(error => {
          // Ignorujeme chyby při přehrávání (časté v prohlížečích)
          console.log(`Nepodařilo se přehrát zvuk ${name}:`, error);
        });
      }
    } catch (error) {
      console.error(`Chyba při přehrávání zvuku ${name}:`, error);
    }
  },
  
  // Zastavení zvuku
  stop(name) {
    try {
      if (this.sounds[name]) {
        this.sounds[name].pause();
        this.sounds[name].currentTime = 0;
      }
    } catch (error) {
      console.error(`Chyba při zastavení zvuku ${name}:`, error);
    }
  }
};

export default soundManager;
