import React from 'react';

const RouletteOverlay = ({ highlightedNumbers }) => {
  // Mapování čísel rulety na jejich úhly - přesné rozložení evropské rulety
  // (sekvence 0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, ...)
  const numberToAngleMap = {
    0: 0,
    32: 9.73, 15: 19.46, 19: 29.19, 4: 38.92,
    21: 48.65, 2: 58.38, 25: 68.11, 17: 77.84,
    34: 87.57, 6: 97.3, 27: 107.03, 13: 116.76,
    36: 126.49, 11: 136.22, 30: 145.95, 8: 155.68,
    23: 165.41, 10: 175.14, 5: 184.87, 24: 194.6,
    16: 204.33, 33: 214.06, 1: 223.79, 20: 233.52,
    14: 243.25, 31: 252.98, 9: 262.71, 22: 272.44,
    18: 282.17, 29: 291.9, 7: 301.63, 28: 311.36,
    12: 321.09, 35: 330.82, 3: 340.55, 26: 350.28
  };
  
  // Velikost úhlu jednoho segmentu (37 čísel, každé zabírá ~9.73 stupňů)
  const segmentAngle = 360 / 37;
  
  // Výpočet vrcholů trojúhelníku tak, aby přesně odpovídaly jednomu segmentu
  // Pro úhel 9.73 stupňů při pohledu ze středu:
  const halfAngleRad = (segmentAngle / 2) * (Math.PI / 180); // Převod na radiány
  const offset = 50 * Math.sin(halfAngleRad);                // Offset pro body od středové linie
  
  // Definice bodů pro SVG polygon (užší trojúhelník)
  const leftPoint = `${50 - offset},0`;  // Levý horní bod
  const rightPoint = `${50 + offset},0`; // Pravý horní bod
  const centerPoint = "50,50";           // Střed kruhu

  return (
    <div className="roulette-overlay">
      {Object.entries(numberToAngleMap).map(([number, angle]) => {
        const isHighlighted = highlightedNumbers.includes(parseInt(number));
        
        return (
          <div
            key={number}
            className="roulette-segment-container"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              transform: `rotate(${angle}deg)`, // Odstraněno rotationAdjustment - už je aplikováno na samotné ruletě
              transformOrigin: 'center center',
              display: isHighlighted ? 'block' : 'none'
            }}
          >
            <svg className={`roulette-segment ${isHighlighted ? 'highlighted' : ''}`} 
                 viewBox="0 0 100 100" 
                 preserveAspectRatio="none">
              <polygon 
                points={`${centerPoint} ${leftPoint} ${rightPoint}`}
                fill="rgba(255, 255, 0, 0.5)" 
                stroke="rgba(255, 255, 255, 0.8)" 
                strokeWidth="1" 
              />
            </svg>
            <div className="segment-number" style={{ position: 'absolute', top: '10%', left: '48%', color: 'white', fontWeight: 'bold' }}>
              {number}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RouletteOverlay;
