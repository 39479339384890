import React, { useState, useEffect } from 'react';
import './CardFinder.css';
import Navbar from './Navbar';

const CardFinder = ({ credits, username, handleLogout }) => {
  const [round, setRound] = useState(1);
  const [cards, setCards] = useState([]);
  const [dealerSelectedCards, setDealerSelectedCards] = useState([]);
  const [foundCards, setFoundCards] = useState([]);
  const [playerScore, setPlayerScore] = useState(0);
  const [dealerScore, setDealerScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('Najdi kartu, kterou dealer schoval!');
  const [showingCards, setShowingCards] = useState(true);
  const [flipAll, setFlipAll] = useState(false);
  const [clickable, setClickable] = useState(false);

  // Inicializace hry
  useEffect(() => {
    startNewRound();
  }, []);

  // Začátek nového kola
  const startNewRound = () => {
    // Resetujeme stav pro nové kolo
    setShowingCards(true);
    setFlipAll(false);
    setClickable(false);
    setFoundCards([]);
    
    // Počet karet, které musí hráč najít v tomto kole
    const cardsToFind = round;
    
    // Vytvoříme balíček karet 1-9
    const deck = Array.from({ length: 9 }, (_, i) => i + 1);
    // Zamícháme balíček
    const shuffledDeck = [...deck].sort(() => Math.random() - 0.5);
    
    // Vybereme karty, které dealer "schová"
    const selectedCards = shuffledDeck.slice(0, cardsToFind);
    setDealerSelectedCards(selectedCards);
    
    // Zamícháme karty znovu pro hráče
    const shuffledForPlayer = [...deck].sort(() => Math.random() - 0.5);
    setCards(shuffledForPlayer);
    
    // Nastavíme zprávu
    setMessage(`Kolo ${round}: Dealer schovává ${cardsToFind} ${getCardWord(cardsToFind)}`);
    
    // Po 3 sekundách otočíme karty lícem dolů a umožníme hráči klikat
    setTimeout(() => {
      setShowingCards(false);
      setMessage(`Kolo ${round}: Najdi ${cardsToFind} ${getCardWord(cardsToFind)}, které dealer schoval`);
      setClickable(true);
    }, 3000);
  };

  // Funkce pro získání správného skloňování slova "karta"
  const getCardWord = (count) => {
    if (count === 1) return "kartu";
    else if (count >= 2 && count <= 4) return "karty";
    else return "karet";
  };

  // Funkce pro kliknutí na kartu
  const handleCardClick = (cardValue) => {
    if (!clickable) return;
    
    // Zkontrolujeme, zda je tato karta jednou z těch, které dealer schoval
    if (dealerSelectedCards.includes(cardValue) && !foundCards.includes(cardValue)) {
      const newFoundCards = [...foundCards, cardValue];
      setFoundCards(newFoundCards);
      
      // Pokud hráč našel všechny karty
      if (newFoundCards.length === round) {
        setPlayerScore(score => score + newFoundCards.length);
        setMessage(`Výborně! Našel jsi všechny ${round} ${getCardWord(round)}!`);
        
        // Příprava na další kolo nebo konec hry
        setTimeout(() => {
          if (round === 5) {
            endGame();
          } else {
            setRound(round + 1);
            setFlipAll(true);
            
            // Po krátké pauze začneme další kolo
            setTimeout(() => {
              startNewRound();
            }, 2000);
          }
        }, 1500);
        
        setClickable(false);
      }
    } else if (foundCards.includes(cardValue)) {
      // Hráč klikl na již nalezenou kartu
      setMessage("Tuhle kartu jsi už našel!");
    } else {
      // Hráč klikl na nesprávnou kartu
      setDealerScore(score => score + 1);
      setMessage("Špatná karta! Dealer získává bod.");
      setFlipAll(true);
      setClickable(false);
      
      // Po krátké pauze ukážeme všechny karty a připravíme další kolo
      setTimeout(() => {
        if (round === 5) {
          endGame();
        } else {
          setRound(round + 1);
          // Po krátké pauze začneme další kolo
          setTimeout(() => {
            startNewRound();
          }, 2000);
        }
      }, 2000);
    }
  };

  // Ukončení hry a vyhodnocení výsledku
  const endGame = () => {
    setGameOver(true);
    setFlipAll(true);
    
    if (playerScore > dealerScore) {
      setMessage(`Gratulujeme! Vyhráváš ${playerScore}-${dealerScore}`);
    } else if (dealerScore > playerScore) {
      setMessage(`Prohrál jsi ${playerScore}-${dealerScore}. Zkus to znovu!`);
    } else {
      setMessage(`Remíza ${playerScore}-${dealerScore}!`);
    }
  };

  // Začít novou hru
  const startNewGame = () => {
    setRound(1);
    setPlayerScore(0);
    setDealerScore(0);
    setGameOver(false);
    setFoundCards([]);
    startNewRound();
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div className="card-finder-container">
        <h1>Card Finder</h1>
        
        <div className="score-board">
          <div className="player-score">
            <h3>Hráč</h3>
            <span>{playerScore}</span>
          </div>
          <div className="vs">VS</div>
          <div className="dealer-score">
            <h3>Dealer</h3>
            <span>{dealerScore}</span>
          </div>
        </div>
        
        <div className="round-info">
          <h2>Kolo {round}/5</h2>
          <p className="message">{message}</p>
        </div>
        
        <div className="cards-container">
          {cards.map((card, index) => (
            <div 
              key={index}
              className={`card ${!showingCards || flipAll ? 'flipped' : ''} 
                ${foundCards.includes(card) ? 'found' : ''}
                ${dealerSelectedCards.includes(card) && flipAll ? 'dealer-selected' : ''}`}
              onClick={() => handleCardClick(card)}
            >
              <div className="card-inner">
                <div className="card-front">
                  <span className="card-value">{card}</span>
                </div>
                <div className="card-back"></div>
              </div>
            </div>
          ))}
        </div>
        
        {gameOver && (
          <button className="new-game-button" onClick={startNewGame}>
            Nová hra
          </button>
        )}
        
        <div className="game-rules">
          <h3>Pravidla hry:</h3>
          <ul>
            <li>Ve hře je 5 kol, v každém kole dealer schovává stále více karet.</li>
            <li>V prvním kole se schovává 1 karta, v pátém 5 karet.</li>
            <li>Nejprve se Ti ukážou všechny karty, zapamatuj si je.</li>
            <li>Poté se karty otočí. Klikni na ty, které si myslíš, že dealer schoval.</li>
            <li>Za každou správně nalezenou kartu získáš 1 bod.</li>
            <li>Pokud klikneš na špatnou kartu, dealer získá 1 bod a kolo končí.</li>
            <li>Vyhrává ten, kdo má po 5. kole více bodů.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardFinder;
