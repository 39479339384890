import React, { useRef, useEffect, useState } from 'react';
import './RouletteWheel.css';
import RouletteOverlay from './RouletteOverlay';

// Správné cesty k obrázkům
import rouletteImage from './images/roulette.png'; 
import ballImage from './images/ball.png';

const RouletteWheel = ({ winningNumber, onAnimationEnd, highlightedNumbers = [] }) => {
  const rouletteRef = useRef(null);
  const ballRef = useRef(null);
  const [animating, setAnimating] = useState(false);
  const [rotationOffset, setRotationOffset] = useState(-4.8); // Základní offset rotace rulety

  useEffect(() => {
    // Reset animace při změně výherního čísla
    if (winningNumber !== null) {
      resetAnimation();
      startAnimation();
    }
  }, [winningNumber]);

  const resetAnimation = () => {
    if (!rouletteRef.current || !ballRef.current) return;
    
    // Okamžitý reset bez animace
    const roulette = rouletteRef.current;
    const ball = ballRef.current;
    
    roulette.style.transition = 'none';
    ball.style.transition = 'none';
    
    // Nastavení na výchozí pozice s offsetem rotace
    roulette.style.transform = `rotate(${rotationOffset}deg)`;
    ball.style.transform = 'rotate(0deg)';
    
    // Vynutit překreslení DOM - oprava ESLint chyb
    void roulette.offsetHeight; // použijeme 'void' pro vyhodnocení výrazu
    void ball.offsetHeight; // použijeme 'void' pro vyhodnocení výrazu
  };

  const startAnimation = () => {
    if (!rouletteRef.current || !ballRef.current) return;
    if (animating) return;
    
    setAnimating(true);
    const roulette = rouletteRef.current;
    const ball = ballRef.current;
    
    // Zajistíme, že reset byl dokončen
    setTimeout(() => {
      const totalDegrees = 360;
      const numberOfSlots = 37;
      const degreesPerSlot = totalDegrees / numberOfSlots;
      
      // Mapa čísel rulety - pořadí na ruletě
      // 0 je na 0 stupních, 32 začíná na degreesPerSlot stupních atd.
      const rouletteNumbersMap = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26];
      
      // Najdi pozici výherního čísla na ruletě
      const winningIndex = rouletteNumbersMap.indexOf(winningNumber);
      const winningSlotAngle = winningIndex * degreesPerSlot;
      
      // Animace ve dvou krocích: nejprve rychlé otáčení, pak zpomalení
      
      // 1. krok: Rychlé otáčení (2 sekundy)
      const spinTime1 = 2000;
      
      // Ruleta se otáčí ve směru hodinových ručiček (záporný úhel)
      roulette.style.transition = `transform ${spinTime1 / 1000}s linear`;
      roulette.style.transform = `rotate(${-720 + rotationOffset}deg)`;  // 2 otáčky + offset
      
      // Míček se otáčí proti směru hodinových ručiček (kladný úhel)
      ball.style.transition = `transform ${spinTime1 / 1000}s linear`;
      ball.style.transform = `rotate(${1080}deg)`;  // 3 otáčky
      
      // 2. krok: Zpomalení a zastavení na výherním čísle
      setTimeout(() => {
        // Výpočet konečné pozice (ruleta se zastaví náhodně, míček přesně na výherním čísle)
        const randomStopOffset = Math.random() * 270;  // Náhodný úhel pro ruletu (0-270 stupňů)
        const finalRouletteAngle = -720 + rotationOffset - randomStopOffset;  // Pokračujeme v záporném otáčení
        const finalBallAngle = 1080 + winningSlotAngle - randomStopOffset;  // Míček na výherní pozici
        
        // Nastavení postupného zpomalení rulety a míčku
        roulette.style.transition = `transform 3s cubic-bezier(0.2, 0.8, 0.3, 1)`;
        ball.style.transition = `transform 3.2s cubic-bezier(0.5, 0.1, 0.15, 1)`;
        
        roulette.style.transform = `rotate(${finalRouletteAngle}deg)`;
        ball.style.transform = `rotate(${finalBallAngle}deg)`;
        
        // Oznámení dokončení animace
        setTimeout(() => {
          if (onAnimationEnd) {
            onAnimationEnd();
          }
          setAnimating(false);
        }, 3200);
      }, spinTime1);
    }, 50);
  };

  return (
    <div className="roulette-wheel-container">
      <div className="roulette-frame">
        <img 
          src={rouletteImage} 
          alt="Roulette" 
          className="roulette-wheel" 
          ref={rouletteRef} 
          onError={(e) => console.error("Chyba při načítání obrázku rulety", e)} 
        />
        {/* Přidání overlay komponenty pro zvýraznění segmentů */}
        <RouletteOverlay highlightedNumbers={highlightedNumbers} />
        <div className="roulette-ball-container">
          <img 
            src={ballImage} 
            alt="Ball" 
            className="roulette-ball" 
            ref={ballRef} 
            onError={(e) => console.error("Chyba při načítání obrázku míčku", e)} 
          />
        </div>
      </div>
    </div>
  );
};

export default RouletteWheel;
