// src/components/RegisterPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css';

function RegisterPage() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    console.log('[DEBUG] Kliknutí na tlačítko Registrovat - spouštím handleRegister...');
    try {
      const response = await axios.post('https://pvprng.com/auth/register', {
        username,
        email,
        password,
      });

      if (response.status === 201) {
        setErrorMessage('Registrace proběhla úspěšně, nyní se můžete přihlásit.');
        // Po registraci přesměrujeme uživatele na přihlašovací stránku
        setTimeout(() => {
          navigate('/game');
        }, 2000);
      }
    } catch (error) {
      setErrorMessage('Registrace se nezdařila. Uživatel s tímto e-mailem již existuje nebo došlo k chybě.');
    }
  };

  const goToLogin = () => {
    navigate('/');
  };

  return (
    <div className="register-container">
      <div className="form-box">
        <h2>Registrace</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <input
          type="text"
          placeholder="Uživatelské jméno"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Heslo"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleRegister}>Registrovat se</button>
        <p>
          Již máte účet?{' '}
          <button className="link-button" onClick={goToLogin}>
            Přihlásit se
          </button>
        </p>
      </div>
    </div>
  );
}

export default RegisterPage;
