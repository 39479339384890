import React, { useState, useEffect } from 'react';
import './Roulette.css';
import Navbar from './Navbar';
import RouletteWheel from './RouletteWheel';
import soundManager from '../utils/SoundManager';

const Roulette = ({ credits, username, handleLogout }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [result, setResult] = useState(null);
  const [color, setColor] = useState(null);
  const [currentBet, setCurrentBet] = useState(0);
  const [bets, setBets] = useState(Array(37).fill(0)); // 37 políček na ruletě
  const [outsideBets, setOutsideBets] = useState({}); // Pro vnější sázky
  const [winDetails, setWinDetails] = useState([]); // Detaily výhry
  const [totalWin, setTotalWin] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [highlightedNumbers, setHighlightedNumbers] = useState([]);

  // Resetuje chybovou hlášku po 5 sekundách
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  // Přidáme useEffect pro globální sledování submit událostí na stránce
  useEffect(() => {
    const handleSubmit = (e) => {
      console.log('DEBUG: Zachycen submit event na stránce', e);
      e.preventDefault();
    };

    const handleClick = (e) => {
      if (e.target.tagName === 'BUTTON' || e.target.role === 'button') {
        console.log('DEBUG: Klik na tlačítko', e.target);
      }
    };

    document.addEventListener('submit', handleSubmit, true);
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('submit', handleSubmit, true);
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  // Load game sounds
  useEffect(() => {
    // Pouze přehrajeme zvuk prostředí, pokud existuje
    const bgAmb = soundManager.sounds['rouletteAmbience'];
    if (bgAmb) {
      bgAmb.loop = true;
      bgAmb.volume = 0.3; // Lower volume for background
      soundManager.play('rouletteAmbience');
    }
    
    return () => {
      // Stop ambience when leaving the game
      if (bgAmb) {
        bgAmb.pause();
        bgAmb.currentTime = 0;
      }
    };
  }, []);

  // Odstranění zvukových efektů
  useEffect(() => {
    // Žádné zvukové efekty
  }, []);

  // Upravená funkce pro spuštění hry bez kontroly kreditů
  const handleStartGame = (event) => {
    console.log('DEBUG: handleStartGame zavolán', { event, eventType: event?.type });
    
    // Explicitní zabránění výchozímu chování
    if (event) {
      console.log('DEBUG: Prevence výchozího chování eventu');
      event.preventDefault();
      event.stopPropagation();
      
      // Kontrola, zda je event součástí formuláře
      if (event.target.form) {
        console.log('DEBUG: Tlačítko je uvnitř formuláře:', event.target.form);
      }
    }

    console.log("Tlačítko roztočit ruletu kliknuto");
    
    // Kontrola, zda vůbec byla učiněna nějaká sázka
    const totalBet = calculateTotalBet();
    console.log('DEBUG: Celková sázka:', totalBet);
    
    if (totalBet <= 0) {
      console.log('DEBUG: Žádná sázka nebyla učiněna');
      setErrorMessage('Nejdříve prosím vsaďte.');
      soundManager.play('buttonClick'); // Error feedback sound
      return;
    }
    
    console.log('DEBUG: Nastavení stavu isPlaying na true');
    setIsPlaying(true);
    setResult(null);
    setColor(null);
    setWinDetails([]);
    setTotalWin(0);
    
    console.log('DEBUG: Začíná fetch požadavek');
    
    // Standardní fetch - již neodečítáme kredity předem, necháme to na backendu
    fetch('/gameResults/roulette-spin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        clientSeed: 'client-' + Date.now(),
        bets,
        outsideBets,
        credits // Pošleme aktuální stav kreditů backendu, ale neupravujeme je na frontendu
      })
    })
    .then(response => {
      console.log('DEBUG: Fetch response obdržena', { status: response.status, ok: response.ok });
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('DEBUG: Data z API:', data);
      
      // Zpracování výsledku
      setResult(data.result);
      setColor(data.color);
      setTotalWin(data.totalWin);
      setWinDetails(data.detailedResults || []);
      
      // Aktualizace kreditů podle backendu - již není potřeba
      console.log(`Aktualizace kreditů: ${data.updatedCredits}`);
      
      // Spuštění animace - FIX THE MISSING FUNCTION CALL HERE
      startRouletteAnimation(data.result);
    })
    .catch(error => {
      console.error('Chyba při komunikaci s API:', error);
      setIsPlaying(false);
      setErrorMessage('Nastala chyba při zpracování požadavku.');
    });
  };

  const handleOutsideBetClick = (type, event) => {
    if (event) event.preventDefault();
    if (isPlaying) return; // Zamezí sázení během hry
    if (currentBet <= 0) {
      setErrorMessage('Nejdříve prosím vyberte hodnotu žetonu.');
      return;
    }
    
    const newOutsideBets = { ...outsideBets };
    newOutsideBets[type] = (newOutsideBets[type] || 0) + currentBet;
    setOutsideBets(newOutsideBets);
  };

  const handleResetBets = (event) => {
    if (event) event.preventDefault();
    setBets(Array(37).fill(0));
    setOutsideBets({});
  };

  // Zjednodušený animační kód na konzistentní způsob
  const startRouletteAnimation = (winningNumber) => {
    console.log('DEBUG: startRouletteAnimation zavolán', { winningNumber });
    // Animace je spuštěna - tím blokujeme tlačítka
    setIsPlaying(true);
    
    // Po dokončení animace provedeme akce
    setTimeout(() => {
      console.log('DEBUG: Animace dokončena');
      setIsPlaying(false);
      
      // Reset sázek po hře
      console.log('DEBUG: Reset sázek po hře');
      setBets(Array(37).fill(0));
      setOutsideBets({});
    }, 5200); // Doba trvání animace (2s + 3.2s) z RouletteWheel.js
  };

  // Definice skupin čísel pro each outside bet
  const numberGroups = {
    red: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
    black: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
    odd: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35],
    even: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
    low: Array.from({ length: 18 }, (_, i) => i + 1), // 1-18
    high: Array.from({ length: 18 }, (_, i) => i + 19), // 19-36
    dozen1: Array.from({ length: 12 }, (_, i) => i + 1), // 1-12
    dozen2: Array.from({ length: 12 }, (_, i) => i + 13), // 13-24
    dozen3: Array.from({ length: 12 }, (_, i) => i + 25), // 25-36
    column1: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36], // Opraveno - první sloupec
    column2: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35], // Prostřední sloupec
    column3: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]  // Třetí sloupec
  };

  // Funkce pro hover over outside bet
  const handleOutsideBetHover = (type) => {
    setHighlightedNumbers(numberGroups[type] || []);
  };

  // Funkce pro odjetí myši z outside bet
  const handleOutsideBetLeave = () => {
    setHighlightedNumbers([]);
  };

  // Nové funkce pro hover nad jednotlivými čísly
  const handleNumberHover = (number) => {
    setHighlightedNumbers([number]);
  };

  const handleNumberLeave = () => {
    setHighlightedNumbers([]);
  };

  // Přidáme chybějící funkce
  const handleBetClick = (amount, event) => {
    if (event) event.preventDefault();
    if (!isPlaying) {
      setCurrentBet(amount);
      // Zvuk pokládání žetonu, pokud existuje
      if (soundManager.sounds['buttonClick']) {
        soundManager.play('buttonClick'); 
      }
    }
  };

  const handleNumberClick = (number, event) => {
    if (event) event.preventDefault();
    if (isPlaying) return; // Zamezí sázení během hry
    if (currentBet <= 0) {
      setErrorMessage('Nejdříve prosím vyberte hodnotu žetonu.');
      return;
    }
    
    const newBets = [...bets];
    newBets[number] = (newBets[number] || 0) + currentBet;
    setBets(newBets);
    soundManager.play('chipPlace'); // Zvuk pokládání žetonu
  };

  // Funkce pro výpočet celkové sázky
  const calculateTotalBet = () => {
    const numberBetsTotal = bets.reduce((sum, bet) => sum + (bet || 0), 0);
    const outsideBetsTotal = Object.values(outsideBets).reduce((sum, bet) => sum + (bet || 0), 0);
    return numberBetsTotal + outsideBetsTotal;
  };

  // Upravený render pro tlačítka - ujistíme se, že tlačítko "Roztočit ruletu" nemá výchozí formulářové chování
  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div className="roulette-container">
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
        
        <div className="roulette-header"> 
          <div className="result-display">
            <div>Výsledek zadek: {result !== null ? result : '?'}</div>
            <div>Barva: {color !== null ? color : '?'}</div>
            {totalWin > 0 && (
              <div className="win-display">Výhra: {totalWin} kreditů!</div>
            )}
          </div>
          
          {/* Předáváme highlightedNumbers do RouletteWheel */}
          <RouletteWheel 
            winningNumber={result} 
            onAnimationEnd={() => console.log('Animace rulety dokončena')} 
            highlightedNumbers={highlightedNumbers.map(num => parseInt(num))}
          />
          
          <div className="roulette-controls">
            {/* Použijeme explicitně button typu "button" - ne "submit" */}
            <button
              type="button"
              onClick={(e) => handleStartGame(e)}
              disabled={isPlaying}
              className={`start-button ${isPlaying ? 'disabled' : ''}`}
              style={{ cursor: isPlaying ? 'not-allowed' : 'pointer' }}
            >
              {isPlaying ? 'Točí se...' : 'Roztočit ruletu'}
            </button>
            <button
              type="button"
              onClick={(e) => !isPlaying && handleResetBets(e)}
              disabled={isPlaying}
              className={`reset-button ${isPlaying ? 'disabled' : ''}`}
              style={{ cursor: isPlaying ? 'not-allowed' : 'pointer' }}
            >
              Reset sázek
            </button>
          </div>
        </div>
        
        {/* Přesuneme betting-amounts před betting-controls */}
        <div className="betting-amounts">
          {[1, 5, 10, 50, 100, 500, 10000].map(amount => (
            <button
              key={amount}
              type="button" // Explicitně uvedeme typ "button"
              className={`bet-amount-button ${currentBet === amount ? 'active' : ''}`}
              onClick={(e) => handleBetClick(amount, e)}
              disabled={isPlaying}
            >
              {amount}
            </button>
          ))}
        </div>
        
        <div className="betting-controls">
          <div className="betting-column-zero">
            <button 
              className={`bet-button zero ${highlightedNumbers.includes(0) ? 'highlighted' : ''}`}
              onClick={(e) => handleNumberClick(0, e)}
              onMouseEnter={() => handleNumberHover(0)}
              onMouseLeave={handleNumberLeave} 
              disabled={isPlaying}
            >
              <div className="bet-button-number">0</div>
              {bets[0] > 0 && <div className="bet-amount">{bets[0]}</div>}
            </button>
          </div>
          <div className="Sloupec_1-12">
            <div className="betting-column">
              <button 
                className={`bet-button three ${highlightedNumbers.includes(3) ? 'highlighted' : ''}`} 
                onClick={(e) => handleNumberClick(3, e)} 
                onMouseEnter={() => handleNumberHover(3)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">3</div>
                {bets[3] > 0 && <div className="bet-amount">{bets[3]}</div>}
              </button>
              <button 
                className={`bet-button six ${highlightedNumbers.includes(6) ? 'highlighted' : ''}`} 
                onClick={(e) => handleNumberClick(6, e)} 
                onMouseEnter={() => handleNumberHover(6)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">6</div>
                {bets[6] > 0 && <div className="bet-amount">{bets[6]}</div>}
              </button>
              <button 
                className={`bet-button nine ${highlightedNumbers.includes(9) ? 'highlighted' : ''}`} 
                onClick={(e) => handleNumberClick(9, e)} 
                onMouseEnter={() => handleNumberHover(9)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">9</div>
                {bets[9] > 0 && <div className="bet-amount">{bets[9]}</div>}
              </button>
              <button 
                className={`bet-button twelve ${highlightedNumbers.includes(12) ? 'highlighted' : ''}`} 
                onClick={(e) => handleNumberClick(12, e)} 
                onMouseEnter={() => handleNumberHover(12)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">12</div>
                {bets[12] > 0 && <div className="bet-amount">{bets[12]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button two ${highlightedNumbers.includes(2) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(2, e)} 
                onMouseEnter={() => handleNumberHover(2)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">2</div>
                {bets[2] > 0 && <div className="bet-amount">{bets[2]}</div>}
              </button>
              <button 
                className={`bet-button five ${highlightedNumbers.includes(5) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(5, e)} 
                onMouseEnter={() => handleNumberHover(5)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">5</div>
                {bets[5] > 0 && <div className="bet-amount">{bets[5]}</div>}
              </button>
              <button 
                className={`bet-button eight ${highlightedNumbers.includes(8) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(8, e)} 
                onMouseEnter={() => handleNumberHover(8)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">8</div>
                {bets[8] > 0 && <div className="bet-amount">{bets[8]}</div>}
              </button>
              <button 
                className={`bet-button eleven ${highlightedNumbers.includes(11) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(11, e)} 
                onMouseEnter={() => handleNumberHover(11)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">11</div>
                {bets[11] > 0 && <div className="bet-amount">{bets[11]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button one ${highlightedNumbers.includes(1) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(1, e)} 
                onMouseEnter={() => handleNumberHover(1)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">1</div>
                {bets[1] > 0 && <div className="bet-amount">{bets[1]}</div>}
              </button>
              <button 
                className={`bet-button four ${highlightedNumbers.includes(4) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(4, e)} 
                onMouseEnter={() => handleNumberHover(4)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">4</div>
                {bets[4] > 0 && <div className="bet-amount">{bets[4]}</div>}
              </button>
              <button 
                className={`bet-button seven ${highlightedNumbers.includes(7) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(7, e)} 
                onMouseEnter={() => handleNumberHover(7)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">7</div>
                {bets[7] > 0 && <div className="bet-amount">{bets[7]}</div>}
              </button>
              <button 
                className={`bet-button ten ${highlightedNumbers.includes(10) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(10, e)} 
                onMouseEnter={() => handleNumberHover(10)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">10</div>
                {bets[10] > 0 && <div className="bet-amount">{bets[10]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className="outside-bet-button-dozen1" 
                onClick={(e) => handleOutsideBetClick('dozen1', e)} 
                onMouseEnter={() => handleOutsideBetHover('dozen1')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">1-12</div>
                {outsideBets.dozen1 > 0 && <div className="bet-amount">{outsideBets.dozen1}</div>}
              </button>
            </div>
            <div className="betting-column-even-low">
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('low', e)} 
                onMouseEnter={() => handleOutsideBetHover('low')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">1-18</div>
                {outsideBets.low > 0 && <div className="bet-amount">{outsideBets.low}</div>}
              </button>
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('even', e)} 
                onMouseEnter={() => handleOutsideBetHover('even')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">Sudá</div>
                {outsideBets.even > 0 && <div className="bet-amount">{outsideBets.even}</div>}
              </button>
            </div>
          </div>
          <div className="Sloupec_13-24">
            <div className="betting-column">
              <button 
                className={`bet-button fifteen ${highlightedNumbers.includes(15) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(15, e)} 
                onMouseEnter={() => handleNumberHover(15)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">15</div>
                {bets[15] > 0 && <div className="bet-amount">{bets[15]}</div>}
              </button>
              <button 
                className={`bet-button eighteen ${highlightedNumbers.includes(18) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(18, e)} 
                onMouseEnter={() => handleNumberHover(18)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">18</div>
                {bets[18] > 0 && <div className="bet-amount">{bets[18]}</div>}
              </button>
              <button 
                className={`bet-button twenty-one ${highlightedNumbers.includes(21) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(21, e)} 
                onMouseEnter={() => handleNumberHover(21)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">21</div>
                {bets[21] > 0 && <div className="bet-amount">{bets[21]}</div>}
              </button>
              <button 
                className={`bet-button twenty-four ${highlightedNumbers.includes(24) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(24, e)} 
                onMouseEnter={() => handleNumberHover(24)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">24</div>
                {bets[24] > 0 && <div className="bet-amount">{bets[24]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button fourteen ${highlightedNumbers.includes(14) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(14, e)} 
                onMouseEnter={() => handleNumberHover(14)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">14</div>
                {bets[14] > 0 && <div className="bet-amount">{bets[14]}</div>}
              </button>
              <button 
                className={`bet-button seventeen ${highlightedNumbers.includes(17) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(17, e)} 
                onMouseEnter={() => handleNumberHover(17)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">17</div>
                {bets[17] > 0 && <div className="bet-amount">{bets[17]}</div>}
              </button>
              <button 
                className={`bet-button twenty ${highlightedNumbers.includes(20) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(20, e)} 
                onMouseEnter={() => handleNumberHover(20)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">20</div>
                {bets[20] > 0 && <div className="bet-amount">{bets[20]}</div>}
              </button>
              <button 
                className={`bet-button twenty-three ${highlightedNumbers.includes(23) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(23, e)} 
                onMouseEnter={() => handleNumberHover(23)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">23</div>
                {bets[23] > 0 && <div className="bet-amount">{bets[23]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button thirteen ${highlightedNumbers.includes(13) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(13, e)} 
                onMouseEnter={() => handleNumberHover(13)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">13</div>
                {bets[13] > 0 && <div className="bet-amount">{bets[13]}</div>}
              </button>
              <button 
                className={`bet-button sixteen ${highlightedNumbers.includes(16) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(16, e)} 
                onMouseEnter={() => handleNumberHover(16)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">16</div>
                {bets[16] > 0 && <div className="bet-amount">{bets[16]}</div>}
              </button>
              <button 
                className={`bet-button nineteen ${highlightedNumbers.includes(19) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(19, e)} 
                onMouseEnter={() => handleNumberHover(19)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">19</div>
                {bets[19] > 0 && <div className="bet-amount">{bets[19]}</div>}
              </button>
              <button 
                className={`bet-button twenty-two ${highlightedNumbers.includes(22) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(22, e)} 
                onMouseEnter={() => handleNumberHover(22)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">22</div>
                {bets[22] > 0 && <div className="bet-amount">{bets[22]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className="outside-bet-button-dozen2" 
                onClick={(e) => handleOutsideBetClick('dozen2', e)} 
                onMouseEnter={() => handleOutsideBetHover('dozen2')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">13-24</div>
                {outsideBets.dozen2 > 0 && <div className="bet-amount">{outsideBets.dozen2}</div>}
              </button>
            </div>
            <div className="betting-column-red-black">
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('red', e)} 
                onMouseEnter={() => handleOutsideBetHover('red')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">Červená</div>
                {outsideBets.red > 0 && <div className="bet-amount">{outsideBets.red}</div>}
              </button>
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('black', e)} 
                onMouseEnter={() => handleOutsideBetHover('black')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">Černá</div>
                {outsideBets.black > 0 && <div className="bet-amount">{outsideBets.black}</div>}
              </button>
            </div>
          </div>
          <div className="Sloupec_25-36">
            <div className="betting-column">
              <button 
                className={`bet-button twenty-seven ${highlightedNumbers.includes(27) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(27, e)} 
                onMouseEnter={() => handleNumberHover(27)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">27</div>
                {bets[27] > 0 && <div className="bet-amount">{bets[27]}</div>}
              </button>
              <button 
                className={`bet-button thirty ${highlightedNumbers.includes(30) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(30, e)} 
                onMouseEnter={() => handleNumberHover(30)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">30</div>
                {bets[30] > 0 && <div className="bet-amount">{bets[30]}</div>}
              </button>
              <button 
                className={`bet-button thirty-three ${highlightedNumbers.includes(33) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(33, e)} 
                onMouseEnter={() => handleNumberHover(33)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">33</div>
                {bets[33] > 0 && <div className="bet-amount">{bets[33]}</div>}
              </button>
              <button 
                className={`bet-button thirty-six ${highlightedNumbers.includes(36) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(36, e)} 
                onMouseEnter={() => handleNumberHover(36)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">36</div>
                {bets[36] > 0 && <div className="bet-amount">{bets[36]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button twenty-six ${highlightedNumbers.includes(26) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(26, e)} 
                onMouseEnter={() => handleNumberHover(26)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">26</div>
                {bets[26] > 0 && <div className="bet-amount">{bets[26]}</div>}
              </button>
              <button 
                className={`bet-button twenty-nine ${highlightedNumbers.includes(29) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(29, e)} 
                onMouseEnter={() => handleNumberHover(29)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">29</div>
                {bets[29] > 0 && <div className="bet-amount">{bets[29]}</div>}
              </button>
              <button 
                className={`bet-button thirty-two ${highlightedNumbers.includes(32) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(32, e)} 
                onMouseEnter={() => handleNumberHover(32)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">32</div>
                {bets[32] > 0 && <div className="bet-amount">{bets[32]}</div>}
              </button>
              <button 
                className={`bet-button thirty-five ${highlightedNumbers.includes(35) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(35, e)} 
                onMouseEnter={() => handleNumberHover(35)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">35</div>
                {bets[35] > 0 && <div className="bet-amount">{bets[35]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className={`bet-button twenty-five ${highlightedNumbers.includes(25) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(25, e)} 
                onMouseEnter={() => handleNumberHover(25)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">25</div>
                {bets[25] > 0 && <div className="bet-amount">{bets[25]}</div>}
              </button>
              <button 
                className={`bet-button twenty-eight ${highlightedNumbers.includes(28) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(28, e)} 
                onMouseEnter={() => handleNumberHover(28)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">28</div>
                {bets[28] > 0 && <div className="bet-amount">{bets[28]}</div>}
              </button>
              <button 
                className={`bet-button thirty-one ${highlightedNumbers.includes(31) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(31, e)} 
                onMouseEnter={() => handleNumberHover(31)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">31</div>
                {bets[31] > 0 && <div className="bet-amount">{bets[31]}</div>}
              </button>
              <button 
                className={`bet-button thirty-four ${highlightedNumbers.includes(34) ? 'highlighted' : ''}`}
                onClick={(e) => handleNumberClick(34, e)} 
                onMouseEnter={() => handleNumberHover(34)}
                onMouseLeave={handleNumberLeave}
                disabled={isPlaying}
              >
                <div className="bet-button-number">34</div>
                {bets[34] > 0 && <div className="bet-amount">{bets[34]}</div>}
              </button>
            </div>
            <div className="betting-column">
              <button 
                className="outside-bet-button-dozen3" 
                onClick={(e) => handleOutsideBetClick('dozen3', e)} 
                onMouseEnter={() => handleOutsideBetHover('dozen3')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">25-36</div>
                {outsideBets.dozen3 > 0 && <div className="bet-amount">{outsideBets.dozen3}</div>}
              </button>
            </div>
            <div className="betting-column-odd-high">
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('odd', e)} 
                onMouseEnter={() => handleOutsideBetHover('odd')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">Lichá</div>
                {outsideBets.odd > 0 && <div className="bet-amount">{outsideBets.odd}</div>}
              </button>
              <button 
                className="outside-bet-button" 
                onClick={(e) => handleOutsideBetClick('high', e)} 
                onMouseEnter={() => handleOutsideBetHover('high')} 
                onMouseLeave={handleOutsideBetLeave} 
                disabled={isPlaying}
              >
                <div className="bet-button-number">19-36</div>
                {outsideBets.high > 0 && <div className="bet-amount">{outsideBets.high}</div>}
              </button>
            </div>
          </div>
          <div className="betting-column-3lines">
            <button 
              className="outside-bet-button" 
              onClick={(e) => handleOutsideBetClick('column1', e)} 
              onMouseEnter={() => handleOutsideBetHover('column1')} 
              onMouseLeave={handleOutsideBetLeave} 
              disabled={isPlaying}
            >
              <div className="bet-button-number">Sloupec 1</div>
              {outsideBets.column1 > 0 && <div className="bet-amount">{outsideBets.column1}</div>}
            </button>
            <button 
              className="outside-bet-button" 
              onClick={(e) => handleOutsideBetClick('column2', e)} 
              onMouseEnter={() => handleOutsideBetHover('column2')} 
              onMouseLeave={handleOutsideBetLeave} 
              disabled={isPlaying}
            >
              <div className="bet-button-number">Sloupec 2</div>
              {outsideBets.column2 > 0 && <div className="bet-amount">{outsideBets.column2}</div>}
            </button>
            <button 
              className="outside-bet-button" 
              onClick={(e) => handleOutsideBetClick('column3', e)} 
              onMouseEnter={() => handleOutsideBetHover('column3')} 
              onMouseLeave={handleOutsideBetLeave} 
              disabled={isPlaying}
            >
              <div className="bet-button-number">Sloupec 3</div>
              {outsideBets.column3 > 0 && <div className="bet-amount">{outsideBets.column3}</div>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roulette;