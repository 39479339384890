import React, { useState, useEffect } from 'react';
import './DiamondMiner.css';
import Navbar from './Navbar';
import diamondImage from './images/diamond.png'; // Budeme potřebovat tento obrázek
import mineImage from './images/mine.png';       // Budeme potřebovat tento obrázek

function DiamondMiner({ credits, username, handleLogout }) {
  // Základní herní stavy
  const [gameActive, setGameActive] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [betAmount, setBetAmount] = useState(10);
  const [currentWin, setCurrentWin] = useState(0);
  const [gridSize, setGridSize] = useState(4); // Výchozí velikost 4x4
  const [gameField, setGameField] = useState([]);
  const [revealedFields, setRevealedFields] = useState([]);
  const [lastReveal, setLastReveal] = useState(null);
  const [message, setMessage] = useState("");
  const [gameHistory, setGameHistory] = useState([]);
  
  // Efekty a animace
  const [shake, setShake] = useState(false);
  const [diamondAnimation, setDiamondAnimation] = useState(false);
  
  // Generování herního pole
  useEffect(() => {
    if (gameActive) {
      generateNewGame();
    }
  }, [gameActive, gridSize]);
  
  // Funkce pro generování nové hry
  const generateNewGame = () => {
    const totalFields = gridSize * gridSize;
    const mineCount = getMineCount(gridSize);
    
    // Vytvoření prázdného pole
    let newFields = Array(totalFields).fill({ type: 'diamond', multiplier: 0 });
    
    // Rozmístění min
    let minePositions = [];
    while (minePositions.length < mineCount) {
      const randomPos = Math.floor(Math.random() * totalFields);
      if (!minePositions.includes(randomPos)) {
        minePositions.push(randomPos);
      }
    }
    
    // Umístění min
    newFields = newFields.map((field, index) => {
      if (minePositions.includes(index)) {
        return { type: 'mine', multiplier: 0 };
      }
      return field;
    });
    
    // Přiřazení násobitelů diamantům
    newFields = newFields.map(field => {
      if (field.type === 'diamond') {
        return { ...field, multiplier: generateMultiplier() };
      }
      return field;
    });
    
    setGameField(newFields);
    setRevealedFields([]);
    setCurrentWin(betAmount);
    setLastReveal(null);
    setMessage("Začněte odkrývat pole! Dávejte pozor na miny.");
    setGameOver(false);
  };
  
  // Funkce pro výpočet počtu min podle velikosti mřížky
  const getMineCount = (size) => {
    switch (size) {
      case 3: return 1 + Math.floor(Math.random() * 2); // 1-2 miny
      case 4: return 3 + Math.floor(Math.random() * 2); // 3-4 miny
      case 5: return 5 + Math.floor(Math.random() * 3); // 5-7 min
      case 6: return 8 + Math.floor(Math.random() * 3); // 8-10 min
      default: return 3;
    }
  };
  
  // Funkce pro generování násobitele podle pravděpodobnosti
  const generateMultiplier = () => {
    const rand = Math.random() * 100;
    
    // Rozdělení pravděpodobností pro jednotlivé typy násobitelů
    if (rand < 50) {
      // Běžný (50%): 1.1x - 1.5x
      return 1.1 + Math.random() * 0.4;
    } else if (rand < 80) {
      // Neobvyklý (30%): 1.5x - 3x
      return 1.5 + Math.random() * 1.5;
    } else if (rand < 95) {
      // Vzácný (15%): 3x - 5x
      return 3 + Math.random() * 2;
    } else if (rand < 99) {
      // Epický (4%): 5x - 10x
      return 5 + Math.random() * 5;
    } else {
      // Legendární (1%): 10x - 50x
      return 10 + Math.random() * 40;
    }
  };
  
  // Funkce pro odkrytí pole
  const revealField = (index) => {
    if (!gameActive || gameOver || revealedFields.includes(index)) {
      return;
    }
    
    const field = gameField[index];
    const newRevealedFields = [...revealedFields, index];
    setRevealedFields(newRevealedFields);
    setLastReveal({ index, field });
    
    if (field.type === 'mine') {
      // Hráč trefil minu - konec hry
      setGameOver(true);
      setGameActive(false);
      setMessage(`Bohužel, narazili jste na minu! Ztratili jste ${betAmount} kreditů.`);
      setShake(true);
      
      // Přidat do historie
      setGameHistory(prev => [
        { 
          type: 'loss', 
          betAmount, 
          gridSize,
          revealedCount: newRevealedFields.length,
          timestamp: new Date().toISOString() 
        },
        ...prev.slice(0, 9)
      ]);
      
      // Efekt zatřesení
      setTimeout(() => setShake(false), 800);
    } else {
      // Hráč našel diamant
      const multiplier = field.multiplier;
      const newWin = Math.floor(currentWin * multiplier);
      setCurrentWin(newWin);
      setDiamondAnimation(true);
      
      // Vypnout animaci po 1 sekundě
      setTimeout(() => setDiamondAnimation(false), 1000);
      
      // Zkontrolujeme, zda byly všechny diamanty nalezeny
      const allDiamondsRevealed = gameField.every((field, idx) => {
        return field.type === 'mine' || newRevealedFields.includes(idx);
      });
      
      if (allDiamondsRevealed) {
        cashOut();
        setMessage("Gratulujeme! Našli jste všechny diamanty!");
      } else {
        setMessage(`Našli jste diamant s násobitelem ${multiplier.toFixed(2)}x! Aktuální výhra: ${newWin}`);
      }
    }
  };
  
  // Funkce pro výběr výhry
  const cashOut = () => {
    if (!gameActive || gameOver) return;
    
    setGameActive(false);
    setGameOver(true);
    
    setMessage(`Vybráno! Získali jste ${currentWin} kreditů.`);
    
    // Přidat do historie
    setGameHistory(prev => [
      { 
        type: 'win', 
        betAmount, 
        winAmount: currentWin,
        gridSize, 
        multiplier: (currentWin / betAmount).toFixed(2),
        revealedCount: revealedFields.length,
        timestamp: new Date().toISOString() 
      },
      ...prev.slice(0, 9)
    ]);
  };
  
  // Funkce pro spuštění nové hry
  const startNewGame = () => {
    if (credits < betAmount) {
      setMessage("Nemáte dostatek kreditů pro tuto sázku.");
      return;
    }
    
    setGameActive(true);
    setGameOver(false);
  };
  
  // Funkce pro formátování času
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
  // Změna velikosti mřížky
  const changeGridSize = (size) => {
    if (!gameActive) {
      setGridSize(size);
    }
  };
  
  // Určení třídy pro pole podle jeho stavu
  const getFieldClass = (index, type, multiplier) => {
    let baseClass = "game-field";
    
    if (revealedFields.includes(index)) {
      baseClass += " revealed";
      baseClass += type === 'mine' ? " mine" : " diamond";
      
      // Přidání třídy podle hodnoty násobitele
      if (type === 'diamond') {
        if (multiplier <= 1.5) baseClass += " common";
        else if (multiplier <= 3) baseClass += " uncommon";
        else if (multiplier <= 5) baseClass += " rare";
        else if (multiplier <= 10) baseClass += " epic";
        else baseClass += " legendary";
      }
    }
    
    if (lastReveal && lastReveal.index === index) {
      baseClass += " last-revealed";
    }
    
    return baseClass;
  };
  
  // Sestavení třídy pro container
  const getContainerClass = () => {
    let containerClass = "diamond-miner-game";
    
    if (shake) containerClass += " shake";
    if (diamondAnimation) containerClass += " diamond-found";
    
    return containerClass;
  };
  
  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="diamond-miner-container">
        <h1>Diamond Miner</h1>
        
        {/* Herní plocha */}
        <div className={getContainerClass()}>
          {gameActive ? (
            <div className={`grid-container grid-${gridSize}`}>
              {gameField.map((field, index) => (
                <button 
                  key={index}
                  className={getFieldClass(index, field.type, field.multiplier)}
                  onClick={() => revealField(index)}
                  disabled={gameOver || revealedFields.includes(index)}
                >
                  {revealedFields.includes(index) ? (
                    field.type === 'diamond' ? (
                      <>
                        <img src={diamondImage} alt="Diamond" className="field-icon" />
                        <span className="multiplier">{field.multiplier.toFixed(2)}x</span>
                      </>
                    ) : (
                      <img src={mineImage} alt="Mine" className="field-icon" />
                    )
                  ) : (
                    <span className="hidden">?</span>
                  )}
                </button>
              ))}
            </div>
          ) : (
            <div className="setup-container">
              <div className="grid-selector">
                <h3>Vyberte velikost pole:</h3>
                <div className="grid-buttons">
                  <button 
                    className={gridSize === 3 ? "active" : ""} 
                    onClick={() => changeGridSize(3)}
                  >
                    3x3
                  </button>
                  <button 
                    className={gridSize === 4 ? "active" : ""} 
                    onClick={() => changeGridSize(4)}
                  >
                    4x4
                  </button>
                  <button 
                    className={gridSize === 5 ? "active" : ""} 
                    onClick={() => changeGridSize(5)}
                  >
                    5x5
                  </button>
                  <button 
                    className={gridSize === 6 ? "active" : ""} 
                    onClick={() => changeGridSize(6)}
                  >
                    6x6
                  </button>
                </div>
              </div>
              
              <div className="bet-selector">
                <h3>Vyberte sázku:</h3>
                <div className="bet-buttons">
                  <button 
                    className={betAmount === 5 ? "active" : ""} 
                    onClick={() => setBetAmount(5)}
                  >
                    5
                  </button>
                  <button 
                    className={betAmount === 10 ? "active" : ""} 
                    onClick={() => setBetAmount(10)}
                  >
                    10
                  </button>
                  <button 
                    className={betAmount === 25 ? "active" : ""} 
                    onClick={() => setBetAmount(25)}
                  >
                    25
                  </button>
                  <button 
                    className={betAmount === 50 ? "active" : ""} 
                    onClick={() => setBetAmount(50)}
                  >
                    50
                  </button>
                  <button 
                    className={betAmount === 100 ? "active" : ""} 
                    onClick={() => setBetAmount(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
          )}
          
          <div className="message-box">
            {message}
          </div>
          
          <div className="game-controls">
            {!gameActive ? (
              <button 
                className="start-button"
                onClick={startNewGame}
                disabled={credits < betAmount}
              >
                Start Game
              </button>
            ) : (
              <div className="active-controls">
                <div className="win-display">
                  <span>Aktuální výhra:</span> 
                  <span className="win-amount">{currentWin}</span>
                </div>
                
                <button 
                  className="cashout-button"
                  onClick={cashOut}
                  disabled={gameOver}
                >
                  Vybrat výhru
                </button>
              </div>
            )}
          </div>
        </div>
        
        {/* Herní historie */}
        <div className="game-history">
          <h2>Historie her</h2>
          <div className="history-container">
            {gameHistory.length > 0 ? (
              <table className="history-table">
                <thead>
                  <tr>
                    <th>Čas</th>
                    <th>Sázka</th>
                    <th>Velikost</th>
                    <th>Výsledek</th>
                    <th>Odkryto</th>
                  </tr>
                </thead>
                <tbody>
                  {gameHistory.map((game, index) => (
                    <tr key={index} className={game.type}>
                      <td>{formatTime(game.timestamp)}</td>
                      <td>{game.betAmount}</td>
                      <td>{game.gridSize}x{game.gridSize}</td>
                      <td>
                        {game.type === 'win' 
                          ? `${game.winAmount} (${game.multiplier}x)`
                          : 'Prohra'}
                      </td>
                      <td>{game.revealedCount} polí</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-history">Zatím žádná historie her</div>
            )}
          </div>
        </div>
        
        {/* Pravidla hry */}
        <div className="game-rules">
          <h2>Pravidla hry</h2>
          <ul>
            <li><strong>Cíl:</strong> Odkrýt co nejvíce diamantů a vyhnout se minám.</li>
            <li><strong>Diamanty:</strong> Každý diamant násobí vaši sázku (1.1x až 50x).</li>
            <li><strong>Miny:</strong> Pokud odkryjete minu, ztratíte sázku.</li>
            <li><strong>Výhra:</strong> Můžete si kdykoli vybrat aktuální výhru nebo pokračovat a riskovat.</li>
            <li><strong>Počet min:</strong> 3x3 (1-2), 4x4 (3-4), 5x5 (5-7), 6x6 (8-10).</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DiamondMiner;
