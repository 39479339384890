import React, { useEffect, useRef } from 'react';
import './Coinflip.css';

// Gold coin with heads and tails sides
const CoinflipAnimation = ({ isFlipping, result, onComplete }) => {
  const coinRef = useRef(null);
  
  useEffect(() => {
    if (!coinRef.current) return;
    
    if (isFlipping) {
      coinRef.current.classList.add('flipping');
      
      if (result) {
        // If we already know the result, prepare final state
        setTimeout(() => {
          coinRef.current.classList.remove('flipping');
          coinRef.current.classList.add('show-result', result);
          if (onComplete) onComplete();
        }, 2000); // Duration of the flip animation
      }
    } else {
      // Reset animation
      coinRef.current.classList.remove('flipping', 'show-result', 'heads', 'tails');
    }
  }, [isFlipping, result, onComplete]);

  return (
    <div className="coin-container">
      <div className="coin" ref={coinRef}>
        <div className="side heads">
          <div className="coin-face">H</div>
        </div>
        <div className="side tails">
          <div className="coin-face">T</div>
        </div>
      </div>
      
      {/* Shadow beneath the coin */}
      <div className={`coin-shadow ${isFlipping ? 'flipping' : ''}`}></div>
    </div>
  );
};

export default CoinflipAnimation;
