import React, { useState, useEffect, useRef, useContext } from 'react';
import './RockPaperScissors.css';
import Navbar from './Navbar';
import { CreditsContext } from '../context/CreditsContext';

// Import obrázků
import rockImg from './images/rock.png';
import paperImg from './images/paper.png';
import scissorsImg from './images/scissors.png';

const RockPaperScissors = ({ credits: initialCredits, username, handleLogout }) => {
  const [gameMode, setGameMode] = useState(null); // 1, 3, nebo 5 kol
  const [roundsPlayed, setRoundsPlayed] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [computerScore, setComputerScore] = useState(0);
  const [playerChoice, setPlayerChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [roundResult, setRoundResult] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [finalResult, setFinalResult] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [history, setHistory] = useState([]);
  const [showGameSettings, setShowGameSettings] = useState(true);
  
  // Nové stavy pro sázky
  const [betAmount, setBetAmount] = useState(5);
  const [totalWin, setTotalWin] = useState(0);
  const [showBetInput, setShowBetInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  // Přidáme možnost vlastní sázky
  const [customBetAmount, setCustomBetAmount] = useState('');
  const betOptions = [5, 10, 25, 50, 100];
  
  // Použijeme kontext pro práci s kredity
  const { credits, updateCredits } = useContext(CreditsContext);

  const countdownRef = useRef(null);

  // Zkontrolujeme, zda máme dostatek kreditů před zahájením hry
  useEffect(() => {
    if (betAmount > credits) {
      setErrorMessage("Nemáte dostatek kreditů pro tuto sázku.");
    } else {
      setErrorMessage("");
    }
  }, [betAmount, credits]);

  const selectGameMode = (rounds) => {
    setGameMode(rounds);
    setShowGameSettings(false);
    setShowBetInput(true);
  };

  // Nová funkce pro nastavení sázky
  const handleBetSelect = (amount) => {
    if (amount <= credits) {
      setBetAmount(amount);
      setCustomBetAmount('');
    }
  };

  // Funkce pro nastavení vlastní sázky
  const handleCustomBetChange = (e) => {
    const value = e.target.value;
    // Povolit pouze čísla
    if (/^\d*$/.test(value)) {
      setCustomBetAmount(value);
      if (value && parseInt(value) > 0 && parseInt(value) <= credits) {
        setBetAmount(parseInt(value));
      }
    }
  };

  // Upravená funkce pro zahájení hry
  const startGame = () => {
    if (betAmount <= 0) {
      setErrorMessage("Prosím, vyberte platnou částku pro sázku.");
      return;
    }
    
    if (betAmount > credits) {
      setErrorMessage("Nemáte dostatek kreditů pro tuto sázku.");
      return;
    }
    
    // Odečteme sázku z kreditů
    updateCredits(-betAmount);
    setShowBetInput(false);
    resetGame();
  };

  const resetGame = () => {
    setRoundsPlayed(0);
    setPlayerScore(0);
    setComputerScore(0);
    setPlayerChoice(null);
    setComputerChoice(null);
    setRoundResult(null);
    setGameOver(false);
    setFinalResult(null);
    setCountdown(null);
    setHistory([]);
    setTotalWin(0);
  };

  const playAgain = () => {
    setShowGameSettings(true);
    resetGame();
  };

  const handlePlayerChoice = (choice) => {
    if (isAnimating || gameOver) return;

    setIsAnimating(true);
    setPlayerChoice(choice);
    setCountdown(3);
    
    // Start countdown
    countdownRef.current = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(countdownRef.current);
          determineRoundWinner(choice);
          return null;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const determineRoundWinner = (playerSelection) => {
    const choices = ['rock', 'paper', 'scissors'];
    const computerSelection = choices[Math.floor(Math.random() * 3)];
    
    setComputerChoice(computerSelection);
    
    let result;
    if (playerSelection === computerSelection) {
      result = 'tie';
    } else if (
      (playerSelection === 'rock' && computerSelection === 'scissors') ||
      (playerSelection === 'paper' && computerSelection === 'rock') ||
      (playerSelection === 'scissors' && computerSelection === 'paper')
    ) {
      result = 'win';
      setPlayerScore((prev) => prev + 1);
    } else {
      result = 'lose';
      setComputerScore((prev) => prev + 1);
    }
    
    setRoundResult(result);
    setHistory((prev) => [...prev, { 
      round: roundsPlayed + 1,
      playerChoice: playerSelection,
      computerChoice: computerSelection,
      result
    }]);
    
    setTimeout(() => {
      // Zvýšíme počet kol pouze pokud to nebyla remíza
      if (result !== 'tie') {
        setRoundsPlayed((prev) => prev + 1);
      }
      
      // Reset stavu pro další kolo
      setPlayerChoice(null);
      setComputerChoice(null);
      setRoundResult(null);
      setIsAnimating(false);
    }, 2000);
  };

  // Kontrola konce hry
  useEffect(() => {
    if (!gameMode || roundsPlayed === 0) return;
    
    const neededToWin = Math.ceil(gameMode / 2);
    
    if (playerScore >= neededToWin) {
      setGameOver(true);
      setFinalResult('win');
      
      // Výpočet výhry - dvojnásobek sázky
      const winAmount = betAmount * 2;
      setTotalWin(winAmount);
      updateCredits(winAmount);
      
    } else if (computerScore >= neededToWin) {
      setGameOver(true);
      setFinalResult('lose');
      // Při prohře už se sázka nevrací, byla odečtena na začátku
    } else if (roundsPlayed >= gameMode) {
      setGameOver(true);
      if (playerScore > computerScore) {
        setFinalResult('win');
        // Výpočet výhry - dvojnásobek sázky
        const winAmount = betAmount * 2;
        setTotalWin(winAmount);
        updateCredits(winAmount);
      } else if (computerScore > playerScore) {
        setFinalResult('lose');
        // Při prohře už se sázka nevrací, byla odečtena na začátku
      } else {
        setFinalResult('tie');
        // Při remíze vrátíme sázku
        updateCredits(betAmount);
      }
    }
  }, [gameMode, roundsPlayed, playerScore, computerScore, betAmount, updateCredits]);

  // Cleanup function
  useEffect(() => {
    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, []);

  // Helper function to get image for choice
  const getChoiceImage = (choice) => {
    switch (choice) {
      case 'rock': return rockImg;
      case 'paper': return paperImg;
      case 'scissors': return scissorsImg;
      default: return null;
    }
  };

  // Function for result message
  const getResultMessage = (result) => {
    switch (result) {
      case 'win': return 'Vyhráli jste!';
      case 'lose': return 'Prohráli jste!';
      case 'tie': return 'Remíza! Kolo se opakuje.';
      default: return '';
    }
  };

  // Function for final result message
  const getFinalResultMessage = (result) => {
    switch (result) {
      case 'win': return `Gratulujeme! Vyhráli jste hru a ${totalWin} kreditů!`;
      case 'lose': return `Bohužel jste prohráli ${betAmount} kreditů. Zkuste to znovu!`;
      case 'tie': return 'Hra skončila remízou! Sázka byla vrácena.';
      default: return '';
    }
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="rps-container">
        <h1>Kámen, Nůžky, Papír</h1>
        
        {errorMessage && <div className="rps-error">{errorMessage}</div>}
        
        {showGameSettings ? (
          <div className="game-settings">
            <h2>Vyberte počet kol:</h2>
            <div className="rounds-selection">
              <button onClick={() => selectGameMode(1)} className="round-button">1 kolo</button>
              <button onClick={() => selectGameMode(3)} className="round-button">3 kola</button>
              <button onClick={() => selectGameMode(5)} className="round-button">5 kol</button>
            </div>
          </div>
        ) : showBetInput ? (
          <div className="bet-settings">
            <h2>Vyberte sázku:</h2>
            <div className="bet-options">
              {betOptions.map(amount => (
                <button 
                  key={amount}
                  onClick={() => handleBetSelect(amount)} 
                  className={`bet-option-button ${betAmount === amount ? 'active' : ''}`}
                  disabled={amount > credits}
                >
                  {amount} kreditů
                </button>
              ))}
            </div>
            
            <div className="custom-bet-container">
              <input 
                type="text"
                placeholder="Vlastní částka" 
                value={customBetAmount}
                onChange={handleCustomBetChange} 
                className="custom-bet-input"
                max={credits}
              />
              <span className="credit-symbol">kreditů</span>
            </div>
            
            <button 
              onClick={startGame} 
              className="start-game-button"
              disabled={betAmount <= 0 || betAmount > credits}
            >
              Začít hru
            </button>
          </div>
        ) : (
          <div className="game-area">
            <div className="score-board">
              <div className="player-score">
                <div className="score-label">Hráč</div>
                <div className="score-value">{playerScore}</div>
              </div>
              <div className="round-info">
                <div className="round-label">Kolo</div>
                <div className="round-value">{roundsPlayed + 1} / {gameMode}</div>
              </div>
              <div className="computer-score">
                <div className="score-label">Počítač</div>
                <div className="score-value">{computerScore}</div>
              </div>
            </div>
            
            {/* Zobrazení sázky během hry */}
            <div className="bet-info">
              <span className="bet-label">Sázka:</span>
              <span className="bet-value">{betAmount} kreditů</span>
            </div>
            
            {gameOver ? (
              <div className="game-over">
                <h2 className={`final-result ${finalResult}`}>
                  {getFinalResultMessage(finalResult)}
                </h2>
                <button onClick={playAgain} className="play-again-button">Hrát znovu</button>
              </div>
            ) : (
              <div className="gameplay-area">
                {countdown ? (
                  <div className="countdown">{countdown}</div>
                ) : roundResult ? (
                  <div className="round-result-display">
                    <div className="choices-display">
                      <div className="choice player-choice">
                        <img src={getChoiceImage(playerChoice)} alt={playerChoice} className={`choice-img ${playerChoice}`} />
                        <div className="choice-label">Váš výběr</div>
                      </div>
                      <div className="vs-label">VS</div>
                      <div className="choice computer-choice">
                        <img src={getChoiceImage(computerChoice)} alt={computerChoice} className={`choice-img ${computerChoice}`} />
                        <div className="choice-label">Počítač</div>
                      </div>
                    </div>
                    <div className={`result-message ${roundResult}`}>
                      {getResultMessage(roundResult)}
                    </div>
                  </div>
                ) : (
                  <div className="choice-buttons">
                    <h2>Vyberte svůj tah:</h2>
                    <div className="choices">
                      <button 
                        className="choice-button" 
                        onClick={() => handlePlayerChoice('rock')}
                        disabled={isAnimating}
                      >
                        <img src={rockImg} alt="Kámen" className="choice-img" />
                        <span>Kámen</span>
                      </button>
                      <button 
                        className="choice-button" 
                        onClick={() => handlePlayerChoice('paper')}
                        disabled={isAnimating}
                      >
                        <img src={paperImg} alt="Papír" className="choice-img" />
                        <span>Papír</span>
                      </button>
                      <button 
                        className="choice-button" 
                        onClick={() => handlePlayerChoice('scissors')}
                        disabled={isAnimating}
                      >
                        <img src={scissorsImg} alt="Nůžky" className="choice-img" />
                        <span>Nůžky</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            
            <div className="game-history">
              <h3>Historie kol:</h3>
              <div className="history-list">
                {history.map((round, index) => (
                  <div key={index} className={`history-item ${round.result}`}>
                    <span className="history-round">Kolo {round.round}:</span>
                    <div className="history-choices">
                      <span className="history-player">
                        <img 
                          src={getChoiceImage(round.playerChoice)} 
                          alt={round.playerChoice} 
                          className="history-icon" 
                        />
                      </span>
                      <span className="history-vs">vs</span>
                      <span className="history-computer">
                        <img 
                          src={getChoiceImage(round.computerChoice)} 
                          alt={round.computerChoice} 
                          className="history-icon" 
                        />
                      </span>
                    </div>
                    <span className={`history-result ${round.result}`}>
                      {round.result === 'win' ? 'Výhra' : round.result === 'lose' ? 'Prohra' : 'Remíza'}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        <div className="game-rules">
          <h3>Pravidla hry:</h3>
          <ul>
            <li>Kámen porazí nůžky (kámen ztupí nůžky)</li>
            <li>Nůžky porazí papír (nůžky rozstřihnou papír)</li>
            <li>Papír porazí kámen (papír zabalí kámen)</li>
            <li>Remízy se nepočítají do odehraných kol</li>
            <li>Vyhrává ten, kdo získá více bodů v daném počtu kol</li>
            <li>Při výhře získáte dvojnásobek vaší sázky</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RockPaperScissors;
