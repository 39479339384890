import React, { useState, useEffect, useRef } from 'react';
import './Crash.css';
import Navbar from './Navbar';
import creditsManager from '../utils/creditsManager';

const Crash = ({ username, handleLogout }) => {
  // Základní herní stavy
  const [betAmount, setBetAmount] = useState(10);
  const [credits, setCredits] = useState(1000);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [multiplier, setMultiplier] = useState(1.00);
  const [crashPoint, setCrashPoint] = useState(null);
  const [hasCashed, setHasCashed] = useState(false);
  const [cashoutMultiplier, setCashoutMultiplier] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);
  const [inputAmount, setInputAmount] = useState('');
  const [showCrashStatus, setShowCrashStatus] = useState(false);
  const [currentGameId, setCurrentGameId] = useState(null);
  const [ws, setWs] = useState(null);
  
  // Reference pro herní elementy
  const gameInterval = useRef(null);
  const graphRef = useRef(null);
  const shipRef = useRef(null);
  
  // Přidáme ref na Navbar komponentu pro aktualizaci kreditů
  const navbarRef = useRef(null);
  
  // WebSocket připojení - pro budoucí implementaci
  const [wsConnected, setWsConnected] = useState(false);
  const wsRef = useRef(null);
  
  // Načtení kreditů z API při prvním načtení komponenty pomocí creditsManager
  useEffect(() => {
    const initializeCredits = async () => {
      const fetchedCredits = await creditsManager.fetchCredits();
      if (fetchedCredits !== null) {
        setCredits(fetchedCredits);
      }
    };
    
    initializeCredits();
  }, []);

  // Funkce pro generování crash pointu
  const generateCrashPoint = () => {
    console.warn("POZOR: Tato funkce slouží pouze pro vývojáře!");
    const random = Math.random();
    const value = 1 + (Math.pow(random, -0.9) - 1) * 0.7;
    return Math.min(10.00, value);
  };

  // Aktualizace pozice lodi a grafu - tato funkce se volá v každém intervalu
  const updateGameVisuals = (multiplierValue) => {
    if (!graphRef.current || !shipRef.current) return;
    
    // Logaritmická progrese pro více přirozenou křivku grafu
    const logMultiplier = Math.log10(multiplierValue * 5) / Math.log10(50);
    const progress = Math.min(logMultiplier * 100, 100);
    
    // Nelineární mapování pro rychlejší pohyb na začátku a zpomalení na konci
    const easedProgress = Math.pow(progress / 100, 0.8) * 100;
    
    // Aktualizace pozice lodi
    const shipX = easedProgress * 0.7;
    const shipY = easedProgress * 0.9;
    
    // Rotace lodi podle úhlu křivky - dynamická změna úhlu
    const rotationAngle = 45 - progress * 0.3;
    
    // Aplikace transformací na loď
    shipRef.current.style.transform = `translate(${shipX}%, -${shipY}%) rotate(${rotationAngle}deg)`;
    
    // Vykreslení křivky grafu
    if (graphRef.current) {
      // Získání canvasu a kontextu
      const canvas = graphRef.current;
      const ctx = canvas.getContext('2d');
      
      // Vyčištění canvasu
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Nastavení stylu čáry
      ctx.strokeStyle = '#4dff91';
      ctx.lineWidth = 3;
      ctx.shadowBlur = 10;
      ctx.shadowColor = '#4dff91';
      
      // Začátek kreslení cesty
      ctx.beginPath();
      ctx.moveTo(0, canvas.height);
      
      // Vykreslení hladké křivky
      const points = [];
      for (let i = 0; i <= easedProgress; i++) {
        const x = i / 100 * canvas.width;
        // Použití exponenciální křivky pro lepší vzhled
        const y = canvas.height - (Math.pow(i / 100, 1.2) * canvas.height);
        points.push({x, y});
      }
      
      // Vykreslení hladké Bézierovy křivky
      for (let i = 0; i < points.length - 1; i++) {
        const xc = (points[i].x + points[i + 1].x) / 2;
        const yc = (points[i].y + points[i + 1].y) / 2;
        ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
      }
      
      ctx.stroke();
      
      // Přidání gradientového efektu pod křivkou
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, 'rgba(77, 255, 145, 0.3)');
      gradient.addColorStop(1, 'rgba(77, 255, 145, 0)');
      
      ctx.lineTo(points[points.length - 1].x, canvas.height);
      ctx.lineTo(0, canvas.height);
      ctx.fillStyle = gradient;
      ctx.fill();
    }
  };

// Zahájení hry
const startGame = async () => {
  if (betAmount <= 0) {
    alert('Zadejte platnou částku sázky!');
    return;
  }
  
  try {
    // Získat token z localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      alert('Nejste přihlášeni!');
      return;
    }
    
    // Odeslat požadavek na server pro zahájení hry
    const response = await fetch('/gameResults/crash-game', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        betAmount,
        clientSeed: 'client-' + Date.now()
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Chyba při zahájení hry');
    }
    
    const data = await response.json();
    console.log('🎮 Hra zahájena, odpověď serveru:', data);
    
    // Nastavit ID aktuální hry pro pozdější cashout
    setCurrentGameId(data.gameId);
    
    // Aktualizovat kredity z backendu po odečtení sázky
    setCredits(data.updatedCredits);
    
    // Aktualizace navbaru pomocí creditsManager
    creditsManager.refreshNavbar(navbarRef);
    
    // Reset grafu
    if (graphRef.current) {
      const ctx = graphRef.current.getContext('2d');
      ctx.clearRect(0, 0, graphRef.current.width, graphRef.current.height);
    }
    
    // Reset pozice lodi
    if (shipRef.current) {
      shipRef.current.style.transform = 'translate(0%, 0%) rotate(45deg)';
    }
    
    // Reset stavů
    setIsGameRunning(true);
    setMultiplier(1.00);
    setHasCashed(false);
    setCashoutMultiplier(null);
    setShowCrashStatus(false);
    setCrashPoint(null);  // Reset crash pointu
    
    // Inicializace WebSocket spojení
    console.log('📡 Inicializace WebSocket spojení...');
    
    try {
      // Vytvoření WebSocket URL s použitím aktuální domény
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsUrl = `${protocol}//${window.location.host}/ws/crash-game`;
      console.log(`📡 Připojování k WebSocket: ${wsUrl}`);
      
      const socket = new WebSocket(wsUrl);
      
      socket.onopen = () => {
        console.log('📡 WebSocket spojení otevřeno');
        
        // Po otevření spojení pošleme identifikační údaje
        const initMessage = JSON.stringify({
          type: 'init',
          gameId: data.gameId,
          token
        });
        
        console.log(`📡 Odesílání init zprávy: ${initMessage}`);
        socket.send(initMessage);
      };
      
      socket.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          console.log('📡 WebSocket zpráva přijata:', message);
          
          if (message.type === 'connected') {
            console.log(`📡 Spojení úspěšně inicializováno pro hru ${message.gameId}`);
          } else if (message.type === 'crash') {
            console.log(`📡 Crash oznámen serverem: ${message.crashPoint}x`);
            setCrashPoint(message.crashPoint);
            handleCrash();
          } else if (message.type === 'error') {
            console.error(`📡 WebSocket chyba: ${message.message}`);
          }
        } catch (error) {
          console.error('📡 Chyba při zpracování WebSocket zprávy:', error, event.data);
        }
      };
      
      socket.onclose = (event) => {
        console.log(`📡 WebSocket spojení ukončeno. Kód: ${event.code}, Důvod: ${event.reason || 'neuvedeno'}`);
      };
      
      socket.onerror = (error) => {
        console.error('📡 WebSocket chyba:', error);
      };
      
      // Uložení WebSocket spojení
      setWs(socket);
    } catch (wsError) {
      console.error('📡 Chyba při inicializaci WebSocket:', wsError);
    }
    
    // Spuštění herní smyčky s aktualizací každých 30ms
    let currentMultiplier = 1.00;
    gameInterval.current = setInterval(() => {
      // Zvýšení multiplieru s exponenciální křivkou
      currentMultiplier += 0.0075 * (currentMultiplier * 0.18);
      const formattedMultiplier = parseFloat(currentMultiplier.toFixed(2));
      setMultiplier(formattedMultiplier);
      
      // Aktualizace grafu a lodi
      updateGameVisuals(formattedMultiplier);
    }, 30);
    
  } catch (error) {
    alert('Nastala chyba: ' + error.message);
    console.error('Chyba při zahájení hry:', error);
  }
};

// Funkce pro cashout (získání výhry)
const handleCashout = async () => {
  if (!isGameRunning || hasCashed || !currentGameId) return;
  
  try {
    // Nejprve zkontrolujeme stav hry
    const token = localStorage.getItem('authToken');
    const statusCheck = await fetch(`/gameResults/crash-status?gameId=${currentGameId}&currentMultiplier=${multiplier}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    const statusData = await statusCheck.json();
    
    // Pokud hra již crashnula, zabráníme cashoutu
    if (statusData.status === 'crashed') {
      setCrashPoint(statusData.crashPoint);
      handleCrash();
      throw new Error('Hra již crashnula');
    }
    
    // Pokud je hra stále aktivní, provedeme cashout
    const response = await fetch('/gameResults/crash-cashout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        gameId: currentGameId,
        cashoutMultiplier: multiplier
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Chyba při cashoutu');
    }
    
    const data = await response.json();
    
    // Nastavení stavu pro cashout
    setHasCashed(true);
    setCashoutMultiplier(data.confirmedMultiplier || multiplier);
    
    // Aktualizace kreditů z backendu
    setCredits(data.updatedCredits);
    
    // Aktualizace kreditů pomocí creditsManager
    creditsManager.updateCredits(navbarRef);
    
    console.log(`Cashout při ${data.confirmedMultiplier.toFixed(2)}x, výhra: ${data.winAmount} kreditů`);
  } catch (error) {
    alert('Nastala chyba při cashoutu: ' + error.message);
    console.error('Chyba při cashoutu:', error);
  }
};

// Zpracování crashe
const handleCrash = () => {
  console.log('🎮 Zpracování crash eventu');
  
  // Zastavení herní smyčky
  if (gameInterval.current) {
    clearInterval(gameInterval.current);
    gameInterval.current = null;
  }
  
  // Zobrazení crashu s dramatickým efektem
  setShowCrashStatus(true);
  
  // Aktualizace stavu hry
  setIsGameRunning(false);
  
  // Přidání výsledku do historie
  console.log('🎮 Přidávám výsledek do historie:', crashPoint);
  setGameHistory(prev => [
    { 
      multiplier: crashPoint, 
      userCashed: hasCashed, 
      cashoutMultiplier: cashoutMultiplier 
    }, 
    ...prev.slice(0, 9)
  ]);
  
  // Automatické skrytí statusu po 2 sekundách
  setTimeout(() => {
    setShowCrashStatus(false);
  }, 2000);
  
  // Pokud byla hra ukončena bez cashout, zkusíme zjistit výsledek ze serveru
  if (!hasCashed && currentGameId) {
    console.log('🎮 Synchronizace kreditů po crash bez cashoutu');
    // Synchronizace kreditů pomocí creditsManager
    creditsManager.updateCredits(navbarRef, setCredits);
  }

  // Uzavření WebSocket spojení při ukončení hry
  if (ws) {
    console.log('📡 Uzavírám WebSocket spojení');
    ws.close(1000, "Hra ukončena");
    setWs(null);
  }
};

// Vyčištění intervalu při odpojení komponenty
useEffect(() => {
  return () => {
    if (gameInterval.current) {
      clearInterval(gameInterval.current);
    }
    
    // Uzavření WebSocket spojení při odchodu z komponenty
    if (ws) {
      ws.close();
    }

    // Uzavření WebSocket spojení při odchodu z komponenty
    if (wsRef.current) {
      try {
        wsRef.current.close();
      } catch (e) {
        console.log('Chyba při zavírání WebSocket:', e);
      }
    }
  };
}, [ws]);

  // Zpracování vstupu pro sázku
  const handleBetAmountChange = (e) => {
    const value = e.target.value;
    setInputAmount(value);
    
    // Kontrola, že vstupní hodnota je číslo
    if (/^\d+$/.test(value)) {
      setBetAmount(parseInt(value));
    }
  };

  // Nastavení předvolených sázek
  const setQuickBet = (amount) => {
    setBetAmount(amount);
    setInputAmount(amount.toString());
  };

  // Aktualizace rozměrů canvasu při změně rozměrů okna
  useEffect(() => {
    const handleResize = () => {
      if (graphRef.current) {
        const container = graphRef.current.parentElement;
        graphRef.current.width = container.clientWidth;
        graphRef.current.height = container.clientHeight;
        
        // Překreslení grafu, pokud hra běží
        if (isGameRunning) {
          updateGameVisuals(multiplier);
        }
      }
    };
    
    // Inicializace rozměrů při prvním vykreslení
    setTimeout(handleResize, 100);
    
    // Přidat listener pro změnu rozměrů okna
    window.addEventListener('resize', handleResize);
    
    // Odstranit listener při odpojení komponenty
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isGameRunning, multiplier]);

  return (
    <div className="crash-game-container">
      <Navbar 
        username={username} 
        initialCredits={credits} 
        handleLogout={handleLogout} 
        ref={navbarRef} // Přidáme ref pro přístup k metodám navbaru
      />
      
      <div className="game-container">
        <h1>Crash Game</h1>
        
        <div className="crash-display">
          <div className={`crash-status ${showCrashStatus ? 'active' : ''}`}>
            CRASH!
          </div>
          
          <div className="graph-multiplier-value">
            {multiplier.toFixed(2)}x
          </div>
          
          <div className="graph-container">
            <canvas ref={graphRef} className="graph-canvas"></canvas>
            <div 
              ref={shipRef} 
              className="ship-icon"
              style={{ background: 'orange', width: '20px', height: '20px', borderRadius: '50%' }}
            ></div>
          </div>
          
          {!isGameRunning && crashPoint && hasCashed && cashoutMultiplier && (
            <div className="cashout-info">
              Cashout @ {cashoutMultiplier.toFixed(2)}x
              <div className="win-amount">
                Výhra: {Math.floor(betAmount * cashoutMultiplier)} kreditů
              </div>
            </div>
          )}
        </div>
        
        <div className="bet-controls">
          {!isGameRunning ? (
            <>
              <div className="bet-amount">
                <label htmlFor="bet-amount">Sázka:</label>
                <input 
                  type="text" 
                  id="bet-amount" 
                  value={inputAmount || betAmount}
                  onChange={handleBetAmountChange}
                  placeholder="Zadejte částku"
                />
              </div>
              
              <div className="quick-bet-amounts">
                <button onClick={() => setQuickBet(10)}>10x</button>
                <button onClick={() => setQuickBet(50)}>50</button>
                <button onClick={() => setQuickBet(100)}>100</button>
                <button onClick={() => setQuickBet(500)}>500</button>
              </div>
              
              <div className="credit-info">
                Dostupné kredity: {credits.toLocaleString()}
              </div>
              
              <button 
                className="start-game-button" 
                onClick={startGame}
                disabled={betAmount <= 0 || betAmount > credits}
              >
                Vsadit {betAmount} kreditů
              </button>
            </>
          ) : (
            <button 
              className="cashout-button" 
              onClick={handleCashout}
              disabled={hasCashed}
            >
              CASHOUT @ {multiplier.toFixed(2)}x
            </button>
          )}
        </div>
        
        <div className="game-history">
          <h3>Historie her</h3>
          <div className="history-items">
            {gameHistory.map((game, index) => (
              <div 
                key={index} 
                className={`history-item ${game.multiplier < 2 ? 'low' : game.multiplier >= 5 ? 'high' : 'medium'}`}
              >
                <div className="history-multiplier">
                  {parseFloat(game.multiplier).toFixed(2)}x
                </div>
                {game.userCashed && (
                  <div className="user-cashed">
                    Cashout @ {game.cashoutMultiplier.toFixed(2)}x
                  </div>
                )}
              </div>
            ))}
            {gameHistory.length === 0 && (
              <div className="empty-history-message">
                Zatím neproběhla žádná hra
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crash;
