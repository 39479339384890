import axios from 'axios';

/**
 * Centralizovaný modul pro správu kreditů a jejich aktualizaci
 */
const creditsManager = {
  /**
   * Načte aktuální stav kreditů z backendu
   * @returns {Promise<number>} Aktuální počet kreditů
   */
  fetchCredits: async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get('/profile/credits', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        return response.data.credits;
      } else {
        console.error('Chyba při načítání kreditů:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Chyba při načítání kreditů:', error);
      return null;
    }
  },

  /**
   * Aktualizuje kredity v UI komponentách
   * @param {React.MutableRefObject} navbarRef Reference na Navbar komponentu
   * @param {function} setCreditsFunc Setter funkce pro aktualizaci lokálního stavu
   * @returns {Promise<number|null>} Aktualizovaný počet kreditů nebo null při chybě
   */
  updateCredits: async (navbarRef, setCreditsFunc) => {
    const credits = await creditsManager.fetchCredits();
    
    if (credits !== null) {
      // Aktualizace lokálního stavu, pokud byla předána setter funkce
      if (setCreditsFunc) {
        setCreditsFunc(credits);
      }
      
      // Aktualizace Navbaru, pokud byla předána reference
      if (navbarRef?.current?.refreshCredits) {
        navbarRef.current.refreshCredits();
      }
    }
    
    return credits;
  },
  
  /**
   * Aktualizuje pouze navbar bez načítání nových dat
   * @param {React.MutableRefObject} navbarRef Reference na Navbar komponentu
   */
  refreshNavbar: (navbarRef) => {
    if (navbarRef?.current?.refreshCredits) {
      navbarRef.current.refreshCredits();
    }
  }
};

export default creditsManager;
