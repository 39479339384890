import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import CoinflipAnimation from './CoinflipAnimation';
import './Coinflip.css';

function Coinflip({ credits, setCredits, username, handleLogout }) {
  const [activeGames, setActiveGames] = useState([]);
  const [myGames, setMyGames] = useState([]);
  const [betAmount, setBetAmount] = useState(10);
  const [selectedColor, setSelectedColor] = useState('heads'); // 'heads' or 'tails'
  const [currentGame, setCurrentGame] = useState(null);
  const [gameResult, setGameResult] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);
  const [isFlipping, setIsFlipping] = useState(false);
  const [loadingGame, setLoadingGame] = useState('');
  
  const navigate = useNavigate();
  const coinRef = useRef(null);

  // Mock data for active games
  useEffect(() => {
    // This would normally come from an API
    const mockActiveGames = [
      { id: 1, creator: 'player123', amount: 50, color: 'heads', createdAt: new Date().toISOString() },
      { id: 2, creator: 'gambler44', amount: 100, color: 'tails', createdAt: new Date(Date.now() - 120000).toISOString() },
      { id: 3, creator: 'lucky_one', amount: 25, color: 'heads', createdAt: new Date(Date.now() - 300000).toISOString() },
    ];
    
    const mockHistory = [
      { id: 101, creator: 'winner1', joiner: 'loser1', amount: 200, creatorColor: 'heads', result: 'heads', createdAt: new Date(Date.now() - 600000).toISOString() },
      { id: 102, creator: 'unlucky', joiner: 'lucky', amount: 50, creatorColor: 'tails', result: 'heads', createdAt: new Date(Date.now() - 1200000).toISOString() },
      { id: 103, creator: username, joiner: 'opponent1', amount: 75, creatorColor: 'heads', result: 'heads', createdAt: new Date(Date.now() - 3600000).toISOString() },
    ];
    
    setActiveGames(mockActiveGames);
    setGameHistory(mockHistory);
  }, [username]);

  const createGame = () => {
    if (credits < betAmount) {
      alert("You don't have enough credits!");
      return;
    }
    
    // In a real app, this would be an API call
    const newGame = {
      id: Date.now(),
      creator: username,
      amount: betAmount,
      color: selectedColor,
      createdAt: new Date().toISOString()
    };
    
    // Add to my games
    setMyGames([...myGames, newGame]);
    
    // Update "all games" list
    setActiveGames([...activeGames, newGame]);
    
    // Kredity by se zde odečetly, ale nebudeme používat setCredits
    console.log(`Kredity odečteny: -${betAmount}`);
    // setCredits(prev => prev - betAmount); - odstraněno
  };

  const joinGame = (game) => {
    if (credits < game.amount) {
      alert("You don't have enough credits!");
      return;
    }
    
    if (game.creator === username) {
      alert("You cannot join your own game!");
      return;
    }
    
    setLoadingGame(game.id);
    
    // Simulate API call
    setTimeout(() => {
      // Kredity by se zde odečetly
      console.log(`Kredity odečteny: -${game.amount}`);
      // setCredits(prev => prev - game.amount); - odstraněno
      
      // Remove from active games
      setActiveGames(activeGames.filter(g => g.id !== game.id));
      
      // Set current game and start the flip animation
      setCurrentGame({
        ...game,
        joiner: username,
        joinerColor: game.color === 'heads' ? 'tails' : 'heads'
      });
      
      // Start flip animation
      setIsFlipping(true);
      
      // Determine result (in real app, this would come from the backend)
      const result = Math.random() > 0.5 ? 'heads' : 'tails';
      
      // Wait for animation to complete
      setTimeout(() => {
        // Set result
        setGameResult(result);
        
        // Update game history
        const completedGame = {
          id: game.id,
          creator: game.creator,
          joiner: username,
          amount: game.amount,
          creatorColor: game.color,
          result,
          createdAt: game.createdAt,
          completedAt: new Date().toISOString()
        };
        setGameHistory([completedGame, ...gameHistory]);
        
        // Determine winner and update credits
        const isCreatorWin = game.color === result;
        const winnerUsername = isCreatorWin ? game.creator : username;
        const winAmount = game.amount * 2 * 0.97; // 3% house edge
        
        if (winnerUsername === username) {
          console.log(`Výhra připsána: +${winAmount}`);
          // setCredits(prev => prev + winAmount); - odstraněno
        }
        
        // End animation
        setTimeout(() => {
          setIsFlipping(false);
          setLoadingGame('');
          
          // Clear current game after displaying result
          setTimeout(() => {
            setCurrentGame(null);
            setGameResult(null);
          }, 3000);
        }, 1000);
      }, 2000);
    }, 1000);
  };

  const cancelGame = (gameId) => {
    // Find the game
    const game = myGames.find(g => g.id === gameId);
    if (!game) return;
    
    // Remove from my games
    setMyGames(myGames.filter(g => g.id !== gameId));
    
    // Remove from active games
    setActiveGames(activeGames.filter(g => g.id !== gameId));
    
    // Kredity by se zde vrátily
    console.log(`Kredity vráceny: +${game.amount}`);
    // setCredits(prev => prev + game.amount); - odstraněno
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diffSeconds = Math.floor((now - createdAt) / 1000);
    
    if (diffSeconds < 60) return `${diffSeconds}s ago`;
    if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)}m ago`;
    return `${Math.floor(diffSeconds / 3600)}h ago`;
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="coinflip-container">
        <h1>Coinflip</h1>
        
        {currentGame && (
          <div className="game-in-progress">
            <div className="game-players">
              <div className="player creator">
                <h3>{currentGame.creator}</h3>
                <div className={`coin-choice ${currentGame.color}`}>{currentGame.color}</div>
                <div className="bet-amount">{currentGame.amount} credits</div>
              </div>
              
              <div className="vs">VS</div>
              
              <div className="player joiner">
                <h3>{currentGame.joiner}</h3>
                <div className={`coin-choice ${currentGame.joinerColor}`}>{currentGame.joinerColor}</div>
                <div className="bet-amount">{currentGame.amount} credits</div>
              </div>
            </div>
            
            <div className="coin-flip-area">
              <CoinflipAnimation 
                isFlipping={isFlipping} 
                result={gameResult} 
                onComplete={() => console.log("Animation completed")} 
              />
            </div>
            
            {gameResult && (
              <div className="game-result">
                <h2>Result: {gameResult}</h2>
                <p>Winner: {gameResult === currentGame.color ? currentGame.creator : currentGame.joiner}</p>
                <p>Won: {Math.floor(currentGame.amount * 2 * 0.97)} credits</p>
              </div>
            )}
          </div>
        )}
        
        {!currentGame && (
          <div className="game-creation">
            <div className="create-game-form">
              <h2>Create New Game</h2>
              
              <div className="form-group">
                <label>Bet Amount</label>
                <div className="bet-buttons">
                  {[10, 25, 50, 100, 500].map(amount => (
                    <button 
                      key={amount} 
                      className={`bet-button ${betAmount === amount ? 'active' : ''}`}
                      onClick={() => setBetAmount(amount)}
                    >
                      {amount}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="form-group">
                <label>Choose Your Side</label>
                <div className="coin-options">
                  <button 
                    className={`coin-option heads ${selectedColor === 'heads' ? 'active' : ''}`}
                    onClick={() => setSelectedColor('heads')}
                  >
                    Heads
                  </button>
                  <button 
                    className={`coin-option tails ${selectedColor === 'tails' ? 'active' : ''}`}
                    onClick={() => setSelectedColor('tails')}
                  >
                    Tails
                  </button>
                </div>
              </div>
              
              <button className="create-game-button" onClick={createGame}>
                Create Game
              </button>
            </div>
            
            <div className="game-lists">
              <div className="active-games">
                <h2>Available Games</h2>
                {activeGames.length > 0 ? (
                  <div className="game-cards">
                    {activeGames.map(game => (
                      <div key={game.id} className="game-card">
                        <div className="game-info">
                          <div className="player-name">{game.creator}</div>
                          <div className="game-details">
                            <span className={`coin-badge ${game.color}`}>{game.color}</span>
                            <span className="bet-badge">{game.amount} credits</span>
                          </div>
                          <div className="game-time">{getTimeAgo(game.createdAt)}</div>
                        </div>
                        <button 
                          className="join-button" 
                          onClick={() => joinGame(game)}
                          disabled={loadingGame === game.id}
                        >
                          {loadingGame === game.id ? 'Joining...' : 'Join Game'}
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-games">No active games available</div>
                )}
              </div>
              
              <div className="my-games">
                <h2>My Created Games</h2>
                {myGames.length > 0 ? (
                  <div className="game-cards">
                    {myGames.map(game => (
                      <div key={game.id} className="game-card my-game">
                        <div className="game-info">
                          <div className="game-details">
                            <span className={`coin-badge ${game.color}`}>{game.color}</span>
                            <span className="bet-badge">{game.amount} credits</span>
                          </div>
                          <div className="game-time">{getTimeAgo(game.createdAt)}</div>
                        </div>
                        <button className="cancel-button" onClick={() => cancelGame(game.id)}>
                          Cancel
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-games">You haven't created any games</div>
                )}
              </div>
            </div>
          </div>
        )}
        
        <div className="game-history">
          <h2>Recent Games</h2>
          <table className="history-table">
            <thead>
              <tr>
                <th>Creator</th>
                <th>Joiner</th>
                <th>Amount</th>
                <th>Result</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {gameHistory.map(game => (
                <tr key={game.id}>
                  <td className={game.result === game.creatorColor ? 'winner' : ''}>
                    {game.creator}
                  </td>
                  <td className={game.result !== game.creatorColor ? 'winner' : ''}>
                    {game.joiner}
                  </td>
                  <td>{game.amount * 2}</td>
                  <td className={`result ${game.result}`}>
                    {game.result}
                  </td>
                  <td>{formatTime(game.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Coinflip;
