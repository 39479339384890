import React, { useState, useEffect, useContext } from 'react';
import Navbar from './Navbar';
import { CreditsContext } from '../context/CreditsContext';
import './PricePredictor.css';
import soundManager from '../utils/SoundManager';

// CS2 skiny s obrázky 
const cs2Skins = [
  { 
    id: 1,
    name: "AWP | Dragon Lore (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=AWP+Dragon+Lore", 
    actualPrice: 42800 
  },
  { 
    id: 2,
    name: "M4A4 | Howl (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=M4A4+Howl", 
    actualPrice: 31500 
  },
  { 
    id: 3,
    name: "AK-47 | Fire Serpent (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=AK-47+Fire+Serpent", 
    actualPrice: 18200 
  },
  { 
    id: 4,
    name: "Butterfly Knife | Crimson Web (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=Butterfly+Knife+Crimson+Web", 
    actualPrice: 15700 
  },
  { 
    id: 5,
    name: "Karambit | Fade (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=Karambit+Fade", 
    actualPrice: 8600 
  },
  { 
    id: 6,
    name: "AWP | Neo-Noir (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=AWP+Neo-Noir", 
    actualPrice: 890 
  },
  { 
    id: 7,
    name: "USP-S | Kill Confirmed (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=USP-S+Kill+Confirmed", 
    actualPrice: 350 
  },
  { 
    id: 8,
    name: "Glock-18 | Fade (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=Glock-18+Fade", 
    actualPrice: 2100 
  },
  { 
    id: 9,
    name: "M4A1-S | Hyper Beast (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=M4A1-S+Hyper+Beast", 
    actualPrice: 120 
  },
  { 
    id: 10,
    name: "AK-47 | Asiimov (Field-Tested)", 
    image: "https://via.placeholder.com/300x200?text=AK-47+Asiimov", 
    actualPrice: 90 
  },
  { 
    id: 11,
    name: "Desert Eagle | Blaze (Factory New)", 
    image: "https://via.placeholder.com/300x200?text=Desert+Eagle+Blaze", 
    actualPrice: 780 
  },
  { 
    id: 12,
    name: "AWP | Asiimov (Field-Tested)", 
    image: "https://via.placeholder.com/300x200?text=AWP+Asiimov", 
    actualPrice: 450 
  }
];

function PricePredictor({ username, handleLogout }) {
  const { credits } = useContext(CreditsContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [betAmount, setBetAmount] = useState(10);
  const [currentRound, setCurrentRound] = useState(0);
  const [maxRounds, setMaxRounds] = useState(5);
  const [playerScore, setPlayerScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [currentSkin, setCurrentSkin] = useState(null);
  const [referenceSkin, setReferenceSkin] = useState(null);
  const [playerGuess, setPlayerGuess] = useState(null); // 'higher' nebo 'lower'
  const [opponentGuess, setOpponentGuess] = useState(null);
  const [roundResult, setRoundResult] = useState('');
  const [gameResult, setGameResult] = useState('');
  const [showActualPrice, setShowActualPrice] = useState(false);
  const [waitingForOpponent, setWaitingForOpponent] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);
  const [usedSkinIds, setUsedSkinIds] = useState([]);
  const [isExtended, setIsExtended] = useState(false);
  const [originalMaxRounds, setOriginalMaxRounds] = useState(5);

  // Inicializace hry
  const startGame = () => {
    if (isPlaying) return;

    // Přehrajeme zvuk startu hry, pokud existuje
    if (soundManager.sounds['buttonClick']) {
      soundManager.play('buttonClick');
    }

    setIsPlaying(true);
    setCurrentRound(1);
    setPlayerScore(0);
    setOpponentScore(0);
    setPlayerGuess(null);
    setOpponentGuess(null);
    setRoundResult('');
    setGameResult('');
    setUsedSkinIds([]);
    setIsExtended(false);
    setOriginalMaxRounds(5);
    setMaxRounds(5);
    
    // Vybíráme referenční skin a první skin k hádání
    selectReferenceSkin();
    selectNextSkin();
  };

  // Výběr referenčního skinu
  const selectReferenceSkin = () => {
    const randomIndex = Math.floor(Math.random() * cs2Skins.length);
    setReferenceSkin(cs2Skins[randomIndex]);
    setUsedSkinIds([cs2Skins[randomIndex].id]);
  };

  // Výběr dalšího skinu pro nové kolo
  const selectNextSkin = () => {
    // Vybereme skin, který ještě nebyl použit v této hře a není referenční skin
    const availableSkins = cs2Skins.filter(s => !usedSkinIds.includes(s.id));
    
    if (availableSkins.length === 0) {
      // Pokud by došly skiny, resetovali bychom použité ID kromě referenčního skinu
      setUsedSkinIds([referenceSkin.id]);
      // A vzali bychom skin ze všech kromě referenčního
      const skinPool = cs2Skins.filter(s => s.id !== referenceSkin.id);
      const randomSkin = skinPool[Math.floor(Math.random() * skinPool.length)];
      setCurrentSkin(randomSkin);
      setUsedSkinIds(prev => [...prev, randomSkin.id]);
    } else {
      // Vybereme náhodný skin z dostupných
      const randomSkin = availableSkins[Math.floor(Math.random() * availableSkins.length)];
      setCurrentSkin(randomSkin);
      setUsedSkinIds(prev => [...prev, randomSkin.id]);
    }
  };

  // Funkce pro odeslání hráčova tipu
  const handleGuess = (guess) => {
    setPlayerGuess(guess);
    setWaitingForOpponent(true);

    // Simulace odhadu protihráče (počítače)
    setTimeout(() => {
      // AI protivníka má 50/50 šanci na správnou odpověď
      const computerGuessCorrect = Math.random() > 0.5;
      const actualHigherOrLower = currentSkin.actualPrice > referenceSkin.actualPrice ? 'higher' : 'lower';
      const computerGuess = computerGuessCorrect ? actualHigherOrLower : (actualHigherOrLower === 'higher' ? 'lower' : 'higher');
      
      setOpponentGuess(computerGuess);
      setShowActualPrice(true);
      setWaitingForOpponent(false);
      
      // Vyhodnocení kola
      evaluateRound(guess, computerGuess);
    }, 1500);
  };

  // Vyhodnocení kola
  const evaluateRound = (playerGuessValue, opponentGuessValue) => {
    const actualHigherOrLower = currentSkin.actualPrice > referenceSkin.actualPrice ? 'higher' : 'lower';
    
    const playerCorrect = playerGuessValue === actualHigherOrLower;
    const opponentCorrect = opponentGuessValue === actualHigherOrLower;
    
    let result = '';
    let newPlayerScore = playerScore;
    let newOpponentScore = opponentScore;

    if (playerCorrect && !opponentCorrect) {
      result = 'Vyhráli jste toto kolo!';
      newPlayerScore += 1;
      if (soundManager.sounds['win']) {
        soundManager.play('win');
      }
    } else if (!playerCorrect && opponentCorrect) {
      result = 'Protivník vyhrál toto kolo.';
      newOpponentScore += 1;
      if (soundManager.sounds['lose']) {
        soundManager.play('lose');
      }
    } else if (playerCorrect && opponentCorrect) {
      result = 'Oba jste uhodli správně!';
      newPlayerScore += 1;
      newOpponentScore += 1;
      if (soundManager.sounds['buttonClick']) {
        soundManager.play('buttonClick');
      }
    } else {
      result = 'Ani jeden z vás neuhodl správně.';
      if (soundManager.sounds['buttonClick']) {
        soundManager.play('buttonClick');
      }
    }

    setPlayerScore(newPlayerScore);
    setOpponentScore(newOpponentScore);
    setRoundResult(result);

    // Přechod na další kolo nebo konec hry po 3 sekundách
    setTimeout(() => {
      if (currentRound < maxRounds) {
        setCurrentRound(currentRound + 1);
        setPlayerGuess(null);
        setOpponentGuess(null);
        setRoundResult('');
        setShowActualPrice(false);
        selectNextSkin();
      } else {
        // Nabídka dodatečných kol v případě remízy
        if (newPlayerScore === newOpponentScore && !isExtended) {
          offerExtension();
        } else {
          // Konec hry
          endGame(newPlayerScore, newOpponentScore);
        }
      }
    }, 3000);
  };

  // Nabídka prodloužení hry v případě remízy
  const offerExtension = () => {
    setGameResult('Remíza! Chcete hrát další 2 kola pro rozhodnutí?');
    setIsExtended(true);
  };

  // Přijetí nabídky prodloužení
  const acceptExtension = () => {
    setMaxRounds(originalMaxRounds + 2);
    setGameResult('');
    selectNextSkin();
  };

  // Odmítnutí nabídky prodloužení = konec hry remízou
  const declineExtension = () => {
    endGame(playerScore, opponentScore);
  };

  // Konec hry
  const endGame = (finalPlayerScore, finalOpponentScore) => {
    let finalResult = '';
    let winAmount = 0;

    if (finalPlayerScore > finalOpponentScore) {
      finalResult = 'Gratuluji! Vyhráli jste hru!';
      winAmount = betAmount * 2;
      if (soundManager.sounds['win']) {
        soundManager.play('win');
      }
    } else if (finalPlayerScore < finalOpponentScore) {
      finalResult = 'Bohužel, prohráli jste hru.';
      if (soundManager.sounds['lose']) {
        soundManager.play('lose');
      }
    } else {
      finalResult = 'Hra skončila remízou!';
      winAmount = betAmount; // Vrátíme sázku při remíze
    }

    setGameResult(finalResult);

    // Aktualizace historie her
    const newGame = {
      date: new Date().toLocaleString(),
      playerScore: finalPlayerScore,
      opponentScore: finalOpponentScore,
      result: finalPlayerScore > finalOpponentScore ? 'win' : finalPlayerScore < finalOpponentScore ? 'loss' : 'draw',
      betAmount,
      winAmount
    };

    setGameHistory(prev => [newGame, ...prev.slice(0, 9)]);

    // Resetování hry po 3 sekundách
    setTimeout(() => {
      setIsPlaying(false);
    }, 3000);
  };

  // Funkce pro změnu sázky
  const handleBetChange = (amount) => {
    if (!isPlaying) {
      setBetAmount(amount);
    }
  };

  // Funkce pro formátování ceny
  const formatPrice = (price) => {
    return new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="price-predictor-container">
        <h1>Price Predictor</h1>
        
        <div className="game-info">
          <p>Uhodněte, zda je cena zobrazené položky vyšší nebo nižší než referenční položka. Za správný odhad získáváte bod. Hraje se na {maxRounds} kol.</p>
        </div>
        
        {!isPlaying ? (
          <div className="startup-controls">
            <div className="bet-controls">
              <h3>Vyberte sázku:</h3>
              <div className="bet-buttons">
                <button onClick={() => handleBetChange(10)} className={betAmount === 10 ? 'active' : ''}>10</button>
                <button onClick={() => handleBetChange(20)} className={betAmount === 20 ? 'active' : ''}>20</button>
                <button onClick={() => handleBetChange(50)} className={betAmount === 50 ? 'active' : ''}>50</button>
                <button onClick={() => handleBetChange(100)} className={betAmount === 100 ? 'active' : ''}>100</button>
              </div>
            </div>
            <button 
              className="start-game-button" 
              onClick={startGame}
              disabled={credits < betAmount}
            >
              Start Game
            </button>
          </div>
        ) : (
          <div className="game-board">
            <div className="game-status">
              <div className="round-info">Kolo {currentRound}/{maxRounds}</div>
              <div className="score-board">
                <div className="player-score">Hráč: {playerScore}</div>
                <div className="vs">vs</div>
                <div className="opponent-score">Soupeř: {opponentScore}</div>
              </div>
              {roundResult && <div className="round-result">{roundResult}</div>}
              {gameResult && (
                <div className="game-result">
                  {gameResult}
                  {isExtended && !showActualPrice && (
                    <div className="extension-buttons">
                      <button onClick={acceptExtension} className="extension-button accept">Ano, hrát dál</button>
                      <button onClick={declineExtension} className="extension-button decline">Ne, ukončit hru</button>
                    </div>
                  )}
                </div>
              )}
            </div>
            
            {referenceSkin && currentSkin && !isExtended && (
              <div className="skin-comparison">
                <div className="reference-skin">
                  <h3>Referenční položka</h3>
                  <div className="skin-image-container">
                    <img src={referenceSkin.image} alt={referenceSkin.name} className="skin-image" />
                  </div>
                  <div className="skin-name">{referenceSkin.name}</div>
                  <div className="skin-price">{formatPrice(referenceSkin.actualPrice)}</div>
                </div>
                
                <div className="current-skin">
                  <h3>Aktuální položka</h3>
                  <div className="skin-image-container">
                    <img src={currentSkin.image} alt={currentSkin.name} className="skin-image" />
                  </div>
                  <div className="skin-name">{currentSkin.name}</div>
                  {showActualPrice ? (
                    <div className="skin-price">{formatPrice(currentSkin.actualPrice)}</div>
                  ) : (
                    <div className="guess-buttons">
                      <button 
                        onClick={() => handleGuess('higher')} 
                        className={`guess-button higher ${playerGuess === 'higher' ? 'selected' : ''}`}
                        disabled={playerGuess !== null || waitingForOpponent}
                      >
                        Vyšší cena
                      </button>
                      <button 
                        onClick={() => handleGuess('lower')} 
                        className={`guess-button lower ${playerGuess === 'lower' ? 'selected' : ''}`}
                        disabled={playerGuess !== null || waitingForOpponent}
                      >
                        Nižší cena
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            
            <div className="guesses-display">
              {playerGuess && (
                <div className="player-guess">
                  Váš tip: <span>{playerGuess === 'higher' ? 'Vyšší' : 'Nižší'}</span>
                </div>
              )}
              
              {opponentGuess && (
                <div className="opponent-guess">
                  Tip soupeře: <span>{opponentGuess === 'higher' ? 'Vyšší' : 'Nižší'}</span>
                </div>
              )}
              
              {waitingForOpponent && (
                <div className="waiting-message">Čekání na tip soupeře...</div>
              )}
            </div>
          </div>
        )}
        
        <div className="game-history">
          <h3>Historie posledních her</h3>
          <table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Skóre</th>
                <th>Výsledek</th>
                <th>Sázka</th>
                <th>Výhra</th>
              </tr>
            </thead>
            <tbody>
              {gameHistory.map((game, index) => (
                <tr key={index} className={`history-row ${game.result}`}>
                  <td>{game.date}</td>
                  <td>{game.playerScore} : {game.opponentScore}</td>
                  <td>
                    {game.result === 'win' && 'Výhra'}
                    {game.result === 'loss' && 'Prohra'}
                    {game.result === 'draw' && 'Remíza'}
                  </td>
                  <td>{game.betAmount}</td>
                  <td>{game.winAmount > 0 ? `+${game.winAmount}` : '0'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PricePredictor;
