import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { CreditsProvider } from './context/CreditsContext'; // Vrácení původního CreditsContext
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import GameMenuPage from './GameMenuPage';
import PlinkoGame from './components/PlinkoGame';
import Roulette from './components/Roulette';
import Crash from './components/Crash';
import PvpCrash from './components/PvpCrash'; // Přidáno pro PVP Crash
import Coinflip from './components/Coinflip';
import DiamondMiner from './components/DiamondMiner';
import SkinSearch from './components/SkinSearch';
import ProfilePage from './pages/ProfilePage';
import CardBattle from './components/CardBattle';
import PricePredictor from './components/PricePredictor';
import CardFinder from './components/CardFinder';
import RockPaperScissors from './components/RockPaperScissors';
import axios from 'axios';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(100); // Vrácení stavu kreditů
  
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log("Token nalezen v localStorage:", token);
    if (token) {
      setIsAuthenticated(true);
      // Načtení jména uživatele
      const fetchUsername = async () => {
        try {
          const response = await axios.get('/profile', {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log("Odpověď z /profile:", response.data);
          setUsername(response.data.username);
        } catch (error) {
          console.error('Chyba při načítání jména uživatele:', error.response?.data || error.message);
        }
      };
      fetchUsername();
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/" />;
  };

  return (
    <CreditsProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/game" element={<PrivateRoute element={<GameMenuPage setIsAuthenticated={setIsAuthenticated} />} />} />
            <Route path="/game/plinko" element={<PrivateRoute element={<PlinkoGame />} />} />
            <Route path="/game/roulette" element={<PrivateRoute element={<Roulette credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/crash" element={<PrivateRoute element={<Crash credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/pvp-crash" element={<PrivateRoute element={<PvpCrash username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/coinflip" element={<PrivateRoute element={<Coinflip credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/card-battle" element={<PrivateRoute element={<CardBattle credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/price-predictor" element={<PrivateRoute element={<PricePredictor credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/diamond-miner" element={<PrivateRoute element={<DiamondMiner credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/card-finder" element={<PrivateRoute element={<CardFinder credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/rock-paper-scissors" element={<PrivateRoute element={<RockPaperScissors credits={credits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/skinsearch" element={<PrivateRoute element={<SkinSearch />} />} />
            <Route path="/profile" element={<PrivateRoute element={<ProfilePage username={username} />} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </CreditsProvider>
  );
}

export default App;