import React, { useEffect, useRef, useState, useContext } from 'react';
import Matter from 'matter-js';
import Navbar from './Navbar'; // Import nové komponenty Navbar
import { useNavigate } from 'react-router-dom'; // Přidání useNavigate
import { CreditsContext } from '../context/CreditsContext'; // Import CreditsContext
import './PlinkoGame.css';
import './DailyMission.css'; // Import nového CSS pro mise

function PlinkoGame() {
  const sceneRef = useRef(null);
  const engineRef = useRef(Matter.Engine.create());
  const ballCounterRef = useRef(0);
  const pegSpacing = 50;
  const ballSize = 10; // Zvětšení míčku pro lepší viditelnost (bylo 20)
  const pegs = useRef([]); // Ref pro uložení všech kolíků
  const finalTargets = useRef([]); // Ref pro uložení finálních čtverečků
  const targetPegsMap = useRef(new Map()); // Mapa pro uložení cesty pro každý míček
  
  // Reference pro animaci výhry
  const winAnimationsRef = useRef([]);

  const [username, setUsername] = useState('');
  //const [credits, setCredits] = useState(0);
  const { credits } = useContext(CreditsContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  // Nový stav pro denní mise
  const [dailyMissions, setDailyMissions] = useState([
    {
      id: 1,
      title: "Rychlá hra",
      description: "Poslat 10 míčků",
      reward: "5% z vaší sázky",
      progress: 0,
      target: 10,
      completed: false,
      incrementStep: 1 // Každý míček zvýší pokrok o 1
    },
    {
      id: 2,
      title: "Šťastná ruka",
      description: "Získat výhru 2x nebo vyšší",
      reward: "10 kreditů",
      progress: 0,
      target: 3,
      completed: false,
      incrementStep: 0.3 // Simulace - po více pokusech
    },
    {
      id: 3,
      title: "Přesná muška",
      description: "Trefit 5x po sobě míčkem do stejné přihrádky",
      reward: "50 kreditů",
      progress: 0,
      target: 5,
      completed: false,
      incrementStep: 0.2 // Obtížnější mise
    },
    {
      id: 4,
      title: "Profesionální hráč",
      description: "Posbírat celkem 500 kreditů z výher",
      reward: "100 kreditů",
      progress: 0,
      target: 500,
      completed: false,
      incrementStep: 10 // Dost pokusů pro dosažení
    },
    {
      id: 5,
      title: "Plinko maniak",
      description: "Poslat celkem 200 míčků",
      reward: "Speciální barva míčku",
      progress: 0,
      target: 200,
      completed: false,
      incrementStep: 1 // Dlouhodobá mise
    }
  ]);

  const [betAmount, setBetAmount] = useState(10);
  const [showMissions, setShowMissions] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);

  // Nové stavy pro vylepšený herní zážitek
  const [ballColor, setBallColor] = useState('#ffffff');
  const [lastWin, setLastWin] = useState(null);
  const [gameStats, setGameStats] = useState({
    totalBalls: 0,
    highestMultiplier: 0,
    totalWin: 0
  });
  const [multipliers, setMultipliers] = useState([]);
  const [winAnimations, setWinAnimations] = useState([]);

  // Definice násobitelů pro každé cílové pole
  // Vyšší násobitelé na krajích pro zvýšení rizika a zábavnosti
  const targetMultipliers = [
    50, 8, 3, 2, 1.5, 1.2, 1, 1.2, 1.5, 2, 3, 8, 50
  ];

  // Add refs for layout configuration variables
  const startXRef = useRef(400);
  const startYRef = useRef(100);
  const numRowsRef = useRef(13);

  // Funkce pro aktualizaci pokroku denních misí
  const updateMissionProgress = () => {
    setDailyMissions(prevMissions => {
      return prevMissions.map(mission => {
        // Pokud mise již byla dokončena, neměníme ji
        if (mission.completed) return mission;

        // Simulujeme postup v misi
        const newProgress = Math.min(mission.progress + mission.incrementStep, mission.target);
        const completed = newProgress >= mission.target;

        // Pokud mise byla právě dokončena, zobrazíme oznámení
        if (completed && !mission.completed) {
          showMissionCompletedMessage(mission);
          handleMissionReward(mission); // Udělení odměny
        }

        return {
          ...mission,
          progress: newProgress,
          completed
        };
      });
    });
  };

  // Zobrazení oznámení o dokončení mise
  const showMissionCompletedMessage = (mission) => {
    setSuccessMessage({
      title: "Mise dokončena!",
      message: `Dokončili jste misi "${mission.title}" a získali odměnu: ${mission.reward}!`,
      missionId: mission.id
    });

    setTimeout(() => setSuccessMessage(null), 5000); // Skrytí po 5 sekundách
  };

  // Simulace udělení odměny za dokončenou misi
  const handleMissionReward = (mission) => {
    switch(mission.id) {
      case 1: // 5% z vaší sázky
        const bonus = betAmount * 0.05;
        console.log(`Bonus připsán: +${bonus}`);
        break;
      case 2: // 10 kreditů
        console.log("Bonus připsán: +10");
        break;
      case 3: // 50 kreditů
        console.log("Bonus připsán: +50");
        break;
      case 4: // 100 kreditů
        console.log("Bonus připsán: +100");
        break;
      case 5: // Speciální barva míčku - pouze oznámení
        // Implementace změny barvy míčku by zde mohla být další funkcionalitou
        console.log("Odemčena speciální barva míčku!");
        break;
      default:
        break;
    }
  };

  // Vylepšená inicializace herního pole s násobiteli
  useEffect(() => {
    const engine = engineRef.current;
    const render = Matter.Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: 800,
        height: 950, // Zvýšená výška pro plné zobrazení hry včetně spodních cílů
        wireframes: false,
        background: '#151928',
        // Vylepšení zobrazení pro lepší viditelnost
        showSleeping: false,
        showDebug: false,
        showBroadphase: false,
        showBounds: false,
        showVelocity: false,
        showCollisions: false,
        showSeparations: false,
        showAxes: false,
        showPositions: false,
        showAngleIndicator: false,
        showIds: false,
        showShadows: false,
        showVertexNumbers: false,
        showConvexHulls: false,
        showInternalEdges: false,
        showMousePosition: false
      },
    });

    const numRows = 13; // Počet řádků kolíků
    const startX = 400;
    const startY = 100;
    let pegCounter = 1;

    // Store values in refs so they can be accessed from other functions
    numRowsRef.current = numRows;
    startXRef.current = startX;
    startYRef.current = startY;

    // Vytvoření 13 řádků kolíků
    for (let i = 0; i < numRows; i++) {
      const numPegsInRow = 3 + i;
      for (let j = 0; j < numPegsInRow; j++) {
        const x = startX + j * pegSpacing - ((numPegsInRow - 1) * pegSpacing) / 2;
        const y = startY + i * pegSpacing;

        if (j !== 0 && j !== numPegsInRow - 1) {
          const peg = Matter.Bodies.circle(x, y, 5, {
            isStatic: true,
            label: `Peg ${pegCounter}`,
            render: {
              fillStyle: '#3a88fe',
              strokeStyle: '#ffffff',
              lineWidth: 1,
              id: pegCounter,
            },
          });
          pegs.current.push({ x, y, id: pegCounter, body: peg });
          Matter.World.add(engine.world, peg);
          pegCounter++;
        } else {
          const peg = Matter.Bodies.circle(x, y, 5, { 
            isStatic: true,
            render: {
              fillStyle: '#3a88fe',
              strokeStyle: '#ffffff',
              lineWidth: 1,
            },
          });
          Matter.World.add(engine.world, peg);
        }
      }
    }

    // Vytvoření finálních cílových polí s násobiteli
    const numTargets = numRows + 1; // O jeden více cílů než řad kolíků (14 místo 13)
    const lastRowY = startY + (numRows - 1) * pegSpacing; 
    
    // Aktualizace multiplierů pro 14 cílů
    const targetMultipliers = [
      50, 8, 5, 3, 2, 1.5, 1.2, 1.2, 1.5, 2, 3, 5, 8, 50
    ];
    
    // Uložíme násobitele do stavu
    setMultipliers(targetMultipliers);
    
    const targets = [];
    
    // ZMĚNA: Upravený výpočet pro rozmístění cílů po celé šířce
    const gameWidth = 800; // Šířka herní plochy
    const targetWidth = 30; // Šířka cílového boxu
    const totalTargetsWidth = targetWidth * numTargets;
    const spacing = (gameWidth - totalTargetsWidth) / (numTargets); //+ 1);
    
    for (let i = 0; i < numTargets; i++) {
      // Nové zarovnání po celé šířce - rovnoměrné rozložení
      const x = spacing * (i + 1) + (targetWidth * i) + (targetWidth / 2);
      
      // Použijeme násobitel pro dané pole
      const multiplier = targetMultipliers[i];
      
      // Určíme barvu podle hodnoty násobitele
      let fillColor;
      if (multiplier >= 20) fillColor = '#ff2d55';
      else if (multiplier >= 5) fillColor = '#ffcc00';
      else if (multiplier >= 2) fillColor = '#34c759';
      else fillColor = '#007aff';
      
      const target = Matter.Bodies.rectangle(x, lastRowY + 60, targetWidth, 80, {
        isStatic: true,
        label: `Target ${i + 1}`,
        multiplier: multiplier,
        render: {
          fillStyle: fillColor,
        },
      });
      
      targets.push({ 
        x, 
        y: lastRowY + 60, 
        id: i + 1, 
        body: target, 
        multiplier: multiplier 
      });
      
      Matter.World.add(engine.world, target);
    }
    
    // Uložíme pozice cílů do speciálního ref pro použití v multiplikátorech
    targetPositionsRef.current = targets.map(t => ({ x: t.x, id: t.id }));
    
    finalTargets.current = targets;

    // Přidáme zdi po stranách, aby míčky nepadaly mimo
    const wallThickness = 20;
    const leftWall = Matter.Bodies.rectangle(
      startX - ((numRows + 2) * pegSpacing) / 2 - wallThickness/2, 
      startY + numRows * pegSpacing/2, 
      wallThickness, 
      numRows * pegSpacing,
      { 
        isStatic: true,
        render: {
          fillStyle: '#151928', // Barva zdi - stejná jako pozadí
          visible: false // Neviditelná zeď
        }
      }
    );
    
    const rightWall = Matter.Bodies.rectangle(
      startX + ((numRows + 2) * pegSpacing) / 2 + wallThickness/2, 
      startY + numRows * pegSpacing/2, 
      wallThickness, 
      numRows * pegSpacing,
      { 
        isStatic: true,
        render: {
          fillStyle: '#151928',
          visible: false
        }
      }
    );

    Matter.World.add(engine.world, [leftWall, rightWall]);
    Matter.Render.run(render);
    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine);

    // Přidání kolizního posluchače pro animaci výhry - vylepšení odezvy na kolize
    Matter.Events.on(engine, 'collisionStart', (event) => {
      event.pairs.forEach((pair) => {
        const bodyA = pair.bodyA;
        const bodyB = pair.bodyB;
        
        // Kontrola, zda jde o kolizi míčku a cílového pole
        if ((bodyA.label && bodyA.label.includes('Ball') && bodyB.label && bodyB.label.includes('Target')) ||
            (bodyB.label && bodyB.label.includes('Ball') && bodyA.label && bodyA.label.includes('Target'))) {
          
          const target = bodyA.label.includes('Target') ? bodyA : bodyB;
          const ball = bodyA.label.includes('Ball') ? bodyA : bodyB;
          
          // Získání násobitele a výpočet výhry
          const multiplier = target.multiplier;
          const win = betAmount * multiplier;
          
          console.log(`Míček zasáhl cíl s násobitelem ${multiplier}x, výhra: ${win}`);
          
          // Vytvoření animace výhry s vylepšeným efektem
          createWinAnimation(target.position.x, target.position.y - 40, multiplier, win);
          
          // Aktualizace statistik
          setGameStats(prev => ({
            totalBalls: prev.totalBalls + 1,
            highestMultiplier: Math.max(prev.highestMultiplier, multiplier),
            totalWin: prev.totalWin + win
          }));
          
          // Nastavení poslední výhry pro zobrazení v UI
          setLastWin({ multiplier, win });
          
          // Odstranění míčku po delší prodlevě (500ms místo 100ms)
          setTimeout(() => {
            Matter.World.remove(engine.world, ball);
          }, 500);
        }
      });
    });

    return () => {
      Matter.Render.stop(render);
      Matter.World.clear(engine.world);
      Matter.Engine.clear(engine);
    };
  }, []); // ÚPRAVA: Odstranění betAmount jako závislosti - inicializace scény by měla proběhnout jen jednou

  // Vylepšená funkce pro vytvoření animace výhry
  const createWinAnimation = (x, y, multiplier, amount) => {
    const id = Date.now() + Math.random();
    const animation = {
      id,
      x,
      y,
      multiplier,
      amount,
      opacity: 1,
      scale: 1,
      type: multiplier >= 5 ? 'big' : 'normal', // Typ animace závisí na násobiteli
    };
    
    setWinAnimations(prev => [...prev, animation]);
    
    // Prodloužíme dobu animace na 3 sekundy pro lepší viditelnost
    setTimeout(() => {
      setWinAnimations(prev => prev.filter(anim => anim.id !== id));
    }, 3000);
  };

  const findNextPeg = (currentPegId, direction, levels) => {
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      const index = level.indexOf(currentPegId);
      if (index !== -1) {
        if (direction === 'left') {
          return levels[i + 1]?.[index]; // Levý spodní kolík
        } else if (direction === 'right') {
          return levels[i + 1]?.[index + 1]; // Pravý spodní kolík
        }
      }
    }
    return null;
  };

  const calculateDirectionToPeg = (ballPosition, targetPeg) => {
    const dx = targetPeg.x - ballPosition.x;
    const dy = targetPeg.y - ballPosition.y;
    const magnitude = Math.sqrt(dx * dx + dy * dy);
    //console.log(`Vypočtený směr: dx=${dx}, dy=${dy}, magnitude=${magnitude}`);
    return { x: (dx / magnitude) * 10, y: (dy / magnitude) * 10 };
  };

  const resetPegsColor = () => {
    pegs.current.forEach((peg) => {
      if (peg.body.render) {
        peg.body.render.fillStyle = '#FFFFFF';
      }
    });
  };

  // Oprava funkce highlightPath
  const highlightPath = (path) => {
    path.forEach((pegId) => {
      const peg = pegs.current.find(p => p.id === pegId);
      if (peg && peg.body.render) {
        peg.body.render.fillStyle = '#FF0000'; // Zvýraznění cesty červenou barvou
      }
    });
  };

  // Vylepšená funkce pro vytvoření míčku, která je oddělená od hlavního handleStartGame
  const createNewBall = (highlightPegs, newBallColor) => {
    const ballId = `Ball-${ballCounterRef.current++}`;
    const firstPeg = pegs.current.find(peg => peg.id === 1);
    if (firstPeg) {
      // Vytvoříme míček s vylepšeným vzhledem pro lepší viditelnost
      const ball = Matter.Bodies.circle(firstPeg.x, firstPeg.y - pegSpacing, ballSize, {
        restitution: 0.7,
        friction: 0.1,
        frictionAir: 0.01,
        label: ballId,
        render: {
          fillStyle: '#FFFF00',
          strokeStyle: '#FF0000',
          lineWidth: 3,
          visible: true,
          opacity: 1,
          shadowColor: '#FFFF00',
          shadowBlur: 15,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        }
      });
      
      console.log(`Míček vytvořen na pozici: x=${firstPeg.x}, y=${firstPeg.y - pegSpacing}`);
      
      targetPegsMap.current.set(ballId, [...highlightPegs]);
      Matter.World.add(engineRef.current.world, ball);
      
      // Přidáme silnější počáteční impuls
      Matter.Body.applyForce(ball, ball.position, { 
        x: (Math.random() - 0.5) * 0.005,
        y: 0.003
      });
      
      return ball;
    }
    return null;
  };

  // ÚPRAVA: Upravený handleStartGame pro lepší práci s míčkem
  const handleStartGame = () => {
    // Náhodný výběr barvy pro míček
    const ballColors = ['#ffffff', '#ff2d55', '#5ac8fa', '#ffcc00', '#34c759'];
    const newBallColor = ballColors[Math.floor(Math.random() * ballColors.length)];
    setBallColor(newBallColor);
    
    console.log(`Kredity odečteny: -${betAmount}`);
    updateMissionProgress();
    resetPegsColor();

    fetch('/gameResults/plinko-path?clientSeed=myCustomSeed')
      .then(response => response.json())
      .then(data => {
        const generatedPath = data.path;
        //console.log('Vygenerovaná cesta:', generatedPath);

        let currentPegId = 1;
        const highlightPegs = [currentPegId];

        const levels = pegs.current.reduce((acc, peg) => {
          const yLevel = peg.y;
          if (!acc[yLevel]) acc[yLevel] = [];
          acc[yLevel].push(peg.id);
          return acc;
        }, {});

        Object.values(levels).forEach(level => level.sort((a, b) => a - b));

        generatedPath.forEach((direction) => {
          currentPegId = findNextPeg(currentPegId, direction, Object.values(levels));
          if (currentPegId !== null && pegs.current.some(peg => peg.id === currentPegId)) {
            highlightPegs.push(currentPegId);
          }
        });

        highlightPath(highlightPegs);
        
        // ÚPRAVA: Použití oddělené funkce createNewBall
        let ball = createNewBall(highlightPegs, newBallColor);

        Matter.Events.on(engineRef.current, 'beforeUpdate', () => {
          if (ball && !ball.isAtTarget && !ball.isRemoved) {
            moveToNextPeg(ball);
          }
        });

        Matter.Events.on(engineRef.current, 'collisionStart', (event) => {
          event.pairs.forEach((pair) => {
            const ball = pair.bodyA.label.includes('Ball') ? pair.bodyA : pair.bodyB;
            const ballPath = targetPegsMap.current.get(ball.label);

            if (ball && ballPath) {
              const collidedPeg = pegs.current.find(peg => peg.body === (pair.bodyA === ball ? pair.bodyB : pair.bodyA));
              if (collidedPeg) {
                //console.log(`Míček ${ball.label} narazil do kolíku: ${collidedPeg.id}`);
                if (ballPath[0] === collidedPeg.id) {
                  //console.log(`Míček ${ball.label} trefil správný kolík: ${collidedPeg.id}`);
                  ballPath.shift(); // Smazání prvního kolíku z cesty
                } else {
                  //console.log(`Míček ${ball.label} narazil do nesprávného kolíku: ${collidedPeg.id}`);
                }
              }

              // Kontrola pro dopad do finálních čtverečků
              const collidedTarget = finalTargets.current.find(target => target.body === (pair.bodyA === ball ? pair.bodyB : pair.bodyA));
              if (collidedTarget) {
                //console.log(`Míček ${ball.label} dopadl do cílové zóny: ${collidedTarget.id}`);
                Matter.World.remove(engineRef.current.world, ball);
                ball.isRemoved = true; // Nastavení flagu pro zastavení dalších akcí
              }
            }
          });
        });
      })
      .catch(error => console.error('Chyba při volání API:', error));
  };

  const moveToNextPeg = (ball) => {
    const ballPath = targetPegsMap.current.get(ball.label);
    
    // Pokud míček má cestu s kolíky, sleduje další kolík
    if (ballPath && ballPath.length > 0) {
      const nextPegId = ballPath[0];
      const nextPeg = pegs.current.find(peg => peg.id === nextPegId);

      if (nextPeg) {
        const direction = calculateDirectionToPeg(ball.position, nextPeg);
        Matter.Body.setVelocity(ball, {
          x: direction.x,
          y: direction.y,
        });
      }
    } 
    // NOVÁ LOGIKA: Když míček dorazí k poslednímu kolíku (už nemá další v cestě)
    // nasměrujeme ho k nejbližšímu cílovému čtverečku
    else if (ball.position.y > (startYRef.current + (numRowsRef.current - 2) * pegSpacing)) {
      // Najdeme nejbližší cílový čtvereček
      const nearestTarget = findNearestTarget(ball.position);
      
      if (nearestTarget) {
        // Nasměrujeme míček na tento cíl
        const direction = calculateDirectionToPeg(ball.position, nearestTarget);
        // Použijeme mírnější směřování (menší y rychlost) aby míček padal přirozeněji
        Matter.Body.setVelocity(ball, {
          x: direction.x * 0.8,
          y: Math.max(5, direction.y * 0.5) // Minimální rychlost padání
        });
      }
    }
  };

  // Nová pomocná funkce pro hledání nejbližšího cílového čtverečku
  const findNearestTarget = (position) => {
    let nearestTarget = null;
    let minDistance = Infinity;
    
    finalTargets.current.forEach(target => {
      const dx = target.x - position.x;
      const dy = target.y - position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance < minDistance) {
        minDistance = distance;
        nearestTarget = target;
      }
    });
    
    return nearestTarget;
  };

  // Funkce pro změnu výše sázky
  const handleBetChange = (amount) => {
    setBetAmount(amount);
  };

  const toggleMissions = () => {
    setShowMissions(!showMissions);
  };

  // Přidáme nový ref pro ukládání pozic cílů
  const targetPositionsRef = useRef([]);

  // Upravené rozložení v render části s vizuálními vylepšeními
  return (
    <>
      {/* Navbar přesunout nad hlavní kontejner */}
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      
      <div className="plinko-game-container">
        {/* Mobilní přepínač viditelnosti misí */}
        <div className="missions-toggle" onClick={toggleMissions}>
          {showMissions ? "Skrýt denní mise" : "Zobrazit denní mise"}
        </div>
        
        {/* Hlavní obsah - hra a sázky */}
        <div className="plinko-content-area">
          {/* Hlavička hry */}
          <div className="plinko-header">
            <h1>Plinko</h1>
            <div className="game-stats">
              <span className="stat">Nejvyšší výhra: <span className="highlight">{gameStats.highestMultiplier}x</span></span>
              <span className="stat">Odehráno míčků: <span className="highlight">{gameStats.totalBalls}</span></span>
              <span className="stat">Celkem vyhráno: <span className="highlight">{gameStats.totalWin}</span></span>
            </div>
          </div>
          
          {/* Plinko hra */}
          <div className="plinko-board-container">
            <div ref={sceneRef} className="plinko-board">
              {/* Vrstva pro násobitele - upravíme použití pozic z targetPositionsRef */}
              <div className="multipliers-overlay">
                {multipliers.map((multiplier, index) => (
                  <div 
                    key={index} 
                    className={`multiplier-label ${
                      multiplier >= 20 ? 'highest' : 
                      multiplier >= 5 ? 'high' : 
                      multiplier >= 2 ? 'medium' : 'low'}`}
                    style={{ 
                      // Použijeme uložené pozice cílových boxů pro přesné zarovnání
                      left: `${targetPositionsRef.current[index]?.x || 0}px`,
                      bottom: '20px'
                    }}
                  >
                    {multiplier}x
                  </div>
                ))}
              </div>
              
              {/* Vrstva pro animace výher */}
              <div className="win-animations-container">
                {winAnimations.map(animation => (
                  <div
                    key={animation.id}
                    className="win-animation"
                    style={{
                      left: `${animation.x}px`,
                      top: `${animation.y}px`,
                      opacity: animation.opacity,
                      transform: `translateY(-50px) scale(${animation.scale})`
                    }}
                  >
                    <div className="win-multiplier">{animation.multiplier}x</div>
                    <div className="win-amount">+{animation.amount}</div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Informace o poslední výhře */}
            {lastWin && (
              <div className="last-win-info">
                <div className="win-flash">
                  <span>Poslední výhra: </span>
                  <span className="win-multiplier">{lastWin.multiplier}x</span>
                  <span className="win-amount">+{lastWin.win}</span>
                </div>
              </div>
            )}
          </div>
          
          {/* Sázky pod hrou */}
          <div className="betting-controls">
            <div className="bet-amount-controls">
              <span>Sázka: <strong>{betAmount}</strong></span>
              <div className="bet-buttons">
                <button onClick={() => handleBetChange(5)} className={betAmount === 5 ? 'active' : ''}>5</button>
                <button onClick={() => handleBetChange(10)} className={betAmount === 10 ? 'active' : ''}>10</button>
                <button onClick={() => handleBetChange(20)} className={betAmount === 20 ? 'active' : ''}>20</button>
                <button onClick={() => handleBetChange(50)} className={betAmount === 50 ? 'active' : ''}>50</button>
                <button onClick={() => handleBetChange(100)} className={betAmount === 100 ? 'active' : ''}>100</button>
              </div>
            </div>
            <button 
              className="start-game-button" 
              onClick={handleStartGame}
              disabled={credits < betAmount}
            >
              Poslat míček
            </button>
          </div>
        </div>
        
        {/* Denní mise vpravo - sloupec */}
        {showMissions && (
          <div className="daily-missions-panel">
            <h2>Denní mise</h2>
            <div className="missions-list">
              {dailyMissions.map(mission => (
                <div key={mission.id} className={`mission-item ${mission.completed ? 'completed' : ''}`}>
                  <div className="mission-header">
                    <h3>{mission.title}</h3>
                    <span className="mission-reward">{mission.reward}</span>
                  </div>
                  <p>{mission.description}</p>
                  <div className="mission-progress-bar">
                    <div 
                      className="mission-progress" 
                      style={{ width: `${(mission.progress / mission.target) * 100}%` }}
                    ></div>
                  </div>
                  <span className="mission-progress-text">
                    {Math.floor(mission.progress)}/{mission.target} 
                    {mission.completed && " ✓"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {/* Success Message */}
        {successMessage && (
          <div className="mission-success-message">
            <h3>{successMessage.title}</h3>
            <p>{successMessage.message}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default PlinkoGame;
//Final verze
