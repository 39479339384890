import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import './GameMenuPage.css';
import soundManager from './utils/SoundManager';

// Import obrázků
import rouletteImage from './components/images/roulette.png';
import shipImage from './components/images/ship.png';
import plinkoBallImage from './components/images/ball.png';
import coinflipImage from './components/images/coin.png'; 
import diamondImage from './components/images/diamond.png'; 
import cardBattleImage from './components/images/card.png';
import pricePredictorImage from './components/images/price-tag.png';
import cardFinderImage from './components/images/card.png';
import rpsImage from './components/images/rock-paper-scissors.png';

// Demo data - později nahraďte skutečnými daty z API
const initialNotifications = [
  { 
    id: 1, 
    type: 'win', 
    text: 'xKr1st0f_CZ vyhrál 1,256 Kč v Roulette před 48s',
    urgent: false 
  },
  { 
    id: 2, 
    type: 'win', 
    text: 'Anonymní hráč vyhrál 16,780 Kč v Crash (14.3x) právě teď',
    urgent: false 
  },
  { 
    id: 3, 
    type: 'win', 
    text: 'MartinK. vyhrál 630 Kč v Diamond Miner před 2m',
    urgent: false 
  },
  { 
    id: 4, 
    type: 'promo', 
    text: 'BONUS ALERT: Získejte 5 FREE spinů v Diamond Miner teď hned!',
    urgent: true 
  },
  { 
    id: 5, 
    type: 'tournament', 
    text: 'Právě začíná: VIP Poker Turnaj - prize pool 50,000 Kč',
    urgent: false 
  }
];

function GameMenuPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);
  const [credits, setCredits] = useState(0);
  const [notifications, setNotifications] = useState(initialNotifications);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [isInfoBarOpen, setIsInfoBarOpen] = useState(true);
  const navigate = useNavigate();
  
  // Nové stavy pro questy
  const [quests, setQuests] = useState([
    {
      id: 1,
      title: "Štědrý začátek",
      description: "Zahrajte si 3 hry Ruleta",
      reward: "25 kreditů",
      progress: 1,
      target: 3,
      completed: false,
      claimed: false,
      enabled: true,
      gameType: "rng"
    },
    {
      id: 2,
      title: "Crash Master",
      description: "Vyhrajte 2x s multiplikátorem vyšším než 3.0",
      reward: "50 kreditů",
      progress: 0,
      target: 2,
      completed: false,
      claimed: false,
      enabled: false,
      gameType: "rng"
    },
    {
      id: 3,
      title: "Diamond Hunter",
      description: "Najděte 5 diamantů v Diamond Miner",
      reward: "10 free spinů",
      progress: 2,
      target: 5,
      completed: false,
      claimed: false,
      enabled: true,
      gameType: "rng"
    },
    {
      id: 4,
      title: "PVP Šampion",
      description: "Vyhrajte 5 coinflip her",
      reward: "100 kreditů",
      progress: 3,
      target: 5,
      completed: false,
      claimed: false,
      enabled: true,
      gameType: "pvp"
    },
    {
      id: 5,
      title: "Plinko Challenge",
      description: "Získejte výhru s násobitelem 50x a vyšším",
      reward: "200 kreditů",
      progress: 0,
      target: 1,
      completed: false,
      claimed: false,
      enabled: false,
      gameType: "rng"
    }
  ]);

  // Stavy pro turnaje
  const [dailyTournaments, setDailyTournaments] = useState([
    {
      id: 1,
      title: "Daily Coinflip",
      description: "Největší výhra v Coinflip",
      prize: "300 kreditů",
      startTime: new Date(Date.now() + 3600000).toLocaleTimeString(),
      endTime: new Date(Date.now() + 86400000).toLocaleTimeString(),
      game: "coinflip"
    },
    {
      id: 2,
      title: "Lucky Spin",
      description: "Nejvyšší výhra v Ruletě",
      prize: "500 kreditů",
      startTime: "12:00",
      endTime: "00:00",
      game: "roulette"
    },
    {
      id: 3,
      title: "Get Rich or Die",
      description: "Největší celková výhra",
      prize: "1000 kreditů + 20 free spinů",
      startTime: "14:30", 
      endTime: "22:00",
      game: "all"
    }
  ]);
  
  const [weeklyTournaments, setWeeklyTournaments] = useState([
    {
      id: 1,
      title: "Diamond Week",
      description: "Nejvyšší počet nalezených diamantů",
      prize: "2000 kreditů + VIP status na 7 dní",
      startTime: "Pondělí 10:00",
      endTime: "Neděle 22:00",
      game: "diamond-miner"
    },
    {
      id: 2,
      title: "High Roller",
      description: "Nejvyšší multiplikátor v Crash",
      prize: "5000 kreditů",
      startTime: "Středa 18:00",
      endTime: "Středa další týden",
      game: "crash"
    }
  ]);

  // Nové stavy pro rozbalování položek
  const [expandedTournaments, setExpandedTournaments] = useState({});
  const [expandedQuests, setExpandedQuests] = useState({});

  // Nahrazení zvukových funkcí prázdnými
  const playToggleSound = () => {
    // Prázdná funkce - žádný zvuk
  };

  const playGameHover = () => {
    // Prázdná funkce - žádný zvuk
  };

  const playGameSelect = () => {
    // Prázdná funkce - žádný zvuk
  };

  // Funkce pro přepínání rozbalení turnaje
  const toggleTournament = (id) => {
    playToggleSound();
    setExpandedTournaments(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Funkce pro přepínání rozbalení questu
  const toggleQuest = (id) => {
    playToggleSound();
    setExpandedQuests(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get('/player', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setUsername(response.data.username);
          setUserId(response.data.id);
          setCredits(response.data.credits);
        }
      } catch (error) {
        console.error('Chyba při načítání dat hráče:', error);
        setIsAuthenticated(false);
      }
    };

    fetchPlayerData();
  }, [setIsAuthenticated]);

  // Přidávání nových notifikací do poolu
  useEffect(() => {
    const wins = [
      'Petr89 vyhrál 450 Kč v Roulette právě teď',
      'JanaK dosáhla 8.6x multiplikátoru v Crash!',
      'Martin123 vyhrál 1,800 Kč v Diamond Miner před 5s',
      'KarelN vyhrál 5,600 Kč v Plinko právě teď',
      'TerezaZ vyhrála 12,000 Kč v Poker před 30s',
      'David44 vyhrál 780 Kč v Dice před 15s',
      'Anonymní hráč vyhrál 23,500 Kč v Crash (18.2x) před 1m'
    ];
    
    const promos = [
      'POSLEDNÍ ŠANCE: 10 free spinů vyprší za 5 minut!',
      'BONUS ALERT: Zdvojnásobte svůj vklad právě teď!',
      'ČASOVĚ OMEZENO: 200% bonus do půlnoci!',
      'VIP NABÍDKA: Exkluzivní přístup k novému turnaji!'
    ];
    
    const addNotificationInterval = setInterval(() => {
      // 80% pravděpodobnost výhry, 20% pravděpodobnost promo
      const isPromo = Math.random() < 0.2;
      
      const newNotification = {
        id: Date.now(),
        type: isPromo ? 'promo' : 'win',
        text: isPromo ? 
          promos[Math.floor(Math.random() * promos.length)] : 
          wins[Math.floor(Math.random() * wins.length)],
        urgent: isPromo
      };
      
      // Přidáme novou notifikaci na konec a udržujeme max 30 notifikací
      setNotifications(prev => [...prev.slice(-29), newNotification]);
    }, 8000); // Nová notifikace každých 8 sekund
    
    return () => clearInterval(addNotificationInterval);
  }, []);

  // Aktualizace zobrazených notifikací každých 10 sekund
  useEffect(() => {
    // Inicializujeme zobrazení při prvním načtení
    updateDisplayedNotifications();
    
    const interval = setInterval(() => {
      updateDisplayedNotifications();
    }, 10000); // Změna zobrazených notifikací každých 10 sekund
    
    return () => clearInterval(interval);
    
    function updateDisplayedNotifications() {
      const notificationsCopy = [...notifications];
      
      // Vždy zahrneme urgentní zprávy, pokud nějaké existují
      const urgentNotifications = notificationsCopy.filter(n => n.urgent);
      const regularNotifications = notificationsCopy.filter(n => !n.urgent);
      
      // Zamícháme pravidelné notifikace pro náhodný výběr
      regularNotifications.sort(() => Math.random() - 0.5);
      
      // Vytvoříme pole pro zobrazené notifikace
      let selected = [];
      
      // Nejprve přidáme jednu speciální nabídku jako hlavní notifikaci
      // Vybereme náhodnou urgentní notifikaci nebo vytvoříme speciální nabídku
      let specialOffer;
      if (urgentNotifications.length > 0) {
        specialOffer = {
          id: urgentNotifications[0].id,
          type: 'special',
          text: urgentNotifications[0].text
        };
      } else {
        // Pokud nemáme urgentní, vytvoříme default speciální nabídku
        const specialOffers = [
          "BONUS ALERT: Získejte 5 FREE spinů v Diamond Miner teď hned!",
          "VIP NABÍDKA: Exkluzivní přístup k novému turnaji!",
          "ČASOVĚ OMEZENO: 200% bonus do půlnoci!",
          "POSLEDNÍ ŠANCE: 10 free spinů vyprší za 5 minut!"
        ];
        specialOffer = {
          id: Date.now(),
          type: 'special',
          text: specialOffers[Math.floor(Math.random() * specialOffers.length)]
        };
      }
      selected.push(specialOffer);
      
      // Přidáme další 3-5 běžných notifikací (podle šířky obrazovky)
      const additionalCount = Math.min(5, regularNotifications.length);
      for (let i = 0; i < additionalCount; i++) {
        if (regularNotifications.length > i) {
          selected.push(regularNotifications[i]);
        }
      }
      
      setDisplayedNotifications(selected);
    }
  }, [notifications]);

  const toggleQuestStatus = (id) => {
    setQuests(prevQuests => prevQuests.map(quest => 
      quest.id === id ? { ...quest, enabled: !quest.enabled } : quest
    ));
  };

  const claimQuestReward = (id) => {
    setQuests(prevQuests => prevQuests.map(quest => 
      quest.id === id && quest.completed ? { ...quest, claimed: true } : quest
    ));
    // Zde by byla implementace pro přidání kreditu nebo odměn
    console.log(`Odměna za úkol ${id} claimnuta!`);
  };

  const goToPlinko = () => {
    playGameSelect();
    navigate('/game/plinko');
  };

  const goToRoulette = () => {
    playGameSelect();
    navigate('/game/roulette');
  };

  const goToCrash = () => {
    playGameSelect();
    navigate('/game/crash');
  };
  
  const goToCoinflip = () => {
    playGameSelect();
    navigate('/game/coinflip');
  };
  
  const goToDiamondMiner = () => {
    playGameSelect();
    navigate('/game/diamond-miner');
  };
  
  const goToTournament = (gameType) => {
    playGameSelect();
    // Implementace přesměrování na konkrétní typ turnaje
    navigate(`/game/${gameType}`);
    console.log(`Přesměrování na ${gameType} turnaj`);
  };

  // Přidám nové navigační funkce pro Card Battle a Price Predictor
  const goToCardBattle = () => {
    playGameSelect();
    navigate('/game/card-battle');
  };
  
  const goToPricePredictor = () => {
    playGameSelect();
    navigate('/game/price-predictor');
  };

  // Přidáme navigační funkci pro Card Finder
  const goToCardFinder = () => {
    playGameSelect();
    navigate('/game/card-finder');
  };

  // Přidáme navigační funkci pro Rock Paper Scissors
  const goToRockPaperScissors = () => {
    playGameSelect();
    navigate('/game/rock-paper-scissors');
  };

  // Přidání nové navigační funkce pro PVP Crash - přidáme před ostatní navigační funkce
  const goToPvpCrash = () => {
    playGameSelect();
    navigate('/game/pvp-crash');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/');  
  };

  const toggleInfoBar = () => {
    setIsInfoBarOpen(!isInfoBarOpen);
  };

  // Časovače a výpočty pro progress bary
  const getTimeRemaining = (endTime) => {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const seconds = Math.floor((total / 1000) % 60);
    return { total, hours, minutes, seconds };
  };

  const getPercentageElapsed = (startTime, endTime) => {
    const total = Date.parse(endTime) - Date.parse(startTime);
    const elapsed = Date.parse(new Date()) - Date.parse(startTime);
    return (elapsed / total) * 100;
  };

  const getDailyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now);
    endTime.setHours(22, 0, 0, 0);
    if (now > endTime) {
      endTime.setDate(endTime.getDate() + 1);
    }
    return endTime;
  };

  const getWeeklyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now);
    endTime.setDate(now.getDate() + (7 - now.getDay()) % 7);
    endTime.setHours(22, 0, 0, 0);
    return endTime;
  };

  const getMonthlyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now.getFullYear(), now.getMonth() + 1, 0, 22, 0, 0, 0);
    return endTime;
  };

  const dailyEndTime = getDailyEndTime();
  const weeklyEndTime = getWeeklyEndTime();
  const monthlyEndTime = getMonthlyEndTime();

  const dailyTimeRemaining = getTimeRemaining(dailyEndTime);
  const weeklyTimeRemaining = getTimeRemaining(weeklyEndTime);
  const monthlyTimeRemaining = getTimeRemaining(monthlyEndTime);

  const dailyPercentageElapsed = getPercentageElapsed(new Date(), dailyEndTime);
  const weeklyPercentageElapsed = getPercentageElapsed(new Date(), weeklyEndTime);
  const monthlyPercentageElapsed = getPercentageElapsed(new Date(), monthlyEndTime);

  // Odstranění přehrávání hudby na pozadí
  useEffect(() => {
    // Prázdný efekt - žádná hudba
  }, []);

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
  
      {/* Informační panel s aktuálním děním */}
      <div className={`top-bar ${isInfoBarOpen ? 'open' : 'closed'}`}>
        <div className="toggle-button" onClick={toggleInfoBar}>
          {isInfoBarOpen ? '▲' : '▼ Zobrazit aktuální dění'}
        </div>
        
        {isInfoBarOpen && (
          <div className="notification-grid">
            <div className="notification-row">
              {/* Speciální nabídka jako první prvek */}
              {displayedNotifications.length > 0 && displayedNotifications[0].type === 'special' && (
                <div className="notification-special">
                  {displayedNotifications[0].text}
                </div>
              )}
              {/* Zobrazení ostatních notifikací */}
              {displayedNotifications.slice(1).map(notification => (
                <div 
                  key={notification.id} 
                  className={`notification-item ${notification.type} ${notification.urgent ? 'urgent' : ''}`}
                >
                  {notification.text}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  
      <div className="container">
        {/* Levý panel s turnaji - upravená kolapsovatelná verze */}
        <div className="tournaments-panel">
          <h2>Turnaje</h2>
          
          <div className="tournament-category">
            <h3>Denní turnaje</h3>
            {dailyTournaments.map(tournament => (
              <div key={tournament.id} className="collapsible-item">
                <div 
                  className="collapsible-header" 
                  onClick={() => toggleTournament(`daily-${tournament.id}`)}
                  onMouseEnter={playGameHover}
                >
                  <div className="collapsible-header-left">
                    <h4>{tournament.title}</h4>
                    <div className="collapsible-timing">
                      {tournament.startTime} - {tournament.endTime}
                    </div>
                  </div>
                  <div className="collapsible-header-right">
                    <span className="collapsible-prize">{tournament.prize}</span>
                    <span className={`expand-icon ${expandedTournaments[`daily-${tournament.id}`] ? 'expanded' : ''}`}>
                      ▼
                    </span>
                  </div>
                </div>
                <div className={`collapsible-content ${expandedTournaments[`daily-${tournament.id}`] ? 'expanded' : ''}`}>
                  <p>{tournament.description}</p>
                  <button 
                    className="join-tournament-button"
                    onClick={() => goToTournament(tournament.game)}
                    onMouseEnter={playGameHover}
                  >
                    Připojit se
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          <div className="tournament-category">
            <h3>Týdenní turnaje</h3>
            {weeklyTournaments.map(tournament => (
              <div key={tournament.id} className="collapsible-item">
                <div 
                  className="collapsible-header"
                  onClick={() => toggleTournament(`weekly-${tournament.id}`)}
                  onMouseEnter={playGameHover}
                >
                  <div className="collapsible-header-left">
                    <h4>{tournament.title}</h4>
                    <div className="collapsible-timing">
                      {tournament.startTime} - {tournament.endTime}
                    </div>
                  </div>
                  <div className="collapsible-header-right">
                    <span className="collapsible-prize">{tournament.prize}</span>
                    <span className={`expand-icon ${expandedTournaments[`weekly-${tournament.id}`] ? 'expanded' : ''}`}>
                      ▼
                    </span>
                  </div>
                </div>
                <div className={`collapsible-content ${expandedTournaments[`weekly-${tournament.id}`] ? 'expanded' : ''}`}>
                  <p>{tournament.description}</p>
                  <button 
                    className="join-tournament-button"
                    onClick={() => goToTournament(tournament.game)}
                    onMouseEnter={playGameHover}
                  >
                    Připojit se
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
  
        <div className="game-sections">
          {/* PVP sekce */}
          <div className="section">
            <h2>PVP</h2>
            <div className="pvp-section">
              <button 
                className="coinflip-box" 
                onClick={goToCoinflip}
                onMouseEnter={playGameHover}
              >
                <img src={coinflipImage} alt="Coinflip" className="coinflip-image" />
                <div className="game-box-text">Coinflip</div>
              </button>
              <button 
                className="card-battle-box" 
                onClick={goToCardBattle}
                onMouseEnter={playGameHover}
              >
                <img src={cardBattleImage} alt="Card Battle" className="card-battle-image" />
                <div className="game-box-text">Card Battle</div>
              </button>
              <button 
                className="price-predictor-box" 
                onClick={goToPricePredictor}
                onMouseEnter={playGameHover}
              >
                <img src={pricePredictorImage} alt="Price Predictor" className="price-predictor-image" />
                <div className="game-box-text">Price Predictor</div>
              </button>
              <button 
                className="card-finder-box" 
                onClick={goToCardFinder}
                onMouseEnter={playGameHover}
              >
                <img src={cardFinderImage} alt="Card Finder" className="card-finder-image" />
                <div className="game-box-text">Card Finder</div>
              </button>
              <button 
                className="rps-box" 
                onClick={goToRockPaperScissors}
                onMouseEnter={playGameHover}
              >
                <img src={rpsImage} alt="Rock Paper Scissors" className="rps-image" />
                <div className="game-box-text">Kámen Nůžky Papír</div>
              </button>
              <button 
                className="crash-battle-box" 
                onClick={goToPvpCrash}
                onMouseEnter={playGameHover}
              >
                <div className="racing-ships">
                  <img src={shipImage} alt="Ship 1" className="ship-one" />
                  <img src={shipImage} alt="Ship 2" className="ship-two" />
                </div>
                <div className="game-box-text">Crash Battle</div>
              </button>
            </div>
          </div>
  
          {/* RNG sekce */}
          <div className="section">
            <h2>RNG</h2>
            <div className="rng-section">
              <button 
                className="roulette-box" 
                onClick={goToRoulette}
                onMouseEnter={playGameHover}
              >
                <img src={rouletteImage} alt="Roulette" className="roulette-image" />
                <div className="roulette-text">Ruleta</div>
              </button>
              <button 
                className="plinko-box" 
                onClick={goToPlinko}
                onMouseEnter={playGameHover}
              >
                <img src={plinkoBallImage} alt="Plinko Ball" className="plinko-ball" />
                <div className="win-multiplier">100x</div>
                <div className="plinko-text">Plinko</div>
              </button>
              <button 
                className="crash-box" 
                onClick={goToCrash}
                onMouseEnter={playGameHover}
              >
                <img src={shipImage} alt="Rocket" className="crash-image" />
                <div className="crash-text">Crash</div>
              </button>
              <button 
                className="diamond-box" 
                onClick={goToDiamondMiner}
                onMouseEnter={playGameHover}
              >
                <img src={diamondImage} alt="Diamond" className="diamond-image" />
                <div className="diamond-text">Diamond Miner</div>
              </button>
            </div>
          </div>
        </div>
  
        {/* Pravý panel s questy - kolapsovatelná verze */}
        <div className="quests-panel">
          <h2>Úkoly</h2>
          
          <div className="quests-filter">
            <button className="filter-button active" onMouseEnter={playGameHover}>Všechny</button>
            <button className="filter-button" onMouseEnter={playGameHover}>RNG</button>
            <button className="filter-button" onMouseEnter={playGameHover}>PVP</button>
          </div>
          
          <div className="quests-list">
            {quests.map(quest => (
              <div key={quest.id} className={`quest-item ${quest.completed ? 'completed' : ''} ${quest.claimed ? 'claimed' : ''} ${quest.enabled ? 'enabled' : 'disabled'}`}>
                <div 
                  className="quest-header"
                  onClick={() => toggleQuest(quest.id)}
                  onMouseEnter={playGameHover}
                >
                  <h4>{quest.title}</h4>
                  <div className="quest-header-right">
                    <span className="quest-type">{quest.gameType === "rng" ? "RNG" : "PVP"}</span>
                    <span className="quest-reward">{quest.reward}</span>
                    <span className={`expand-icon ${expandedQuests[quest.id] ? 'expanded' : ''}`}>
                      ▼
                    </span>
                  </div>
                </div>
                <div className={`quest-content ${expandedQuests[quest.id] ? 'expanded' : ''}`}>
                  <p>{quest.description}</p>
                  <div className="quest-progress-bar">
                    <div 
                      className="quest-progress" 
                      style={{ width: `${(quest.progress / quest.target) * 100}%` }}
                    ></div>
                  </div>
                  <div className="quest-footer">
                    <span className="quest-progress-text">
                      {quest.progress}/{quest.target}
                    </span>
                    {quest.completed && !quest.claimed ? (
                      <button 
                        className="claim-button"
                        onClick={(e) => { 
                          e.stopPropagation();
                          claimQuestReward(quest.id);
                        }}
                        onMouseEnter={playGameHover}
                      >
                        Claim
                      </button>
                    ) : quest.claimed ? (
                      <span className="claimed-text">Získáno</span>
                    ) : (
                      <button 
                        className={`toggle-button ${quest.enabled ? 'on' : 'off'}`}
                        onClick={(e) => { 
                          toggleQuestStatus(quest.id);
                          e.stopPropagation();
                        }}
                        onMouseEnter={playGameHover}
                      >
                        {quest.enabled ? 'Aktivní' : 'Vypnuto'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameMenuPage;